<template>
  <div class="s-checkout-extras" v-if="fields" v-show="stepCode === 'extras'">
    <Heading class="s-checkout-extras__title" :field="{ value: title }" :type="fields.titleType" rich />
    <section class="s-checkout-extras__list">
      <div class="s-checkout-extras__list-item" v-for="item in packageDetails?.rightsRespList" :key="item.id">
        <div class="s-checkout-extras__list-item-img" @click="showDetails(item)" v-if="!$isNullOrEmpty(item.image)">
          <img :src="item.image" :alt="item.name" />
        </div>
        <div class="s-checkout-extras__list-item-name" @click="showDetails(item)">{{ item.name }}</div>
        <div class="s-checkout-extras__list-item-price">{{ item.optional ? item.price : $t('Included') }}</div>
        <span class="s-checkout-extras__list-item-details" @click="showDetails(item)">{{ $t('Details') }}</span>
        <div class="s-checkout-extras__list-item-selection" :class="{ disable: !item.optional, selected: item.selected }">
          <div class="s-checkout-extras__list-item-selection-icon first" @click="selectItem(item)">
            <Icon :name="!item.optional ? 'check' : item.selected ? 'minus' : 'plus'" />
          </div>
          <div class="s-checkout-extras__list-item-selection-text">{{ getItemText(item) }}</div>
        </div>
      </div>
    </section>
    <Modal class="s-checkout-extras__details" :animation="$deviceComputes.isMobileOrTablet.value ? 'bottom-fade-in' : 'right-slide-in'" ref="detailsRef" closable>
      <div class="s-checkout-extras__details-img" v-if="!$isNullOrEmpty(showPackage.image)">
        <img :src="showPackage.image" :alt="showPackage.name" />
      </div>
      <div class="s-checkout-extras__details-name">{{ showPackage.name }}</div>
      <div class="s-checkout-extras__details-price" v-if="showPackage.optional">{{ showPackage.price }}</div>
      <div class="s-checkout-extras__details-selection" :class="{ disable: !showPackage.optional, selected: showPackage.selected }">
        <div class="s-checkout-extras__details-selection-icon first" @click="selectItem(showPackage)">
          <Icon :name="!showPackage.optional ? 'check' : showPackage.selected ? 'minus' : 'plus'" />
        </div>
        <div class="s-checkout-extras__details-selection-text">{{ getItemText(showPackage) }}</div>
      </div>
      <div class="s-checkout-extras__details-des">{{ showPackage.remark }}</div>
    </Modal>
  </div>
</template>
<script>
import { getCurrentInstance, reactive, onMounted, toRefs, inject, watch, computed } from 'vue';
import useDevice from '@/hooks/useDevice';
import { formatModel, getAdaptiveImageField, getPageAlpha2Code } from '@/utils/site-utils';
import { formatMoney } from '@/utils/math-utils';
import { formatString, equalString } from '@/utils/string-utils';
import { canUseDOM } from '@/utils/dom-utils';
import { useI18n } from 'vue-i18n';
import { deepCopy, isNullOrEmpty } from '@/utils/obj-utils';
import api from '@/api';
import { debounce } from 'lodash';
export default {
  props: {
    page: {
      type: Object
    },
    step: {
      type: Number
    },
    stepCode: {
      type: String
    },
    fields: {
      type: Object
    },
    blindOrder: {
      type: Object
    },
    currency: {
      type: String,
      default: null
    },
    packageDetails: {
      type: Object
    },
    carModel: {
      type: Object
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const { $route, $jss } = proxy;
    const page = $jss.routeData();
    const { vin } = $route.query;
    const country = getPageAlpha2Code(page);
    const toast = inject('toast');
    const { t } = useI18n();
    const { deviceState } = useDevice();
    const state = reactive({
      detailsRef: null,
      stockDetails: null,
      showPackage: null
    });
    const computes = {
      title: computed(() => formatString(props.fields?.title?.value, { carModel: formatModel(props.carModel?.name, 'capitalize') }))
    };
    const _methods = {
      updateImage: debounce(() => {
        props.packageDetails?.rightsRespList.forEach((x) => (x.image = getAdaptiveImageField(deviceState, x.icon, x.padIconUrl)));
      }, 50)
    };
    const methods = {
      getItemText: (i) => {
        if (!i.optional) {
          return t('Added').toUpperCase();
        } else if (i.selected) {
          return t('Remove').toUpperCase();
        } else {
          return t('Add').toUpperCase();
        }
      },
      selectItem: (i) => {
        if (!i.optional) return;
        i.selected = !i.selected;
      },
      showDetails: (i) => {
        state.showPackage = i;
        state.detailsRef.open();
      }
    };
    watch(
      () => deviceState.deviceType,
      () => {
        _methods.updateImage();
      }
    );
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
.s-checkout-extras {
  &__list {
    margin-top: 24px;
    margin-bottom: 56px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    &-item {
      width: calc(50% - 4px);
      margin-top: 48px;
      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }
      &-img {
        cursor: pointer;
        > img {
          width: 100%;
        }
      }
      &-name {
        margin-top: 8px;
        font-size: 16px;
        line-height: 24px;
        color: $grey-night;
        word-break: break-word;
      }
      &-price {
        font-size: 12px;
        line-height: 20px;
        color: $grey-next;
      }
      &-details {
        font-size: 12px;
        line-height: 20px;
        text-decoration-line: underline;
        color: $grey-night;
        cursor: pointer;
      }
      &-selection {
        $s: &;
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        &-icon {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $yellow;
          color: $black;
          cursor: pointer;
          .e-icon > svg {
            width: 12px;
            height: 12px;
          }
        }
        &-text {
          font-size: 12px;
          font-weight: 700;
          line-height: 20px;
          color: $grey-night;
        }
        &.disable {
          cursor: not-allowed;
          #{$s}-icon {
            cursor: not-allowed;
            color: $white;
            background-color: $green;
          }
          #{$s}-text {
            color: $green;
          }
        }
        &.selected {
          #{$s}-icon {
            color: $white;
            background-color: $black;
          }
        }
      }
    }
  }
  &__details {
    .e-modal__content {
      padding: 64px 24px;
    }
    &-img > img {
      width: 100%;
    }
    &-name {
      margin-top: 24px;
      font-size: 26px;
      line-height: 32px;
      color: $black;
      word-break: break-word;
    }
    &-price {
      margin-top: 8px;
      font-size: 16px;
      line-height: 24px;
      color: $grey-next;
    }
    &-selection {
      margin-top: 16px;
      $s: &;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      &-icon {
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $yellow;
        color: $black;
        cursor: pointer;
        .e-icon > svg {
          width: 16px;
          height: 16px;
        }
      }
      &-text {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: $grey-night;
      }
      &.disable {
        cursor: not-allowed;
        #{$s}-icon {
          cursor: not-allowed;
          color: $white;
          background-color: $green;
        }
        #{$s}-text {
          color: $green;
        }
      }
      &.selected {
        #{$s}-icon {
          color: $white;
          background-color: $black;
        }
      }
    }
    &-des {
      margin-top: 24px;
      font-size: 16px;
      line-height: 24px;
      color: $grey-next;
    }
  }
  @include tablet-landscape {
    &__list {
      gap: 24px;
      margin-bottom: 80px;
      &-item {
        width: calc(50% - 12px);
      }
    }
    &__details {
      justify-content: flex-end;
      .e-modal__content {
        min-height: 100vh;
        width: grid-width(10);
        padding: 80px 64px 48px;
      }
      &-name {
        margin-top: 32px;
      }
      &-selection {
        margin-top: 24px;
      }
      &-des {
        margin-top: 32px;
      }
    }
  }
}
</style>
