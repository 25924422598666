<template>
  <div class="s-checkout-loan-detail" :class="[{ [`theme-${theme}`]: theme }]" v-if="simulation?.selectedSimulation || financeQuote">
    <template v-if="simulation?.selectedSimulation">
      <div class="fs-24 mg-b-16" v-if="!$isNullOrEmpty(productName)">{{ productName }}</div>
      <div class="s-checkout-loan-detail__loan-items" v-if="currency">
        <div class="s-checkout-loan-detail__loan-item">
          <span>{{ $t('Total on the road price - finance') }}</span>
          <span>{{ $formatMoney(totalAmount, currency) }}</span>
        </div>
        <div class="s-checkout-loan-detail__loan-item">
          <span>{{ $t('Down payment') }}</span>
          <span>{{ $formatMoney(simulation.selectedSimulation.downPayment.value, currency) }}</span>
        </div>
        <div class="s-checkout-loan-detail__loan-item">
          <span>{{ $t('Duration') }}</span>
          <span>{{ simulation.selectedSimulation.duration.value }} Months</span>
        </div>
        <div class="s-checkout-loan-detail__loan-item">
          <span>{{ $t('Yearly mileage') }}</span>
          <span>{{ simulation.selectedSimulation.annualMileage.value }} Km</span>
        </div>
        <div class="s-checkout-loan-detail__loan-item">
          <span>{{ $t('First installment') }}</span>
          <span>{{ $formatMoney(simulation.selectedSimulation.firstPayment.value, currency) }}</span>
        </div>
        <div class="s-checkout-loan-detail__loan-item">
          <span>{{ $formatString($t('##no## monthly instalments of'), { no: simulation.selectedSimulation.duration.value - 2 }) }}</span>
          <span>{{ $formatMoney(simulation.selectedSimulation.monthlyPayment.value, currency) }}</span>
        </div>
        <div class="s-checkout-loan-detail__loan-item">
          <span>{{ $t('Calculated final instalment') }}</span>
          <span>{{ $formatMoney(simulation.selectedSimulation.lastPayment.value, currency) }}</span>
        </div>
        <div class="s-checkout-loan-detail__loan-item">
          <span>{{ $t('Financed amount') }}</span>
          <span>{{ $formatMoney(simulation.selectedSimulation.amountOfCredit.value, currency) }}</span>
        </div>
        <div class="s-checkout-loan-detail__loan-item">
          <span>{{ $t('Total cost of credit') }}</span>
          <span>{{ $formatMoney(simulation.selectedSimulation.totalAmountFinanced.value, currency) }}</span>
        </div>
        <div class="s-checkout-loan-detail__loan-item">
          <span>{{ $t('Fixed borrowing rate p.a.') }}</span>
          <span>{{ simulation.selectedSimulation.nominalInterest.value }}%</span>
        </div>
        <div class="s-checkout-loan-detail__loan-item">
          <span>{{ $t('Effective interest rate') }}</span>
          <span>{{ simulation.selectedSimulation.effectiveInterest.value }}%</span>
        </div>
        <div class="s-checkout-loan-detail__disclaimer" v-html="decodeURIComponent(simulation.selectedSimulation.legalDisclaimer?.value ?? simulation.selectedSimulation.legalDisclaimer)" />
      </div>
    </template>
    <template v-if="financeQuote">
      <div class="s-checkout-loan-detail__loan-items" v-if="currency">
        <template v-for="(block, i) in financeQuote.quote?.Blocks" :key="i">
          <div class="s-checkout-loan-detail__loan-item" v-for="(item, j) in block.Details" :key="j">
            <span>{{ item.Label }}</span>
            <span>{{ item.DisplayValue }}</span>
          </div>
        </template>
      </div>
      <div class="s-checkout-loan-detail__disclaimer" v-html="financeQuote.terms" />
    </template>
  </div>
</template>

<script>
import { sitecoreProps } from '@/utils/constants';
import { computed } from 'vue';
import { ifEmpty } from '@/utils/string-utils';

export default {
  name: 'checkout-loan-detail',
  props: {
    fields: {
      type: Object
    },
    theme: {
      type: String
    },
    carSeries: {
      type: Object
    },
    paymentOption: {
      type: Object
    },
    /**@type SantanderSimulation*/
    simulation: {
      type: Object
    },
    /**@type CwFinanceResult*/
    financeQuote: {
      type: Object
    },
    totalAmount: {
      type: Number
    },
    currency: {
      type: String,
      default: 'EUR'
    },
    ...sitecoreProps
  },
  setup(props) {
    const computes = {
      productName: computed(() => ifEmpty(props.paymentOption?.productName, props.simulation?.selectedProduct?.loanType))
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-checkout-loan-detail {
  $c: &;
  &__loan-items {
    margin-bottom: 32px;
  }
  &__loan-item {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    color: $grey-dark;
    border-bottom: 1px solid $grey-light;
    font-size: 12px;
    line-height: 20px;
    > span {
      &:first-child {
        text-transform: capitalize;
      }
    }
  }
  &__disclaimer {
    font-size: 12px;
    color: $grey-next;
    hyphens: auto;
  }
  &.theme {
    &-night {
      color: $white;
      #{$c}__loan-item {
        color: $grey-taubmans;
        border-color: $grey-night;
        &:last-child {
          border-color: $grey-night;
        }
      }
      #{$c}__disclaimer {
        color: $grey-taubmans;
      }
    }
  }
}
</style>
