import i18ninit from './i18n';
import { createApp } from './createApp';
import config from './temp/config';
import gsap from 'gsap';
import { APP_CONST } from '@/utils/constants';
import { getCarSeriesList, getRemoteGlobalConfig } from '@/services/siteService';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { formatCarPrice, getAlpha2CodeByLang, getCurrentAlpha2Code, getGlobalConfigs } from '@/utils/site-utils';
import { canUseDOM, loadChineseFont, loadRemoteModule } from '@/utils/dom-utils';
import { Workbox } from 'workbox-window';
import { equalString } from '@/utils/string-utils';
import { S_ALL_CAR_PRICES } from '@/utils/web-storage-keys';
import { webStorage } from '@/utils/web-storage';
import { formatMoney } from '@/utils/math-utils';
import lottie from 'lottie-web';
import animationData from '@/assets/loading.json';
import api from '@/api';
import { getLangFromUrl, getQueryStrings } from '@/utils/uri-utils';
import * as Vue from 'vue/dist/vue.esm-bundler';

/* eslint-disable no-underscore-dangle */

let initLanguage = config.defaultLanguage;

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide the window.__JSS_STATE__ object
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.

  SSR is initiated from /server/server.js.
*/
APP_CONST.lang = initLanguage;
const $rootLoading = document.getElementById('root-loading');
// const [referrer, channel] = getQueryStrings('referrer', 'channel');
// if (!equalString(referrer, 'app') && !equalString(channel, 'app')) {
//   $rootLoading.style.display = 'block';
//   if (!document.getElementById('root-loading-el') && $rootLoading) {
//     const $rootLoadingEl = document.createElement('div');
//     $rootLoadingEl.setAttribute('id', 'root-loading-el');
//     $rootLoading.appendChild($rootLoadingEl);
//     lottie.loadAnimation({
//       container: $rootLoadingEl,
//       renderer: 'svg',
//       loop: true,
//       autoplay: true,
//       animationData: animationData
//     });
//   }
// }
const { onLCP, onFID, onCLS } = require('web-vitals');
// onCLS(console.log, { reportAllChanges: true });
// onFID(console.log, { reportAllChanges: true });
// onLCP(console.log, { reportAllChanges: true });
let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
initLanguage = __JSS_STATE__?.sitecore?.context?.language ?? getLangFromUrl();
APP_CONST.lang = initLanguage;
if (/^zh/gi.test(initLanguage)) {
  loadChineseFont();
}
// if (navigator.serviceWorker) {
//   let serviceWorkerPath = '/service-worker.js';
//   window.addEventListener('DOMContentLoaded', () => {
//     navigator.serviceWorker.getRegistrations().then((regs) => {
//       for (let reg of regs) {
//         console.log('reg', reg);
//         reg.unregister().catch((swErr) => console.log(`Service Worker Unregister Error: ${swErr}}`));
//       }
//     });
//     if (process.env?.NODE_ENV !== 'development') {
//       navigator.serviceWorker
//         .register(serviceWorkerPath)
//         .then(() => console.log('Service Worker Registered'))
//         .catch((swErr) => console.log(`Service Worker Installation Error: ${swErr}}`));
//     }
//   });
// }
if (navigator.serviceWorker && process.env?.NODE_ENV !== 'development') {
  const wb = new Workbox('/service-worker.js');
  wb.register()
    .then(() => {
      console.log('Service Worker Registered');
    })
    .catch((swErr) => console.log(`Service Worker Installation Error: ${swErr}}`));
}
const fetchAllPrice = async () => {
  const carSeriesItems = await getCarSeriesList();
  const alpha2Code = canUseDOM() ? getCurrentAlpha2Code() : getAlpha2CodeByLang(initLanguage);
  const carPrices = webStorage.get(S_ALL_CAR_PRICES) ?? {};
  APP_CONST.carPriceList = carPrices[alpha2Code] ?? null;
  if (!carPrices[alpha2Code]) {
    const filterIds = new Set();
    for (let s of carSeriesItems) {
      if (s.children?.length) {
        for (let e of s.children) {
          if (!isNullOrEmpty(e.filterId)) {
            filterIds.add(e.filterId);
          }
        }
      }
    }
    if (filterIds.size > 0) {
      const [res, ex] = await api.price.priceInfo(null, {
        country: alpha2Code,
        // currency: 'EUR',
        // market: getCurrentMarket(),
        pageNum: 1,
        pageSize: 100,
        entityRelation: 'NSC to Customer',
        priceCategory: 'Sale Price',
        source: 'Car Configurator',
        sourceItemIds: [...filterIds]
      });
      if (res?.records?.length > 0) {
        let result = {};
        for (let record of res?.records) {
          for (let priceItem of record.priceList) {
            if (equalString(priceItem.country, alpha2Code)) {
              result[`${record.model} Price`] = formatMoney(priceItem.price, priceItem.currency);
            }
          }
        }
        carPrices[alpha2Code] = APP_CONST.carPriceList = result;
        webStorage.set(S_ALL_CAR_PRICES, carPrices);
        return result;
      }
      return null;
    }
  } else {
    return carPrices[alpha2Code];
  }
};
const initRemoteApp = async (__JSS_STATE__) => {
  let ukAppEntryVal = null;
  if (__JSS_STATE__?.sitecore?.route) {
    const [ukAppEntry] = getGlobalConfigs(__JSS_STATE__.sitecore.route, 'ukAppEntry');
    ukAppEntryVal = ukAppEntry?.value;
  } else {
    const globalConfig = await getRemoteGlobalConfig();
    ukAppEntryVal = globalConfig?.ukAppEntry;
  }
  if (isNullOrEmpty(ukAppEntryVal)) return null;
  return loadRemoteModule(ukAppEntryVal, 'lrm_application');
};
// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.
Promise.all([i18ninit(initLanguage) /*, fetchAllPrice() */, initRemoteApp(__JSS_STATE__)]).then(([i18n /*, priceData*/, remoteApp]) => {
  // HTML element to place the app into
  const rootElement = document.getElementById('root');
  // if (__JSS_STATE__) {
  //   // set i18n language SSR state language instead of static config default language
  //   if (!isNullOrEmpty(priceData) && __JSS_STATE__.sitecore.route.placeholders) {
  //     formatCarPrice(__JSS_STATE__.sitecore.route.placeholders, priceData);
  //   }
  // }
  const initialState = __JSS_STATE__ || null;
  const { app } = createApp(initialState, i18n);
  window.buildLRMApp = remoteApp?.code?.buildApp;
  global.Vue = Vue;
  app.mount(rootElement);
  const rootLoadingStyle = global.getComputedStyle($rootLoading);
  if (rootLoadingStyle.display !== 'none') {
    gsap.to($rootLoading, {
      opacity: 0,
      duration: 0.2,
      onComplete() {
        $rootLoading.style.display = 'none';
      }
    });
  }
});
