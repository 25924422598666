<template>
  <div class="s-image-slider-vertical-pc" :class="rootClasses" :style="{ ...rootStyle }" ref="rootEl">
    <div class="s-image-slider-vertical-pc__main">
      <div class="s-image-slider-vertical-pc__main-wrapper" ref="mainWrapperEl">
        <animated-content type="skew-in" v-if="animateFlag">
          <jss-rich-text class="s-image-slider-vertical-pc__main-title" :id="titleId" :field="currentSlide.fields.title" />
        </animated-content>
        <animated-content type="bottom-fade-in" :delay="400" v-if="animateFlag">
          <jss-rich-text class="s-image-slider-vertical-pc__main-body" :id="bodyId" :field="currentSlide.fields.body" tag="div" />
        </animated-content>
        <animated-content type="bottom-fade-in" :delay="500" :hide-content="false" v-if="animateFlag">
          <SiteButton class="s-image-slider-vertical-pc__main-btn" :id="btnId" v-bind="currentSlide.fields.button" />
        </animated-content>
      </div>
    </div>
    <div class="s-image-slider-vertical-pc__side">
      <background-image
        class="s-image-slider-vertical-pc__side-slide"
        :image="$adaptiveImageField(slide.fields.desktopImage, slide.fields.mobileImage)"
        v-for="slide in fields.slides"
        :key="slide.id"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs, watch } from 'vue';
import { largeThanTablet } from '@/utils/dom-utils';
import { loadScrollMagic, themeClass } from '@/utils/site-utils';
import gsap from 'gsap';

export default {
  name: 'ImageSliderVerticalPc',
  props: {
    /**@type ImageSliderVerticalFields*/
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  setup(props) {
    let controller, scene, tl;
    const state = reactive({
      index: 0,
      rootEl: null,
      mainWrapperEl: null,
      animateFlag: true
    });
    const computes = {
      rootClasses: computed(() => [themeClass(props), { reverse: props.fields.reverse?.value }]),
      hasData: computed(() => !!props?.fields?.slides && props.fields.slides.length > 0),
      currentSlide: computed(() => (computes.hasData.value ? props.fields.slides[state.index] : null)),
      rootStyle: computed(() => {
        if (computes.hasData.value) {
          const slides = props.fields.slides;
          return {
            '--bg-first': `url(${slides[slides.length - 1].fields.desktopImage.value?.src})`
          };
        }
        return null;
      }),
      titleId: `${props.rendering.uid}-title`,
      bodyId: `${props.rendering.uid}-body`,
      btnId: `${props.rendering.uid}-btn`
    };
    // watch(
    //   () => computes.currentSlide,
    //   () => {
    //     state.animateFlag = false;
    //     setTimeout(() => {
    //       state.animateFlag = true;
    //     }, 10);
    //   },
    //   {
    //     immediate: true,
    //     deep: true
    //   }
    // );
    const methods = {
      initScrollMagic() {
        if (computes.hasData.value && largeThanTablet()) {
          const count = props.fields.slides.length;
          const itemHeight = (((window.innerWidth * 11) / 24) * 3) / 4;
          const duration = (count - 1) * (30 + itemHeight);
          const ScrollMagic = loadScrollMagic();
          controller = new ScrollMagic.Controller();
          scene = new ScrollMagic.Scene({
            triggerElement: state.rootEl,
            triggerHook: 0,
            duration: duration
          });
          scene.setPin(state.mainWrapperEl);
          // tl = gsap.timeline();
          // tl.fromTo(
          //   document.getElementById(computes.titleId),
          //   {
          //     opacity: 0,
          //     rotation: 30
          //   },
          //   {
          //     opacity: 1,
          //     rotation: 0,
          //     duration: 10
          //   },
          //   'first'
          // ).fromTo(
          //   document.getElementById(computes.bodyId),
          //   {
          //     y: 100,
          //     opacity: 0
          //   },
          //   {
          //     y: 0,
          //     opacity: 1,
          //     duration: 10
          //   },
          //   'first'
          // );
          scene.on('progress', (e) => {
            const scrollHeight = duration * e.progress;
            if (scrollHeight <= itemHeight / 2) {
              state.index = 0;
            } else {
              state.index = Math.floor(scrollHeight / itemHeight);
            }
          });
          scene.addTo(controller);
        }
      }
    };
    onMounted(() => {
      methods.initScrollMagic();
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-image-slider-vertical-pc {
  $c: &;
  display: none;
  @include tablet-landscape {
    @include grid-container;
    &__main {
      @include grid-block(3, 7);
      &-wrapper {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &-title {
        margin-bottom: $space-40;
      }
      &-body {
        margin-bottom: $space-30;
      }
    }
    &__side {
      @include grid-block(12, 11);
      &:before {
        content: '';
        display: block;
        height: calc((100vh - #{grid-width(math.div(11 * 3, 4))} - 60px) / 2);
        background-image: var(--bg-first);
        mask-image: linear-gradient(to top, rgba($black, 0.5), rgba($black, 0.2) 60%, transparent);
        background-position: center bottom;
      }
      &-slide {
        height: grid-width(math.div(11 * 3, 4));
        margin-top: $space-30;
      }
    }
    &.reverse {
      #{$c}__main {
        @include grid-block(16, 7, 1);
      }
      #{$c}__side {
        @include grid-block(3, 11, 1);
      }
    }
  }
}
</style>
