<template>
  <div class="s-dashboard-card" :class="$settingValue(fields.type, 'media')" :style="{ marginTop: largeThanTablet() ? fields.marginTopPC.value : fields.marginTopMobile.value }">
    <div class="s-dashboard-card__content" :class="{ small: fields.smallSize.value && largeThanTablet() }">
      <div class="s-dashboard-card__media">
        <AdaptiveMedia :field="fields.media" :isBackground="!$equalString($settingValue(fields.type, 'media'), 'content')" />
      </div>
      <div class="s-dashboard-card__main">
        <div class="s-dashboard-card__main-top">
          <JssRichText class="s-dashboard-card__eyebrow" :field="fields.eyebrow" />
          <JssRichText class="s-dashboard-card__title" :field="fields.title" />
        </div>
        <div class="s-dashboard-card__main-bottom">
          <JssRichText class="s-dashboard-card__body" :field="fields.body" />
          <div class="s-dashboard-card__btns">
            <SiteButton class="s-dashboard-card__btn" v-for="btn in fields.buttons" :key="btn.id" v-bind="btn" />
            <ImageLink class="s-dashboard-card__btn" v-for="link in fields.imageLinks" :key="link.id" v-bind="link" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { largeThanTablet } from '@/utils/dom-utils';
export default {
  name: 'DashboardCard',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    return {
      largeThanTablet
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.s-dashboard-card {
  position: relative;
  $s: &;
  min-height: 480px;
  width: calc(100vw - 24px);
  &__content {
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    background-color: $white;
  }
  &__title {
    margin-top: 16px;
  }
  &__main {
    position: absolute;
    z-index: 1;
    padding: 48px 32px;
    &-bottom {
      margin-top: 32px;
    }
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
  }
  &__media {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &.media {
    #{$s}__media {
      .e-adaptive-media__image {
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
      }
    }
  }
  &.content {
    #{$s}__media {
      position: unset;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    #{$s}__main {
      position: unset;
    }
  }
  &.car {
    overflow: hidden;
    #{$s}__media {
      height: grid-width-m(5);
      right: 0;
      bottom: 48px;
      .e-adaptive-media__main {
        background-color: transparent;
        .e-adaptive-media__image {
          background-color: transparent;
        }
      }
    }
  }
  .small {
    padding: 0;
    #{$s}__media {
      position: unset;
      height: grid-width-m(5);
      .e-adaptive-media__main {
        .e-adaptive-media__image {
          background-color: transparent;
        }
      }
    }
    #{$s}__main {
      position: unset;
      padding: 48px;
    }
  }
  @include tablet-landscape {
    min-height: 50vw;
    width: 100%;
    &__main {
      padding: 64px;
    }
    &__content {
      min-height: grid-width(12);
    }
    .small {
      min-height: auto;
      height: fit-content;
      width: grid-width(9);
      #{$s}__media {
        height: 20vw;
      }
    }
    &.car {
      #{$s}__media {
        height: grid-width(5);
      }
    }
  }
}
</style>
