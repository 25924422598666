<template>
  <div class="s-checkout-finance" v-show="stepCode === 'finance'">
    <jss-rich-text class="text-primary" :field="fields.title" :type="fields.titleType" rich />
    <jss-rich-text class="text-info" :field="fields.subtitle" tag="div" />
    <div class="s-checkout-finance__customer-types">
      <div class="s-checkout-finance__customer-type" :class="[{ active: customerType?.code === ct?.code }]" v-for="ct in customerTypes" :key="ct.id" @click.stop="onCtClick($event, ct)">
        <icon :field="ct.icon" />
        <span v-html="ct.name" />
      </div>
    </div>
    <dynamic-form ref="businessFormRef" :form="fields.businessForm" v-show="customerType?.code === 'business'" @change="onBusinessFormChange" />
    <jss-rich-text class="fs-24 mg-b-16" :field="fields.paymentTitle" />
    <div class="mg-b-12" v-html="$formatString(fields.paymentLabel?.value, { model: $formatModel(carModel?.name, 'capitalize') })" />
    <div class="s-checkout-finance__payment-options">
      <div
        class="s-checkout-finance__payment-option"
        :class="[{ active: paymentOption?.name === payment?.name }]"
        v-html="payment.title"
        v-for="payment in paymentOptions"
        :key="payment.name"
        @click.stop="onPaymentOptionClick($event, payment)"
      />
    </div>
    <div class="text-desc mg-b-24" v-html="paymentOption?.description" />
    <div class="pd-b-60 hide-empty" v-if="paymentOption?.name !== 'cash'">
      <jss-image class="img-width-fix mg-b-24" :media="fields.regulatoryImage" />
      <jss-rich-text class="text-desc mg-b-24" :field="fields.regulatoryText" />
      <div class="s-checkout-finance__change-finance" @click="onChangeFinance" v-if="simulation || financeQuote">
        <jss-rich-text class="s-checkout-finance__change-finance-text" :field="fields.changeFinanceText" />
        <icon :field="fields.changeFinanceIcon" />
      </div>
      <checkout-loan-detail
        :fields="fields"
        :payment-option="paymentOption"
        :simulation="simulation"
        :finance-quote="financeQuote"
        :car-series="carSeries"
        :total-amount="totalAmount"
        :currency="currency"
      />
    </div>
    <jss-rich-text class="fs-24 mg-b-8" :field="fields.agentTitle" />
    <jss-rich-text class="mg-b-8" :field="fields.agentSubtitle" />
    <dynamic-form :form="fields.agentForm" :data="agentFormCfg" ref="agentFormRef" @change="onAgentFormChange" />
    <div class="mg-b-8">
      <transition :css="false" @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
        <trade-in :fields="fields" :agent="agentFormCfg?.store.controlProps?.selectedOption" :car-model="carModel" ref="tradeInRef" />
      </transition>
    </div>
  </div>
</template>

<script>
/**
 * @typedef CheckoutOrderDetailFields
 * @property {SimpleField} title
 * @property {SimpleField} subtitle
 * @property {Array<CustomerType>} customerTypes
 * @property {DropLinkField<CustomerType>} defaultCustomerType
 * @property {SimpleField} paymentTitle
 * @property {SimpleField} paymentLabel
 * @property {Array<PaymentOption>} paymentOptions
 * @property {SimpleField} paymentDescription
 * @property {ImageField} regulatoryImage
 * @property {SimpleField} regulatoryText
 * @property {SimpleField} changeFinanceText
 * @property {ImageField} changeFinanceIcon
 * @property {SimpleField} loanTitle
 * @property {SimpleField} loanDescription
 * @property {SimpleField} selectTitle
 * @property {SimpleField} agentTitle
 * @property {SimpleField} agentSubtitle
 * @property {Form} agentForm
 * */
/**
 * @typedef FinanceRef
 * @property {() => void} init
 * @property {() => void} fillReservation
 * @property {() => Promise<Object>} validate
 * */
import { inject, reactive, toRefs } from 'vue';
import { sitecoreProps } from '@/utils/constants';
import { transitions } from '@/utils/transitions';
import { merge } from 'lodash';
import BUS_EVENTS from '@/utils/bus-events';

export default {
  name: 'checkout-finance',
  emits: ['update:customerType', 'update:paymentOption', 'change'],
  props: {
    step: {
      type: Number
    },
    stepCode: {
      type: String
    },
    fields: {
      type: Object
    },
    agentOptions: {
      type: Array
    },
    blindOrder: {
      type: Object
    },
    carSeries: {
      type: Object
    },
    carModel: {
      type: Object
    },
    paymentOptions: {
      type: Array
    },
    customerTypes: {
      type: Array
    },
    paymentOption: {
      type: Object
    },
    customerType: {
      type: Object
    },
    simulation: {
      type: Object
    },
    /** @type CwFinanceResult */
    financeQuote: {
      type: Object
    },
    totalAmount: {
      type: Number
    },
    currency: {
      type: String,
      default: 'EUR'
    },
    financeLevel2: {
      type: Boolean
    },
    ...sitecoreProps
  },
  setup(props, ctx) {
    const $bus = inject('$bus');
    const checkoutMethods = inject('checkoutMethods');
    const methods = {
      init() {
        const options = {
          controlProps: {
            options: props.agentOptions
          }
        };
        if (props.blindOrder?.storeId) {
          merge(options, {
            controlProps: {
              selectedOption: props.agentOptions?.find((x) => x.code === props.blindOrder.storeId)
            }
          });
        }
        state.agentFormRef.updateItem('store', options);
      },
      onCtClick(e, ct) {
        if (props.customerType?.code === ct.code) return;
        ctx.emit('update:customerType', ct);
      },
      onPaymentOptionClick(e, paymentOption) {
        if (props.paymentOption?.name === paymentOption.name) return;
        ctx.emit('update:paymentOption', paymentOption);
      },
      async validate() {
        let businessValid = true,
          businessData = null,
          tradeInValid = true,
          tradeInData = null,
          tradeInLeadTypes = null;
        if (state.businessFormRef && props.customerType?.code === 'business') {
          [businessValid, businessData] = await state.businessFormRef.validate();
        }
        const [agentValid, agentData] = await state.agentFormRef.validate();
        if (state.tradeInRef) {
          [tradeInValid, tradeInData, tradeInLeadTypes] = await state.tradeInRef.validate();
        }
        if (!businessValid || !agentValid || !tradeInValid) {
          return null;
        }
        return {
          customerType: props.customerType,
          paymentOption: props.paymentOption,
          agent: agentData.store,
          businessData: businessData,
          tradeInData: tradeInData,
          tradeInLeadTypes
        };
      },
      fillReservation() {
        if (props.blindOrder?.storeId) {
          state.agentFormRef.updateItem('store', {
            controlProps: {
              selectedOption: props.agentOptions.find((x) => x.code === props.blindOrder.storeId)
            }
          });
        }
      },
      onChangeFinance() {
        $bus.emit(BUS_EVENTS.OPEN_FINANCE_CALCULATOR);
      },
      onBusinessFormChange() {
        const data = state.businessFormRef.getValues();
        console.log('business data', data);
        ctx.emit('change', 'businessData', data);
      },
      onAgentFormChange(name, value) {
        if (name === 'store') {
          checkoutMethods.updateAgent(value);
        }
      }
    };

    const state = reactive({
      /**@type DynamicForm*/
      businessFormRef: null,
      /**@type DynamicForm*/
      agentFormRef: null,
      agentFormCfg: {
        store: {
          controlProps: {
            options: [],
            selectedOption: null
          }
        }
      },
      /**@type DynamicForm*/
      financeFormRef: null,
      /**@type TradeInRef*/
      tradeInRef: null
    });
    return {
      ...toRefs(state),
      ...methods,
      transitions
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-checkout-finance {
  &__customer-types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 32px;
  }
  &__customer-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 16px;
    border: 1px solid $grey-taubmans;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s cubic-bezier(0.055, 0.215, 0, 1);
    border-collapse: collapse;
    > span {
      &:nth-child(2) {
        text-align: center;
      }
    }
    .e-icon {
      margin-bottom: 8px;
    }
    &.active {
      border-color: $black;
      background: $black;
      color: $white;
      .e-icon {
        color: $yellow;
      }
    }
  }
  &__payment-options {
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__payment-option {
    overflow: hidden;
    padding: 16px;
    text-align: center;
    transition: all 0.2s cubic-bezier(0.055, 0.215, 0, 1);
    border: 1px solid $black;
    cursor: pointer;
    user-select: none;
    &.active {
      background: $black;
      color: $white;
    }
  }
  &__change-finance {
    padding: 16px 48px;
    border: 1px solid $black;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s cubic-bezier(0.055, 0.215, 0, 1);
    cursor: pointer;
    margin-bottom: 24px;
    .e-icon {
      margin-left: 8px;
    }
    &:hover {
      background: $black;
      color: $white;
    }
  }
  @include tablet-landscape {
    &__payment-options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
