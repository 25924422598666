<script setup>
import { computed } from 'vue';
import { isNullOrEmpty } from '@/utils/obj-utils';

const props = defineProps({
  fields: {
    type: Object
  },
  data: {
    type: Object
  },
  dealer: {
    type: Object
  },
  selectable: {
    type: Boolean,
    default: true
  },
  selectedDealer: {
    type: Object
  },
  theme: {
    type: String
  }
});
const checked = computed(() => props.selectedDealer?.id === props.dealer?.id);
</script>

<template>
  <div class="s-dealer-card-v1" :class="[{ selectable, checked, [`theme-${theme}`]: !isNullOrEmpty(theme) }]" v-if="dealer">
    <div class="s-dealer-card-v1__header" v-if="selectable">
      <site-radio :checked="checked" />
      <!--      <div>{{ $t(dealer.stripeOpen ? 'Stripe available' : 'Stripe unavailable') }}</div>-->
    </div>
    <div class="s-dealer-card-v1__name fs-18 fw-500 ls-2 __lh-1_5">{{ dealer.storeName }}</div>
    <div class="s-dealer-card-v1__addr">
      <div class="s-dealer-card-v1__distance" v-if="dealer.metre">{{ $formatDistance(dealer.metre, 'm', 0) }}</div>
      <span>{{ $buildAddress(dealer.addressDetail, dealer.districtName, dealer.cityName, dealer.countryName) }}</span>
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-dealer-card-v1 {
  padding: 24px 24px 16px 24px;
  border: 1px solid $grey-89;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: all 0.2s cubic-bezier(0, 0, 0.4, 0.2);
  user-select: none;
  &.selectable {
    cursor: pointer;
  }
  &.checked {
    border-color: $black;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__distance {
    display: inline-block;
    font-size: 12px;
    color: $grey;
    border-right: 1px solid currentColor;
    padding-right: 12px;
    margin-right: 12px;
    white-space: nowrap;
  }
  &__addr {
    color: $grey-next;
  }
  &.theme {
    &-dark {
      background: $black;
      border-color: $grey-dark;
      color: rgba($white, 0.7);
    }
  }
}
</style>
