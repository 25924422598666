<template>
  <div v-bind="$attrs" class="s-form-layout" v-if="$props?.fields" :class="[{ reverse: fields.reverse?.value, 'full-width-image': fields.fullWidthImage?.value }]" ref="rootEl">
    <div class="s-form-layout__side-image" ref="sideImageEl">
      <adaptive-image class="s-form-layout__image" :field="fields.image" :is-background="!$deviceComputes.isMobileOrTablet.value" :class="[{ 'open-curtain-finished': openCurtainFinished }]">
        <div class="s-form-layout__image-content" v-if="$slots['image-content']">
          <slot name="image-content" />
        </div>
      </adaptive-image>
    </div>
    <div class="s-form-layout__main" v-if="$slots.default">
      <div class="s-form-layout__content" :class="[{ [`theme-${$settingValue(fields.contentTheme)}`]: $settingValue(fields.contentTheme) }]">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @typedef FormLayout
 * @property {HTMLElement} rootEl
 * @property {FormLayoutFields} fields
 // * @property {function: void} updateScrollMagic
 * */
/**
 * @typedef FormLayoutFields
 * @property {SimpleField} reverse
 * @property {ImageField} image
 * @property {SimpleField} scrollText
 * @property {CheckField} fullWidthImage
 * @property {GlobalSettingEntry} contentTheme
 * */
import { nextTick, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import { isMobile } from '@/utils/dom-utils';
import { loadScrollMagic } from '@/utils/site-utils';

export default {
  name: 'form-layout',
  props: {
    /**@type FormLayoutFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    let controller, scene;
    const state = reactive({
      /**@type HTMLElement*/
      rootEl: null,
      /**@type HTMLElement*/
      sideImageEl: null,
      openCurtainFinished: false
    });
    const _methods = {
      onScroll(e) {
        _methods.checkHeader();
      },
      onResize(e) {
        _methods.checkHeader();
      },
      checkHeader() {
        const $header = document.querySelector('.c-header');
        if (!$header || $header.classList.contains('theme-transparent') || $header.classList.contains('hide')) {
          state.rootEl?.classList.add('full-height');
        } else {
          state.rootEl?.classList.remove('full-height');
        }
      }
    };
    onMounted(() => {
      _methods.checkHeader();
      window.addEventListener('scroll', _methods.onScroll);
      window.addEventListener('resize', _methods.onResize);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', _methods.onScroll);
      window.removeEventListener('resize', _methods.onResize);
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-form-layout {
  $c: &;
  @include grid-container;
  position: relative;
  &__side-image {
    display: none;
    margin-bottom: 24px;
    .e-background-image {
      &__image {
        height: auto;
        width: 100%;
      }
    }
    @include grid-block(1, 12, 1);
    .e-animated-content__main {
      height: 100%;
    }
  }
  &__image-wrapper {
    position: relative;
    z-index: -1;
  }
  &__image {
    &.open-curtain-finished {
      //transition: transform 500ms ease;
      //transform: scale(1.1);
    }
  }
  &__image-content {
    width: 100%;
    height: 100%;
    &:empty {
      display: none;
    }
  }
  &__main {
    @include grid-block(2, 10, 2);
    padding-top: 24px;
    padding-bottom: 24px;
    position: relative;
  }
  &__content {
    height: 100%;
    @include component-themes();
    &[class*='theme-'] {
      padding: 0 0 $space-24 0;
    }
    &:empty {
      display: none;
    }
  }
  &.full-width-image {
    #{$c}__side-image {
      display: block;
      @include grid-block(1, 12, 1);
      height: 100vh;
      position: relative;
    }
    #{$c}__image {
      height: 100%;
    }
  }
  @include tablet-landscape {
    min-height: 100vh;
    &__side-image {
      height: 100vh;
      display: block;
      @include grid-block(1, 12, 1);
      position: sticky;
      top: tablet-header-height();
      transition: all 0.2s cubic-bezier(0.1, 0.1, 0.1, 1);
      margin-bottom: 0;
    }
    &__image-wrapper {
      height: 100vh;
    }
    &__image {
      height: 100%;
      .e-background-image__content {
        @include height-except-header;
        color: $white;
      }
      &-content {
        height: 100%;
      }
    }
    &__main {
      padding-top: 56px;
      @include grid-block(15, 8, 1);
    }
    &.reverse {
      #{$c}__side-image {
        @include grid-block(13, 12, 1);
      }

      #{$c}__main {
        @include grid-block(3, 8, 1);
      }
    }
    &.full-width-image {
      #{$c}__side-image {
        @include grid-block(1, 24, 1);
        position: sticky;
      }

      &__image-wrapper {
        width: 100%;
      }
    }
  }
  @include desktop {
    &__side-image {
      top: desktop-header-height();
    }
    &.full-height {
      #{$c}__side-image {
        top: 0;
      }
    }
  }
}
</style>
