<template>
  <div class="s-finance-calculator">
    <div class="s-finance-calculator__santander" style="width: 600px;zoom:0.8" v-if="isFrontEnd">
      <scf-quoting type="modal" visible="false">
        <button class="s-finance-calculator__button" slot="customAction" @click="onButtonClick">{{ $tu('Confirm') }}</button>
      </scf-quoting>
    </div>
    <ca-auto-bank-calculator ref="cabRef" />
  </div>
</template>

<script>
/**
 * @typedef FinanceCalculatorRef
 * @property {() => Promise<[CwFinance, Object]>} configCodeWaver
 * @property {(payload: Object) => Promise<ScfSimulation>} config
 * */
import services from '@/services';
import { inject, nextTick, reactive, toRefs } from 'vue';
import { merge } from 'lodash';
import { formatModel, getCurrentAlpha2Code } from '@/utils/site-utils';
import api from '@/api';
import { isNullOrEmpty, isObject } from '@/utils/obj-utils';
import { ERROR_CODES } from '@/utils/constants';
import BUS_EVENTS from '@/utils/bus-events';
import { canUseDOM, removeModalClasses } from '@/utils/dom-utils';

export default {
  name: 'finance-calculator',
  setup() {
    const $bus = inject('$bus');
    const toast = inject('toast');
    let config, assetInfo;
    let cwFinanceEventAttached = false;
    const methods = {
      async configCodeWaver() {
        const [cwFinance, config] = await services.finance.loadCodeWaverFinancePlugin();
        if (!cwFinanceEventAttached) {
          cwFinanceEventAttached = true;
          setTimeout(() => {
            document.querySelector('.cw-finance .close').addEventListener('click', () => {
              removeModalClasses('santander-open');
            });
          }, 1000);
        }
        // const res = cwFinance.getEntities();
        // console.log('entry res', res);
        return [cwFinance, config];
      },
      async config(options) {
        let scfSimulation = global.scfSimulation;
        const countryCode = getCurrentAlpha2Code();
        console.log('santander options', options);
        if (!scfSimulation) {
          [scfSimulation, config] = await services.finance.loadSantanderLoanPlugin();
          const [assetRes] = await api.finance.getAssetInfo(null, {
            channelCode: config.channelCode,
            country: countryCode,
            model: options.model // 'ELETRE Base' // options.model
          });
          if (!assetRes?.length || isNullOrEmpty(assetRes[0].assetCode)) {
            await toast.showExByCode(ERROR_CODES.AssetLoadError);
            return [null, null];
          }
          assetInfo = assetRes[0];
        }
        if (!scfSimulation) {
          await toast.showExByCode(ERROR_CODES.SantanderLoadError);
          return [null, assetInfo];
        }
        let payload = merge(
          {
            country: countryCode,
            assetId: assetInfo.assetCode ?? '',
            model: formatModel(options.model),
            // assetPrice: FINANCE_DEMO_PRICES[assetInfo.assetCode],
            // loanType: 'LoanballoonKM',
            term: 60,
            typeVehicle: 'New'
            // registrationDate: formatDate(new Date())
          },
          options
        );
        if (!isObject(payload.forceSelection) || isNullOrEmpty(payload.forceSelection)) {
          payload.forceSelection = null;
        }
        console.log('santander payload', payload, scfSimulation);
        scfSimulation.configure(payload);
        return [scfSimulation, assetInfo];
      },
      async configCab({ preSelection, payload, carSeries, carModel, isStock, onClose, onSelect }) {
        await state.cabRef.init({ preSelection, payload, carSeries, carModel, isStock, onClose, onSelect });
      },
      openCab() {
        state.cabRef.open();
      },
      onButtonClick() {
        global.scfSimulation.closeSimulator();
        nextTick(() => {
          $bus.emit(BUS_EVENTS.CONFIRM_FINANCE_CALCULATOR, global.scfSimulation);
        });
      }
    };
    const state = reactive({
      isFrontEnd: false,
      cabRef: null
    });
    if (canUseDOM()) {
      state.isFrontEnd = true;
    }
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-finance-calculator {
  &__santander {
    zoom: 0.8;
  }
  &__button {
    background: $yellow;
    border-color: $yellow;
    color: $black;
  }
  scf-quoting {
    > button {
      display: none;
    }
  }
  @include desktop {
    &__santander {
      width: grid-width(10);
    }
  }
}
</style>
