<template>
  <div class="s-image-slider-vertical-mobile">
    <div class="s-image-slider-vertical-mobile__slide" v-for="slide in fields.slides" :key="slide.id">
      <jss-rich-text class="s-image-slider-vertical-mobile__slide-title" :field="slide.fields.title" :tag="slide.fields.titleType?.fields.phrase.value ?? 'div'" />
      <background-image class="s-image-slider-vertical-mobile__slide-image" :image="$adaptiveImageField(slide.fields.desktopImage, slide.fields.mobileImage)" />
      <jss-rich-text class="s-image-slider-vertical-mobile__slide-body" :field="slide.fields.body" tag="div" />
      <div class="s-image-slider-vertical-mobile__slide-buttons">
        <site-button v-bind="slide.fields.button" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageSliderVerticalMobile',
  props: {
    /**@type ImageSliderVerticalFields*/
    fields: {
      type: Object
    }
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-image-slider-vertical-mobile {
  $c: &;
  padding: 0 grid-width(2);
  &__slide {
    &-title {
      margin-bottom: $space-40;
      margin-top: $space-60;
    }
    &-image {
      height: grid-width(math.div(20 * 3, 4));
      margin-bottom: $space-20;
    }
    &-body {
      margin-bottom: $space-30;
    }
  }
  @include tablet-landscape {
    display: none;
  }
}
</style>
