<script setup>
import { getCurrentInstance, reactive, ref } from 'vue';
import { settingValue } from '@/utils/site-utils';
import { sleep } from '@/utils/dom-utils';
import services from '@/services';
import utils from '@/utils';
import { merge } from 'lodash';
import { formatString, ifEmpty } from '@/utils/string-utils';
import api from '@/api';
import { isNullOrEmpty } from '@/utils/obj-utils';

const { proxy } = getCurrentInstance();
const { $jss } = proxy;
const containerEl = ref();
const state = reactive({
  /**@type {?ModalRef}*/
  modalRef: null,
  page: null,
  lang: null,
  alpha2Code: null,
  config: null,
  /**@type {?CarSeries}*/
  carSeries: null,
  /**@type {?CarModel}*/
  carModel: null,
  isStock: false,
  assetInfo: null,
  preSelection: null,
  /**@type {?CAABCalculatorInitializePayload}*/
  payload: null,
  /**@type {?Function}*/
  onCloseCallback: null,
  /**@type {?Function}*/
  onSelectCallback: null
});
const buildConfig = () => {
  let touchpoint;
  if (state.isStock) {
    touchpoint = formatString(ifEmpty(state.config?.stockTouchPoint, 'lotus-stock-##lang##'), {
      lang: state.lang
    });
  } else {
    touchpoint = formatString(ifEmpty(state.config?.touchpoint, 'lotus-##lang##'), {
      lang: state.lang
    });
  }
  const fuelType = settingValue(state.carModel?.fuelType, '2');
  const objectType = settingValue(state.carModel?.vehicleObjectType, '1');
  const vehicleInfo = {
    versionCode: state.assetInfo?.assetId ?? state.carModel?.caVersionCode,
    versionDescription: state.carModel?.caVersionDesc,
    modelCode: state.carSeries?.caModelCode,
    modelDescription: state.carSeries?.caModelDesc,
    brandCode: 'LOTUS',
    brandDescription: 'LOTUS',
    // originalPrice: 133568.3,
    promoPrice: 0,
    // priceCurrency: 'EUR',
    energyClass: 'A',
    euroClass: 'EURO 6d temp',
    fuelType: fuelType,
    condition: 'N',
    registrationDate: '2020-01',
    mileage: 0,
    usePriceWithVat: true,
    optionalTotalValue: 0,
    objectType: objectType
  };
  merge(vehicleInfo, state.payload);
  const body = {
    targetElt: null,
    config: {
      debug: state.config?.debug === '1',
      cookies: {
        essential: true,
        performance: true,
        comfort: true,
        advertising: true
      },
      marketCode: state.alpha2Code,
      lang: state.lang,
      touchpoint: touchpoint,
      theme: state.config?.theme ?? 'lotus',
      closeBtn: true,
      onClose: (...args) => {
        sleep(100).then(() => {
          document.body.style.overflow = 'visible';
        });
        state.onCloseCallback && state.onCloseCallback(...args);
      },
      onSelect: (...args) => {
        state.onSelectCallback && state.onSelectCallback(...args);
      },
      vehicle: vehicleInfo
    }
  };
  if (!isNullOrEmpty(state.preSelection?.configId)) {
    body.configId = state.preSelection?.configId;
  }
  console.log('ca auto bank payload', body);
  return body;
};
/**
 * @param {{
 *   payload: CAABCalculatorInitializePayload,
 *   carSeries: CarSeries,
 *   carModel: CarModel,
 *   isStock: Boolean,
 *   onCloseCallback?: Function,
 *   onSelectCallback?: Function
 * }} data
 * @return {Promise<void>}
 */
const init = async ({ preSelection, payload, carSeries, carModel, isStock, onClose, onSelect }) => {
  console.log('cab payload', payload);
  state.preSelection = preSelection;
  state.payload = payload;
  state.carSeries = carSeries;
  state.carModel = carModel;
  state.isStock = isStock;
  state.onCloseCallback = onClose;
  state.onSelectCallback = onSelect;
  if (!global.FCABFC3) {
    state.page = $jss.routeData();
    state.alpha2Code = utils.site.getPageAlpha2Code(state.page);
    state.lang = state.alpha2Code.toLowerCase();
    [, state.config] = await services.finance.loadCAAutoBankPlugin();
  }
  if (!state.assetInfo) {
    const [assetRes] = await api.finance.getAssetInfo(null, {
      channelCode: state.config.channelCode,
      country: state.alpha2Code,
      model: carModel?.assetName
    });
    if (assetRes?.length > 0) {
      state.assetInfo = assetRes[0];
    }
  }
  const body = buildConfig();
  global.FCABFC3?.init(body);
};
const open = () => {
  const body = buildConfig();
  global.FCABFC3?.init(body);
};
defineExpose({ init, open });
</script>

<template>
  <div ref="containerEl" />
</template>

<style lang="scss"></style>
