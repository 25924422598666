<template>
  <div class="s-hero-support-item">
    <icon :field="fields.icon" class="s-hero-support-item__icon" />
    <adaptive-image class="s-hero-support-item__image" :field="fields.adaptiveImage" v-if="fields.adaptiveImage" />
    <background-image class="s-hero-support-item__image" :image="fields.image" v-else-if="fields.image.value?.src" />
    <jss-rich-text class="s-hero-support-item__title" :field="fields.title" />
    <jss-rich-text class="s-hero-support-item__body" :field="fields.body" />
    <site-button :class="fields?.btnLeft?.value ? `s-hero-support-item__button` : ``" v-bind="fields.button" />
  </div>
</template>

<script>
/**
 * @typedef HeroSupportItemFields
 * @property {ImageField} icon
 * @property {ImageField}  image
 * @property {SimpleField} title
 * @property {GlobalSettingEntry} titleType
 * @property {SimpleField} body
 * @property {ButtonField} button
 * @property {Array<Tag>} tags
 * */
export default {
  name: 'hero-support-item',
  props: {
    fields: {
      type: Object
    },
    parallax: {
      type: Boolean,
      default: false
    },
    hasAnimation: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-hero-support-item {
  $c: &;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
  &__icon {
    display: block;
    margin-bottom: $space-20;
    > svg {
      width: 30px;
      height: 30px;
    }
  }
  &__image {
    width: 100%;
    height: 280px;
    margin-bottom: $space-20;
  }
  &__title {
    @include h6;
    margin-top: $space-20;
  }
  &__body {
    margin-top: $space-12;
  }
  .e-site-button {
    margin-top: $space-30;
  }
  &__button {
    width: fit-content;
  }
  &.item-align- {
    &center {
      align-items: center;
      text-align: center;
    }
    &right {
      align-items: flex-end;
      text-align: end;
    }
  }
  @include tablet-landscape {
    margin-bottom: 0;
    position: relative;
    padding-bottom: 100px;
    &__icon {
      display: block;
      margin-bottom: $space-30;
      > svg {
        width: 50px;
        height: 50px;
      }
    }
    &__image {
      height: grid-width(math.div(math.div(16, 3) * 3, 4));
    }
    &__title {
      margin-top: $space-20;
    }
    &__body {
      margin-top: $space-20;
    }
    .e-site-button {
      position: absolute;
      bottom: 16px;
      width: fit-content;
      margin-top: 0;
    }
  }
}
</style>
