<script setup>
import { ref, watch } from 'vue';
import { transitions } from '@/utils/transitions';
const emit = defineEmits(['update:opened']);
const props = defineProps({
  title: {
    type: String
  },
  opened: {
    type: Boolean
  }
});
const active = ref(props.opened);
watch(
  () => props.opened,
  (opened) => {
    active.value = opened;
  }
);
const toggle = () => {
  const result = !active.value;
  active.value = result;
  emit('update:opened', result);
};
</script>

<template>
  <div class="s-uc-fm-sec" v-if="!$isNullOrEmpty(title)">
    <div class="s-uc-fm-sec__header">
      <div class="s-uc-fm-sec__text __ft-pl" v-html="title" @click="toggle" />
      <span class="s-uc-fm-sec__icon" :class="[{ active }]" @click="toggle" />
    </div>
    <transition :css="false" :duration="336" @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
      <div class="s-uc-fm-sec__content" v-show="active">
        <slot />
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-uc-fm-sec {
  &__header {
    position: relative;
    padding: 32px 0;
    display: flex;
    align-items: center;
    line-height: 1;
  }
  &__text {
    cursor: pointer;
    flex-grow: 1;
  }
  &__icon {
    position: absolute;
    right: 0;
    cursor: pointer;
    display: block;
    width: 12px;
    height: 12px;
    &:before {
      content: '';
      width: 2px;
      height: 100%;
      background: $black;
      position: absolute;
      top: 0;
      left: calc(50% - 1px);
    }
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background: $black;
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
    }
    &.active {
      &:before {
        opacity: 0;
      }
    }
  }
  &__content {
    color: $grey-next;
  }
}
</style>
