<template>
  <div class="s-dashboard-layout" v-if="fields">
    <div class="s-dashboard-layout__header" :class="[{ active: collapsed }]">
      <div class="s-dashboard-layout__header-logo" @click="$redirectTo(dashboardLink)">
        <Icon class="s-dashboard-layout__header-logo-icon" size="large" :field="fields.logoIcon" />
        <JssRichText class="s-dashboard-layout__header-logo-text" :field="fields.logoText" />
      </div>
      <div class="s-dashboard-layout__header-btns">
        <template v-for="menu in visibleMenus" :key="menu.code">
          <site-link class="s-dashboard-layout__header-btns-orders" :link="{ value: menu.link }">
            <div class="s-dashboard-layout__header-btns-orders-text" v-html="menu.link.text" />
            <div class="s-dashboard-layout__header-btns-orders-number" v-html="menu.count" />
          </site-link>
        </template>
        <!--        <div class="s-dashboard-layout__header-btns-orders" @click="$redirectTo(orderListLink)">-->
        <!--          <div class="s-dashboard-layout__header-btns-orders-text">-->
        <!--            <JssRichText :field="fields.orderText" />-->
        <!--          </div>-->
        <!--          <div class="s-dashboard-layout__header-btns-orders-number">{{ orderCount }}</div>-->
        <!--        </div>-->
        <div class="s-dashboard-layout__header-btns-menu" @click.prevent="toggleMenu" ref="menuEl">
          <div class="s-dashboard-layout__header-btns-menu-text" v-if="$deviceComputes.largeThanTablet.value">
            {{ $tu('Account') }}
          </div>
          <Icon class="s-dashboard-layout__header-btns-menu-icon" :class="{ collapsed }" name="down" />
        </div>
      </div>
      <transition name="options-ani">
        <div class="s-dashboard-layout__menu" v-if="collapsed">
          <template v-if="dropMenus.length > 0">
            <SiteLink class="s-dashboard-layout__menu-item" :class="{ active: $equalString(activeMenu, menu.code) }" :link="{ value: menu.link }" v-for="menu in dropMenus" :key="menu.code">
              <div class="s-dashboard-layout__menu-item-left">
                <div class="s-dashboard-layout__menu-item-icon">
                  <Icon :svg="menu.icon.svgCode" />
                </div>
                <div class="s-dashboard-layout__menu-item-link" v-html="menu.link.text" />
              </div>
              <div class="s-dashboard-layout__menu-item-count" v-html="menu.count" />
            </SiteLink>
          </template>
          <div class="s-dashboard-layout__menu-item s-dashboard-layout__sign-out" @click="onSignOut">
            <JssText :field="fields.signOutText" />
          </div>
        </div>
      </transition>
    </div>
    <div class="s-dashboard-layout__main" v-show="loaded">
      <slot />
    </div>
    <transition name="fade-in">
      <div class="s-dashboard-layout__mask" v-if="$deviceComputes.isMobileOrTablet.value && collapsed" />
    </transition>
  </div>
</template>
<script>
/**
 * @typedef DashboardLayoutFields
 * @property {{ fields: DashboardHeaderFields }} dashboardHeader
 * */
/**
 * @typedef DashboardHeaderFields
 * @property {GlobalSettingEntry} theme
 * @property {IconField} logoIcon
 * @property {SimpleField} logoText
 * @property {SimpleField} orderText
 * @property {Array<Object>} menus
 * @property {SimpleField} signOutText
 * @property {SimpleField} configurationText
 * @property {CheckField} hideLifeOrder
 * @property {CheckField} hideVehicleOrder
 * */
import { getCurrentInstance, reactive, toRefs, onMounted, onBeforeUnmount, inject, computed } from 'vue';
import useAppStore from '@/store/appStore';
import api from '@/api';
import services from '@/services';
import { getCarModel, getCarSeries } from '@/services/siteService';
import { equalString, formatString } from '@/utils/string-utils';
import { isNullOrEmpty, deepCopy, isArray } from '@/utils/obj-utils';
import { formatOrderDate } from '@/utils/date-utils';
import { appendQuery, getBetterUrl } from '@/utils/uri-utils';
import { getGlobalConfigs, getPageAlpha2Code } from '@/utils/site-utils';
import { arraySort } from '@/utils/array-utils';
import { onWidthChanged } from '@/hooks/onWidthChanged';
import { debounce, difference, differenceBy } from 'lodash';
import { unWrapSitecoreItem } from '@/utils/sitecore-utils';
export default {
  name: 'DashboardLayout',
  props: {
    fields: {
      type: Object
    },
    activeMenu: {
      type: String,
      default: 'dashboard'
    }
  },
  setup(props) {
    if (!props.fields) return;
    const toast = inject('toast');
    const loading = inject('loading');
    const appMethods = inject('appMethods');
    const { /**@type DeviceComputes */ deviceComputes } = inject('device-common');
    /**@type AppStore*/
    const appStore = useAppStore();
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const page = $jss.routeData();
    const alpha2Code = getPageAlpha2Code(page);
    const state = reactive({
      menuEl: null,
      orders: [],
      collapsed: false,
      dashboardLink: null,
      orderListLink: null,
      orderDetailsLink: null,
      mhpLink: null,
      shopOrderDetailsLink: null,
      shopWinterWheelsOrderDetailLink: null,
      servicePlanOrderDetailLink: null,
      loaded: false,
      configurationList: null,
      configurationCount: 0,
      shopBikeSpuId: null,
      ordersTotal: 0,
      mallOrdersTotal: 0,
      partsOrdersTotal: 0,
      serviceOrdersTotal: 0,
      menus: [],
      visibleMenus: [],
      dropMenus: []
    });
    const computes = {
      orderCount: computed(() => state.ordersTotal + state.mallOrdersTotal + state.partsOrdersTotal + state.serviceOrdersTotal)
    };
    const _methods = {
      buildMenu() {
        let visibleMenus = [],
          dropMenus = [];
        if (!isArray(state.menus)) return;
        const currentMenu = state.menus.find((x) => equalString(x.code, props.activeMenu));
        if (deviceComputes.isMobileOrTablet.value) {
          if (!currentMenu?.packUpOnDesktop && currentMenu) {
            visibleMenus = [currentMenu];
          } else {
            visibleMenus = state.menus.filter((x) => equalString(x.code, 'order-list'));
          }
          dropMenus = difference(state.menus, visibleMenus);
        } else {
          dropMenus = state.menus.filter((x) => x.packUpOnDesktop);
          visibleMenus = difference(state.menus, dropMenus);
        }
        state.visibleMenus = visibleMenus;
        state.dropMenus = dropMenus;
      },
      setMenuCount(code, count) {
        const menu = state.menus.find((x) => equalString(x.code, code));
        menu && (menu.count = count);
      },
      async getOrders() {
        let vinList = [];
        const [vinRes, vinEx] = await api.cidp.getControlCars();
        if (vinEx) {
          await toast.showEx(vinEx);
          if (vinEx.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (vinRes?.length) {
          vinList = vinRes.map((v) => v.vin);
        }
        const [res, ex] = await api.order.intactList(null, {
          customerId: appStore?.loginInfo?.lotusId,
          pageNum: 1,
          pageSize: 10000,
          vinList
        });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (!res) return;
        let orders = [];
        let ruleItems = [];
        const orderStatuses = await services.order.getOrderStatuses();
        orders = deepCopy(res?.filter((x) => x.orderStatus && x.orderType && !equalString(x.orderStatus, 'New') && !equalString(x.orderStatus, '100')));
        orders = orders.filter((x) => !equalString(x.countryCode, 'GB') || !equalString(x.orderType, '100') || !equalString(x.stockFlag, '2') || !x.stockOrder);
        for (let order of orders) {
          const carSeries = await getCarSeries(order.series);
          order.carSeries = carSeries;
          const carModel = await getCarModel(order.series, order.model);
          order.carModel = carModel;
          order.status = orderStatuses.find((x) => x.code == order.orderStatus);
          order.soldOut = !!carSeries?.soldOut;
          if (order.orderType === '200') {
            order.modelImage = order.carImages?.length > 0 ? order.carImages[0].imageUrl : null;
          } else {
            order.modelImage = carSeries?.image?.src;
          }
          if (equalString(order.orderType, '100') && equalString(order.orderStatus, '200')) {
            ruleItems.push({
              orderType: '200',
              carSeriesCode: carSeries?.code ?? '',
              country: order.countryCode
            });
          }
          order.userUpdateTime = null;
          if (order.intactOrderDateDto) {
            let { finalPayCompleteTime, paymentTime, signTime } = order.intactOrderDateDto;
            finalPayCompleteTime = finalPayCompleteTime ? new Date(finalPayCompleteTime).getTime() : 0;
            paymentTime = paymentTime ? new Date(paymentTime).getTime() : 0;
            signTime = signTime ? new Date(signTime).getTime() : 0;
            order.userUpdateTime = finalPayCompleteTime > paymentTime ? (finalPayCompleteTime > signTime ? finalPayCompleteTime : signTime) : paymentTime;
          }
        }
        const [resConfig] = await api.order.rule.query(null, { items: ruleItems });
        orders.forEach((o) => {
          o.jumpLink = null;
          o.configurable = resConfig.some((r) => equalString(r.carCode, o.series)) ? true : false;
          if (state.orderDetailsLink && equalString(o.orderType, '200')) {
            const href = getBetterUrl(state.orderDetailsLink, page.itemLanguage, false);
            o.jumpLink = appendQuery(href, { orderId: o.id });
            o.salesOrder = true;
          } else if (state.mhpLink && equalString(o.orderType, '100') && equalString(o.orderStatus, '200') && (isNullOrEmpty(o.series) || isNullOrEmpty(o.model) || o.configurable)) {
            const [, targetLang] = services.order.checkOrderLang(page, o.countryCode);
            if (o.soldOut) {
              o.jumpLink = null;
            } else if (o.carSeries?.noConfigure || (o.stockOrder && o.stockFlag == 2)) {
              const detailsHref = getBetterUrl(state.orderDetailsLink, page.itemLanguage, false);
              o.jumpLink = appendQuery(detailsHref, { orderId: o.id });
            } else {
              const href = formatString(state.mhpLink, {
                lang: targetLang,
                series: o.series?.toLowerCase() ?? 'eletre',
                model: ''
              });
              o.jumpLink = appendQuery(href, {
                access_token: appStore?.loginInfo?.token,
                blindOrderId: o.id
              });
            }
            o.salesOrder = false;
          } else if (equalString(o.orderType, '800')) {
            const href = getBetterUrl(state.orderDetailsLink, page.itemLanguage, false);
            o.jumpLink = appendQuery(href, { orderId: o.id });
            o.salesOrder = true;
          }
        });
        orders = arraySort(orders, 'userUpdateTime');
        state.orders = orders;
        state.ordersTotal = props?.fields?.hideVehicleOrder?.value ? 0 : orders.length;
        appStore.setOrderInfo(orders);
      },
      async getShopOrders() {
        const [res, ex] = await api.shop.getOrderList({
          pageNum: 1,
          pageSize: 10000,
          bizType: 51121001
        });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (!res || !res.records || res.records.length < 1) return;
        let records = [];
        res.records.forEach((item) => {
          if (item.items.length && item.items[0].spuBusId != state.shopBikeSpuId) {
            records.push(item);
          }
        });
        const orderStatuses = await services.order.getOrderStatuses();
        const { $jss } = proxy;
        const page = $jss.routeData();
        const shopOrderdetailsHref = getBetterUrl(state.shopOrderDetailsLink, page.itemLanguage, false);
        for (let order of records) {
          if (order.status == '51031003') {
            orderStatuses.forEach((item) => {
              if (item.code == '51031003' && item.stockCode == order.stockStatus) {
                order.status = item;
              } else if (item.code == '51031003' && order.stockStatus === 51041002 && item.stockCode == '51041003') {
                order.status = item;
              }
            });
          } else if (order.status == '51031006' && order.closeReason) {
            orderStatuses.forEach((item) => {
              if (item.code == '51031006' && item.stockCode == order.closeReason) {
                order.status = item;
              } else if (item.code == '51031006' && !item.stockCode) {
                order.status = item;
              }
            });
          } else {
            order.status = orderStatuses.find((x) => x.code == order.status);
          }
          order.series = order.items[0].name;
          order.paymentTime = order.payTime;
          order.userUpdateTime = order.dateCreate;
          order.createTime = order.dateCreate;
          order.orderId = order.orderCode;
          order.jumpLink = appendQuery(shopOrderdetailsHref, { orderCode: order.orderCode });
        }
        let orders = arraySort(records, 'userUpdateTime');
        state.mallOrdersTotal = props?.fields?.hideLifeOrder?.value ? 0 : records.length;
        appStore.setShopOrderInfo(orders);
      },
      async getPartsOrders() {
        const [res, ex] = await api.shop.getOrderList({
          pageNum: 1,
          pageSize: 10000,
          bizType: 51121007,
          saleAfterItemType: 1
        });

        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (!res || !res.records || res.records.length < 1) return;
        let records = [];
        res.records.forEach((item) => {
          if (item.items.length && item.items[0].spuBusId != state.shopBikeSpuId && item.payTime) {
            records.push(item);
          }
        });
        const orderStatuses = await services.order.getWheelsOrderStatuses();
        const { $jss } = proxy;
        const page = $jss.routeData();
        const winterWheelsOrderdetailsHref = getBetterUrl(state.shopWinterWheelsOrderDetailLink, page.itemLanguage, false);
        for (let order of records) {
          if (order.status == '51031003') {
            orderStatuses.forEach((item) => {
              if (item.code == '51031003' && item.stockCode == order.stockStatus) {
                order.status = item;
              } else if (item.code == '51031003' && order.stockStatus === 51041002 && item.stockCode == '51041003') {
                order.status = item;
              }
            });
          } else if (order.status == '51031006' && order.closeReason) {
            orderStatuses.forEach((item) => {
              if (item.code == '51031006' && item.stockCode == order.closeReason) {
                order.status = item;
              } else if (item.code == '51031006' && !item.stockCode) {
                order.status = item;
              }
            });
          } else {
            order.status = orderStatuses.find((x) => x.code == order.status);
          }
          order.series = order.items[0].name;
          order.paymentTime = order.payTime;
          order.userUpdateTime = order.dateCreate;
          order.createTime = order.dateCreate;
          order.orderId = order.orderCode;
          order.jumpLink = appendQuery(winterWheelsOrderdetailsHref, { orderCode: order.orderCode });
        }
        let orders = arraySort(records, 'userUpdateTime');
        state.partsOrdersTotal = props?.fields?.hideWheelsOrder?.value ? 0 : records.length;
        appStore.setPartsOrderInfo(orders);
      },
      async getServiceOrders() {
        const [res, ex] = await api.shop.getOrderList({
          pageNum: 1,
          pageSize: 10000,
          bizType: 51121007,
          saleAfterItemType: 6
        });

        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (!res || !res.records || res.records.length < 1) return;
        let records = [];
        res.records.forEach((item) => {
          if (item.items.length && item.items[0].spuBusId != state.shopBikeSpuId && item.payTime) {
            records.push(item);
          }
        });
        const orderStatuses = await services.order.getServicePlanOrderStatuses();
        const { $jss } = proxy;
        const page = $jss.routeData();
        const servicePlanOrderDetailHref = getBetterUrl(state.servicePlanOrderDetailLink, page.itemLanguage, false);
        for (let order of records) {
          order.status = orderStatuses.find((x) => x.code == order.status);
          order.series = order.items[0].name;
          order.paymentTime = order.payTime;
          order.userUpdateTime = order.dateCreate;
          order.createTime = order.dateCreate;
          order.orderId = order.orderCode;
          order.jumpLink = appendQuery(servicePlanOrderDetailHref, { orderCode: order.orderCode });
        }
        let orders = arraySort(records, 'userUpdateTime');
        state.serviceOrdersTotal = props?.fields?.hideServiceOrder?.value ? 0 : records.length;
        appStore.setServiceOrderInfo(orders);
      },
      async getConfigurations() {
        const list = await services.site.getVehicleConfigList();
        _methods.setMenuCount('configuration-list', list?.length);
      }
    };
    const methods = {
      toggleMenu: () => {
        state.collapsed = !state.collapsed;
      },
      closeMenu: (e) => {
        if (!state.menuEl?.contains(e.target)) {
          state.collapsed = false;
        }
      },
      onSignOut: async () => {
        await appMethods.logout();
      }
    };
    onWidthChanged({
      callback: debounce(() => {
        _methods.buildMenu();
      }, 100)
    });
    onMounted(async () => {
      loading.show();
      const { $jss } = proxy;
      const page = $jss.routeData();
      const [
        dashboardLink,
        orderListLink,
        orderDetailsLink,
        mhpLink,
        shopOrderDetailsLink,
        shopWinterWheelsOrderDetailLink,
        servicePlanOrderDetailLink,
        configurationList,
        shopBikeSpuId
      ] = getGlobalConfigs(
        page,
        'dashboardLink',
        'orderListLink',
        'orderDetailsLink',
        'mhpLink',
        'shopOrderDetailsLink',
        'shopWinterWheelsOrderDetailLink',
        'servicePlanOrderDetailLink',
        'configurationList',
        'shopBikeSpuId'
      );
      state.dashboardLink = dashboardLink?.value?.href;
      state.orderListLink = orderListLink?.value?.href;
      state.orderDetailsLink = orderDetailsLink?.value?.href;
      state.shopOrderDetailsLink = shopOrderDetailsLink?.value?.href;
      state.shopWinterWheelsOrderDetailLink = shopWinterWheelsOrderDetailLink?.value?.href;
      state.servicePlanOrderDetailLink = servicePlanOrderDetailLink?.value?.href;
      state.configurationList = configurationList?.value?.href;
      state.mhpLink = mhpLink?.value;
      state.shopBikeSpuId = shopBikeSpuId?.value;
      appStore.setHideLifeOrder(props?.fields?.hideLifeOrder?.value);
      appStore.setHideVehicleOrder(props?.fields?.hideVehicleOrder?.value);
      appStore.setHideWheelsOrder(props?.fields?.hideWheelsOrder?.value);
      appStore.setHideServiceOrder(props?.fields?.hideServiceOrder?.value);

      const menus = unWrapSitecoreItem(props.fields?.menus) ?? [];
      for (let item of menus) {
        item.count = null;
      }
      state.menus = menus;
      await Promise.all([_methods.getOrders(), _methods.getShopOrders(), _methods.getPartsOrders(), _methods.getServiceOrders(), _methods.getConfigurations()]);
      _methods.setMenuCount('order-list', state.ordersTotal + state.mallOrdersTotal + state.partsOrdersTotal + state.serviceOrdersTotal);
      _methods.buildMenu();
      state.loaded = true;
      loading.hide();
      document.addEventListener('click', (e) => methods.closeMenu(e), true);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('click', (e) => methods.closeMenu(e), true);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      appStore
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-dashboard-layout {
  position: relative;
  &__header {
    position: sticky;
    top: 0;
    left: 0;
    height: 56px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    background-color: $white;
    transition: all 600ms ease;
    &-logo,
    &-btns,
    &-btns-orders,
    &-btns-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-logo {
      gap: 16px;
      cursor: pointer;
      &-text {
        color: #262626;
      }
    }
    &-btns {
      height: 100%;
      gap: 0;
      &-orders {
        height: 100%;
        gap: 8px;
        cursor: pointer;
        &-text {
          font-size: 12px;
          line-height: 16px;
          color: #2f2f2f;
        }
        &-number {
          width: 24px;
          height: 24px;
          font-size: 12px;
          line-height: 20px;
          color: $black;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $yellow;
        }
      }
      &-menu {
        height: 100%;
        padding: 0 0 0 14px;
        gap: 16px;
        cursor: pointer;
        &-text {
          font-size: 12px;
          line-height: 16px;
          color: #2f2f2f;
        }
        &-icon {
          width: 12px;
          height: 12px;
          transition: transform 0.5s ease;
          > svg {
            width: 100%;
            height: 100%;
          }
          &.collapsed {
            transform: rotate(180deg);
          }
        }
      }
    }
    &.active {
      z-index: 3;
    }
  }
  &__menu {
    position: absolute;
    top: 56px;
    right: 0;
    z-index: 2;
    background-color: $white;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 2px rgba($grey-fade, 0.5);
    width: 100%;
    padding: 0 24px;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0;
      line-height: 24px;
      color: $black !important;
      cursor: pointer;
      &-count {
        font-size: 12px;
        background-color: $yellow;
        color: $black;
        padding: 0 8px;
      }
      &.active {
        font-weight: bold;
      }
      &:hover {
        background-color: rgba(#ccc, 0.5);
      }
      & + & {
        border-top: 1px solid $grey-light;
      }
    }
  }
  &__mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    background-color: rgba($black, 0.4);
    backdrop-filter: blur(10px);
  }
  @include tablet-landscape {
    &__header {
      padding: 0 48px;
      &-btns {
        gap: 64px;
        &-orders {
          gap: 16px;
        }
      }
    }
    &__menu {
      width: auto;
      right: 48px;
      border-radius: 4px;
    }
  }
}
</style>
