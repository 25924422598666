<template>
  <div class="s-checkout-verify" :class="[{ 'on-app': $isOnApp($route) }]" v-if="fields" v-show="stepCode === 'verify'">
    <div class="s-checkout-verify__main" v-show="!captchaHasSent">
      <heading class="s-checkout-verify__title text-primary" :field="fields.title" :type="fields.titleType" rich />
      <jss-rich-text class="s-checkout-verify__subtitle" :field="fields.subtitle" />
      <dynamic-form :form="fields.accountForm" @change="onFormChange" ref="accountFormRef" />
      <div class="s-checkout-verify__actions">
        <site-button v-bind="fields.sendButton" @click="onSendCode" />
      </div>
    </div>
    <div class="s-checkout-verify__captcha" v-show="captchaHasSent">
      <jss-rich-text class="s-checkout-verify__title text-primary" :field="fields.verifyTitle" />
      <div class="mg-b-24" v-html="$formatString(fields.verifySubtitle?.value, { email, seconds })" />
      <captcha-input class="mg-b-24" v-model:value="captcha" :label="fields.captchaInput?.fields?.label?.value" ref="captchaInputRef" />
      <transition :css="false" @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
        <div v-show="captchaHasSent && seconds === 0">
          <a class="s-checkout-verify__resend ib label-primary mg-b-32" :class="[{ disabled: resendDisabled }]" @click="onResend">{{ fields.resendText?.value }}</a>
        </div>
      </transition>
      <div class="s-checkout-verify__actions">
        <site-button v-bind="fields.verifyButton" :disabled="captcha?.length < 6 || verifying || (captchaHasSent && seconds <= 0)" @click="onVerify" />
      </div>
    </div>
  </div>
</template>
<script>
/**
 * @typedef CheckoutVerifyFields
 * @property {Object} accountForm
 * @property {ButtonField} sendButton
 * @property {SimpleField} resendText
 * @property {ButtonField} verifyButton
 * @property {SimpleField} verifyTitle
 * @property {SimpleField} verifySubtitle
 * */
/**
 * @typedef CheckoutVerifyRef
 * @property {() => void} fillReservation
 * */
import { getCurrentInstance, reactive, onMounted, toRefs, inject, watch, computed } from 'vue';
import { GET_CAPTCHA_SCENE, getCaptchaExecutor } from '@/utils/captcha-utils';
import { merge } from 'lodash';
import { CAPTCHA_SCENE, captchaTypes, sitecoreProps } from '@/utils/constants';
import { transitions } from '@/utils/transitions';
import { getSiteError } from '@/services/siteService';
import { gtmFormView, gtmFormStart, gtmFormStep } from '@/utils/gtm-utils';
import useAppStore from '@/store/appStore';
export default {
  props: {
    step: {
      type: Number
    },
    stepCode: {
      type: String
    },
    bound: {
      type: Object
    },
    fields: {
      type: Object
    },
    blindOrder: {
      type: Object
    },
    features: {
      type: Array,
      default: () => []
    },
    currency: {
      type: String,
      default: null
    },
    bindRequired: {
      type: Boolean
    },
    ...sitecoreProps
  },
  setup(props) {
    let getCaptcha, checkCaptcha, codeTimeout;
    /**@type AppStore*/
    const appStore = useAppStore();
    const loading = inject('loading');
    const toast = inject('toast');
    const appMethods = inject('appMethods');
    const checkoutMethods = inject('checkoutMethods');
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const page = $jss.routeData();
    const { formType } = page.fields;
    const _methods = {
      startCodeCountdown(seconds) {
        const fn = (seconds) => {
          state.seconds = seconds;
          if (seconds > 0) {
            codeTimeout = setTimeout(() => {
              fn(--seconds);
            }, 1000);
          }
        };
        fn(seconds);
      }
    };
    const methods = {
      onFormChange: () => {
        if (!state.formChanged) {
          state.formChanged = true;
          gtmFormStart(formType?.value, props.fields.accountForm.id);
        }
      },
      async onSendCode() {
        const [valid, data] = await state.accountFormRef.validate();
        if (valid) {
          const { email } = data;
          loading.show();
          let geeResult = await checkoutMethods.validateGeeTest();
          const body = merge(
            {
              captchaType: captchaTypes.email,
              captchaScene: CAPTCHA_SCENE.registerLogin,
              email
            },
            geeResult
          );
          let res, ex;
          if (props.bindRequired) {
            merge(body, {
              captchaScene: CAPTCHA_SCENE.bindEmail
            });
          }
          [res, ex] = await getCaptcha(null, body);
          if (ex) {
            await toast.showEx(ex);
            loading.hide();
            return;
          }
          state.email = email;
          state.captchaHasSent = true;
          checkoutMethods.gtmCheckout('1', `checkout_verify_email`);
          _methods.startCodeCountdown(60);
          loading.hide();
        }
      },
      onResend() {
        if (!computes.resendDisabled.value) {
          methods.onSendCode();
        }
      },
      async onVerify() {
        if (state.verifying) return;
        state.verifying = true;
        loading.show();
        const body = {
          captchaType: captchaTypes.email,
          captcha: state.captcha,
          email: state.email
        };
        if (props.bindRequired) {
          merge(body, {
            captchaScene: CAPTCHA_SCENE.bindEmail
          });
        }
        const [, ex] = await checkCaptcha(null, body);
        if (ex) {
          const msg = await getSiteError(ex);
          console.log('validate error', msg);
          state.captchaInputRef.setErrorMsg(props.fields.captchaInput?.fields?.errorMsg?.value ?? 'Verification failed');
          loading.hide();
          state.verifying = false;
          return;
        }
        if (codeTimeout) {
          clearTimeout(codeTimeout);
        }
        loading.hide();
        state.verifying = false;
        state.captchaInputRef.setSuccessMsg(props.fields.captchaInput?.fields?.successMsg?.value ?? 'Verification success');
        setTimeout(() => {
          checkoutMethods.goNext({ email: state.email });
        }, 1000);
      },
      fillReservation() {
        state.accountFormRef.updateItem('email', {
          controlProps: {
            value: props.blindOrder?.email
          }
        });
      },
      reset() {
        state.captchaInputRef.setSuccessMsg(null);
        state.captcha = null;
      }
    };

    const state = reactive({
      /**@type DynamicForm*/
      accountFormRef: null,
      /**@type CaptchaInputRef*/
      captchaInputRef: null,
      email: null,
      /**@type String|null*/
      captcha: null,
      captchaHasSent: false,
      verifying: false,
      seconds: 0,
      formChanged: false
    });
    const computes = {
      resendDisabled: computed(() => !state.captchaHasSent || state.seconds > 0)
    };
    watch(
      () => props.step,
      (v) => {
        if (v === 0) {
          state.email = null;
          state.captcha = null;
          state.captchaHasSent = false;
          state.verifying = false;
          state.seconds = 0;
        }
      }
    );
    watch(
      () => state.captcha,
      (_captcha) => {
        if (_captcha?.length === 6) {
          methods.onVerify().catch();
        }
      }
    );
    watch(
      () => [appStore.hasLoggedIn, props.bound],
      (hasLoggedIn, bound) => {
        [getCaptcha, checkCaptcha] = getCaptchaExecutor(GET_CAPTCHA_SCENE.CHECKOUT, hasLoggedIn && !bound.email);
      }
    );
    onMounted(() => {
      [getCaptcha, checkCaptcha] = getCaptchaExecutor(GET_CAPTCHA_SCENE.CHECKOUT, appStore.hasLoggedIn && !props.bound.email);
      gtmFormView(formType?.value, props.fields.accountForm.id);
      gtmFormStep(formType?.value, props.fields.accountForm.id, appStore?.loginInfo, 1, 'email_verify', null);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      transitions
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-checkout-verify {
  &__resend {
    @include h9;
    line-height: 1.5;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
    &.disabled {
      opacity: 0.5;
    }
  }
  &__actions {
    .e-site-button {
      width: 100%;
    }
  }
  @include mobile {
    min-height: calc(100vh - 308px);
    &.on-app {
      min-height: calc(100vh - 224px);
    }
  }
  @include tablet-landscape {
    min-height: calc(100vh - 276px);
  }
}
</style>
