<script setup>
import { computed } from 'vue';
import { deepCopy } from '@/utils/obj-utils';
import { buildConfigField } from '@/utils/sitecore-utils';
const emit = defineEmits(['contact']);
const props = defineProps({
  fields: {
    type: Object
  },
  data: {
    type: Object
  },
  dealer: {
    type: Object
  },
  selectable: {
    type: Boolean,
    default: true
  },
  selectedDealer: {
    type: Object
  },
  theme: {
    type: String
  }
});
const checked = computed(() => props.selectedDealer?.id === props.dealer?.id);
const contactBtnProps = computed(() => {
  const { dealerCardButton } = props.fields;
  if (!dealerCardButton?.fields) return null;
  const newButton = deepCopy(dealerCardButton);
  delete newButton.fields.link.value.href;
  newButton.fields.size = buildConfigField('small');
  return newButton;
});
const onContact = (dealer) => {
  emit('contact', dealer);
};
</script>

<template>
  <div class="s-dealer-card-v3">
    <div class="s-dealer-card-v3__header">
      <div class="fs-18 fw-500">{{ dealer.storeName }}</div>
      <div class="flex ac g8 __c-grey-next" v-if="dealer.metre">
        <icon name="direction" size="tiny" />
        <span class="fs-12">{{ $formatDistance(dealer.metre, 'm', 0) }}</span>
      </div>
    </div>
    <div class="text-desc">{{ dealer.addressDetail }}<br />{{ dealer.cityName }}, {{ dealer.countryName }}</div>
    <site-button v-bind="contactBtnProps" @click="onContact" />
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-dealer-card-v3 {
  $c: &;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 24px;
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }
  .e-site-button {
    width: 100%;
    margin-top: 12px;
  }
  + #{$c} {
    border-top: 1px solid $grey-89;
  }
  @include tablet-landscape {
    .e-site-button {
      width: fit-content;
      align-self: flex-end;
    }
  }
}
</style>
