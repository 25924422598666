<script setup>
import { computed, ref } from 'vue';
import { isNullOrEmpty } from '@/utils/obj-utils';
import utils from '@/utils';

const props = defineProps({
  fields: {
    type: Object
  },
  data: {
    type: Object
  },
  dealer: {
    type: Object
  },
  selectable: {
    type: Boolean,
    default: true
  },
  selectedDealer: {
    type: Object
  },
  theme: {
    type: String
  }
});
const rootEl = ref();
const checked = computed(() => props.selectedDealer?.id === props.dealer?.id);
const text = 'Event copy here lorem ipsum dolor sit amet conse event copy here lorem ipsum dolor sit amet conse event copy here lorem ipsum dolor sit amet conse';
const abstract = computed(() => utils.string.byteCut(text, 140));
const showReadMore = computed(() => utils.string.byteLen(text) > 140);
const collapsed = ref(true);
</script>

<template>
  <div class="s-dealer-card-v4" :class="[{ selectable, checked, [`theme-${theme}`]: !isNullOrEmpty(theme) }]" ref="rootEl" v-if="dealer">
    <div class="s-dealer-card-v4__top">
      <div class="s-dealer-card-v4__name fs-18 fw-500 __lh-1_5">{{ dealer.storeName }}</div>
      <div class="s-dealer-card-v4__distance" v-if="dealer.metre">
        <icon name="paper-plane" />
        <span>{{ $formatDistance(dealer.metre, 'm', 0) }}</span>
      </div>
    </div>
    <div class="s-dealer-card-v4__main">
      <div class="s-dealer-card-v4__info">
        <template v-if="data?.isEvent">
          <div class="s-dealer-card-v4__tag">
            <icon :field="fields.dateIcon" size="xl" />
            <span>Fri 9 - Sat 10 Dec, 2023</span>
          </div>
          <div class="s-dealer-card-v4__tag">
            <icon :field="fields.placeIcon" size="xl" />
            <span>Paid entrance</span>
            <popover-disclaimer icon="warn" position="top-whole" :relative-el="rootEl" disclaimer="Event copy here lorem ipsum dolor sit amet conse.." @click.stop />
          </div>
          <div class="s-dealer-card-v4__event" @click.stop>
            <span v-html="collapsed && showReadMore ? abstract : text" />
            &nbsp;
            <template v-if="showReadMore">
              <jss-text class="hand tdl" :field="collapsed ? fields.readMoreText : fields.collapseText" @click="collapsed = !collapsed" />
            </template>
          </div>
        </template>
        <div class="s-dealer-card-v4__addr">{{ dealer.addressDetail }}<br />{{ dealer.cityName }}, {{ dealer.countryName }}</div>
      </div>
      <icon class="s-dealer-card-v4__arrow" name="forward" size="tiny" />
    </div>
    <div class="s-dealer-card-v4__types" v-if="data?.isEvent && data?.locationTypes?.length > 0">
      <span class="s-dealer-card-v4__type" v-html="type.text" v-for="type in data.locationTypes" :key="type.code" />
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-dealer-card-v4 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 0;
  border-bottom: 1px solid $grey-light;
  transition: all 0.2s cubic-bezier(0, 0, 0.4, 0.2);
  &.selectable {
    user-select: none;
    cursor: pointer;
  }
  .s-popover-disclaimer {
    color: $black;
    &__icon {
      svg {
        height: 16px;
      }
    }
  }
  &__top {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: $grey-next;
  }
  &__tag {
    display: flex;
    gap: 8px;
  }
  &__event {
    color: $black;
  }
  &__distance {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    line-height: 24px;
    white-space: nowrap;
    .e-icon {
      svg {
        height: 12px;
      }
    }
  }
  &.theme {
    &-dark {
      background: $black;
      border-color: $grey-dark;
      color: rgba($white, 0.7);
    }
  }
  &__types {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
  &__type {
    font-size: 12px;
    padding: 8px 12px;
    border: 1px solid $grey-89;
  }
  &:last-child {
    border-bottom: 0;
  }
}
html.rtl {
  .s-dealer-card-v2 {
    &__arrow {
      transform: rotate(180deg);
    }
  }
}
</style>
