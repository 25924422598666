<template>
  <div class="s-checkout-personal-info" v-show="stepCode === 'order-details'">
    <jss-rich-text class="s-checkout-personal-info__title text-primary" :field="fields.title" tag="div" />
    <dynamic-form :form="fields.personalForm" ref="personalFormRef" />
    <div v-show="customerType?.code === 'business'">
      <jss-rich-text class="text-secondary mg-b-32" :field="fields.companyTitle" tag="div" />
      <dynamic-form :form="fields.companyForm" ref="companyFormRef" />
    </div>
    <div v-show="customerType?.code === 'individual' && $equalString(alpha2Code, 'IT')">
      <jss-rich-text class="text-secondary mg-b-32" :field="fields.alternateTitle" tag="div" />
      <dynamic-form :form="fields.alternateForm" ref="alternateFormRef" />
    </div>
    <jss-rich-text class="text-secondary mg-b-8" :field="customerType?.code === 'individual' ? fields.shippingTitle : $tryValueField(fields.shippingCoTitle, fields.shippingTitle)" tag="div" />
    <dynamic-form :form="fields.shippingForm" ref="shippingFormRef" :data="shippingFormCfg" />
    <checkbox class="mg-b-44" v-model:value="diffAddr" :mandatoryText="fields.diffAddrLabel?.value ?? `My billing address is different from my shipping address`" />
    <!--    <checkbox class="mg-b-44" v-model:value="saveAddr" :mandatoryText="fields.saveAddrLabel?.value ?? `Save to my personal info`" />-->
    <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
      <div class="s-checkout-delivery__billing" v-show="diffAddr">
        <jss-rich-text class="text-secondary mg-b-32" :field="fields.billingTitle" tag="div" />
        <dynamic-form :form="fields.billingForm" ref="billingFormRef" />
      </div>
    </transition>
    <dynamic-form :form="fields.consentForm" ref="consentFormRef" @change="onConsentFormChange" />
    <checkout-confirm-modal v-bind="fields.confirmationModal" :email="email" :configuration="configuration" :blind-order="blindOrder" ref="confirmModalRef" />
  </div>
</template>

<script>
/**
 * @typedef CheckoutOrderDetailFields
 * @property {SimpleField} title
 * @property {SimpleField} subtitle
 * @property {Array<CustomerType>} customerTypes
 * @property {SimpleField} personalTitle
 * @property {Form} personalForm
 * @property {SimpleField} addressTitle
 * @property {SimpleField} addressCoTitle
 * @property {Form} shippingForm
 * @property {SimpleField} diffAddrLabel
 * @property {SimpleField} saveAddrLabel
 * @property {Form} billingForm
 * @property {SimpleField} selectionTitle
 * @property {SimpleField} agentTitle
 * @property {SimpleField} agentSubtitle
 * @property {Form} agentForm
 * @property {SimpleField} paymentTitle
 * @property {SimpleField} paymentLabel
 * @property {Array<PaymentOptionField>} paymentOptions
 * @property {Object} confirmationModal
 * */
/**
 * @typedef CustomerTypeField
 * @property {{
 *   code: SimpleField,
 *   icon: ImageField,
 *   name: SimpleField
 * }} fields
 * */
/**
 * @typedef PaymentOptionField
 * @property {{
 *   code: SimpleField,
 *   name: SimpleField
 * }} fields
 * */
/**
 * @typedef CheckoutDeliveryRef
 * @property {() => void} fillReservation
 * @property {() => Promise<{personalData: Object,shippingData: Object,billingData: Object, diffAddr: Boolean, saveAddr: Boolean}>} validate
 * */
import { sitecoreProps } from '@/utils/constants';
import { transitions } from '@/utils/transitions';
import { inject, nextTick, onMounted, reactive, toRefs } from 'vue';
import { difference, merge } from 'lodash';
import { getProvinceOptions } from '@/services/siteService';
import { equalString } from '@/utils/string-utils';
import { getAlpha2CodeByLang } from '@/utils/site-utils';
import { getLangFromUrl } from '@/utils/uri-utils';
export default {
  name: 'checkout-personal-info',
  props: {
    /**@type CheckoutOrderDetailFields*/
    fields: {
      type: Object
    },
    step: {
      type: Number
    },
    stepCode: {
      type: String
    },
    email: {
      type: String
    },
    blindOrder: {
      type: Object
    },
    configuration: {
      type: Object
    },
    features: {
      type: Object
    },
    customerType: {
      type: Object
    },
    financeLevel2: {
      type: Boolean
    },
    /**@type SantanderSimulation*/
    simulation: {
      type: Object
    },
    ...sitecoreProps
  },
  setup(props) {
    const checkoutMethods = inject('checkoutMethods');
    const state = reactive({
      alpha2Code: null,
      diffAddr: false,
      saveAddr: false,
      /**@type DynamicForm*/
      personalFormRef: null,
      /**@type DynamicForm*/
      companyFormRef: null,
      /**@type DynamicForm*/
      shippingFormRef: null,
      shippingFormCfg: {
        province: {
          visible: false,
          controlProps: {
            options: [],
            selectedOption: null
          }
        }
      },
      /**@type DynamicForm*/
      billingFormRef: null,
      /**@type DynamicForm*/
      consentFormRef: null,
      /**@type CheckoutConfirmModalRef*/
      confirmModalRef: null
    });
    const _methods = {
      isReservationShippingDiffFromBilling() {
        if (!props.blindOrder) return false;
        const { detailAddress, billingAddress } = props.blindOrder;
        const keys = ['name', 'street', 'address', 'address2', 'city', 'country'];
        for (let key of keys) {
          if (detailAddress[key] !== billingAddress[key]) return true;
        }
        return false;
      },
      async updateProvinceList(alpha2Code) {
        if (equalString(alpha2Code, 'IT')) {
          const payload = {
            visible: true,
            controlProps: {
              options: await getProvinceOptions(alpha2Code),
              selectedOption: null
            }
          };
          merge(state.shippingFormCfg.province, payload);
        } else {
          merge(state.shippingFormCfg.province, {
            visible: false,
            controlProps: {
              options: [],
              selectedOption: null
            }
          });
        }
      }
    };
    const methods = {
      async validate() {
        let personalValid,
          personalData,
          companyValid = true,
          companyData,
          shippingValid,
          shippingData,
          billingValid = true,
          billingData,
          consentValid,
          consentData;
        [personalValid, personalData] = await state.personalFormRef.validate();
        if (props.customerType?.code === 'business') {
          [companyValid, companyData] = await state.companyFormRef.validate();
        }
        [shippingValid, shippingData] = await state.shippingFormRef.validate();
        if (state.diffAddr) {
          [billingValid, billingData] = await state.billingFormRef.validate();
        } else {
          billingValid = true;
          billingData = shippingData;
        }
        [consentValid, consentData] = await state.consentFormRef.validate();
        const deliveryLeadTypes = state.consentFormRef.getLeadTypes();
        if (!personalValid || !shippingValid || !billingValid || !consentValid) return null;
        const confirmed = await state.confirmModalRef.open({
          email: props.email,
          companyData,
          personalData,
          shippingData,
          billingData
        });
        if (!confirmed) return null;
        const allConsents = [
          ...new Set([
            ...state.personalFormRef.getVerifiedConsents(),
            ...state.companyFormRef.getVerifiedConsents(),
            ...state.shippingFormRef.getVerifiedConsents(),
            ...state.consentFormRef.getVerifiedConsents()
          ])
        ];
        const newsletterConsents = consentData?.termsCondition ? state.consentFormRef.getItemConsents('termsCondition') : [];
        const bindConsents = difference(allConsents, newsletterConsents);
        return {
          personalData,
          companyData,
          shippingData,
          billingData,
          diffAddr: state.diffAddr,
          saveAddr: state.saveAddr,
          consentData,
          newsletterConsents,
          bindConsents,
          deliveryLeadTypes
        };
      },
      fillReservation() {
        const [firstName, ...lastName] = props.blindOrder.detailAddress?.name?.split(' ');
        const [address, ...address2] = props.blindOrder.detailAddress?.street.split(',');
        state.shippingFormRef.updateItems({
          mobile: {
            controlProps: {
              area: {
                code: props.blindOrder?.userInfo?.areaCode
              },
              mobile: {
                number: props.blindOrder?.userInfo?.phoneNumber
              }
            }
          },
          firstName: {
            controlProps: {
              value: props.blindOrder.detailAddress?.firstName ?? firstName
            }
          },
          middleName: {
            controlProps: {
              value: props.blindOrder.detailAddress?.middleName ?? ''
            }
          },
          lastName: {
            controlProps: {
              value: props.blindOrder.detailAddress?.lastName ?? lastName.join(' ')
            }
          },
          address: {
            controlProps: {
              value: address
            }
          },
          address2: {
            controlProps: {
              value: address2.join(',')
            }
          },
          zipCode: {
            controlProps: {
              value: props.blindOrder.detailAddress?.zipCode
            }
          },
          city: {
            controlProps: {
              value: props.blindOrder.detailAddress?.city
            }
          }
        });
        if (_methods.isReservationShippingDiffFromBilling()) {
          state.diffAddr = true;
          const [blFirstName, ...blLastName] = props.blindOrder.billingAddress?.name?.split(' ');
          const [blAddress, ...blAddress2] = props.blindOrder.billingAddress?.street.split(',');
          state.billingFormRef.updateItems({
            firstName: {
              controlProps: {
                value: props.blindOrder.billingAddress?.firstName ?? blFirstName
              }
            },
            middleName: {
              controlProps: {
                value: props.blindOrder.billingAddress?.middleName ?? ''
              }
            },
            lastName: {
              controlProps: {
                value: props.blindOrder.billingAddress?.lastName ?? blLastName.join(' ')
              }
            },
            address: {
              controlProps: {
                value: blAddress
              }
            },
            address2: {
              controlProps: {
                value: blAddress2.join(',')
              }
            },
            zipCode: {
              controlProps: {
                value: props.blindOrder.billingAddress?.zipCode
              }
            },
            city: {
              controlProps: {
                value: props.blindOrder.billingAddress?.city
              }
            }
          });
        }
      },
      async onShippingFormChange(key, value) {
        switch (key) {
          case 'country': {
            await _methods.updateProvinceList(value.code);
            break;
          }
          default:
            break;
        }
      },
      checkMandatoryCheckbox() {
        return state.consentFormRef?.hasAllMandatoryCheckboxChecked();
      },
      onConsentFormChange(key, value) {
        const mandatoryChecked = state.consentFormRef?.hasAllMandatoryCheckboxChecked();
        checkoutMethods.changeNextDisabled(!mandatoryChecked);
      }
    };
    onMounted(async () => {
      await nextTick();
      const lang = getLangFromUrl();
      const alpha2Code = getAlpha2CodeByLang(lang);
      state.alpha2Code = alpha2Code;
      _methods.updateProvinceList(alpha2Code).catch();
      state.shippingFormCfg.province.visible = equalString(alpha2Code, 'IT');
      state.shippingFormRef.updateItem('province', {
        visible: equalString(alpha2Code, 'IT')
      });
    });
    return {
      ...toRefs(state),
      ...methods,
      transitions
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-checkout-personal-info {
  &__save-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 56px;
    .e-site-switch {
      margin-left: 8px;
    }
  }
  &__save-info-text {
    font-size: 12px;
  }
}
</style>
