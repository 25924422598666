<template>
  <label-value
    class="s-checkout-price-summary"
    :label="label ?? pricing.label"
    :value="$formatMoney(pricing.price, $ifEmpty(currency, pricing.currency))"
    :size="size"
    :theme="theme"
    :bordered="bordered"
    v-if="pricing"
  />
</template>

<script>
import { computed } from 'vue';
import { equalString } from '@/utils/string-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'checkout-price-summary',
  props: {
    priceList: {
      type: Array
    },
    label: {
      type: String
    },
    formula: {
      type: String
    },
    elementNames: {
      type: Array
    },
    additionalPrice: {
      type: Number
    },
    currency: {
      type: String
    },
    theme: {
      type: String
    },
    size: {
      type: String
    },
    bordered: {
      type: Boolean
    }
  },
  setup(props) {
    const computes = {
      pricing: computed(() => {
        let total = 0,
          currency = null,
          currentAction = '+',
          currentName = '';
        const formulaItems = [];
        for (let i = 0, len = props.formula.length; i < len; i++) {
          const char = props.formula[i];
          if (char === '-') {
            formulaItems.push({
              action: currentAction,
              name: currentName.trim()
            });
            currentAction = '-';
            currentName = '';
          } else if (char === '+') {
            formulaItems.push({
              action: currentAction,
              name: currentName.trim()
            });
            currentAction = '+';
            currentName = '';
          } else if (i === len - 1) {
            currentName += char;
            formulaItems.push({
              action: currentAction,
              name: currentName.trim()
            });
          } else {
            currentName += char;
          }
        }
        // console.log('formulaItems', props.formula, formulaItems);
        if (props.priceList.length) {
          for (let item of formulaItems) {
            for (let priceItem of props.priceList) {
              if (equalString(priceItem.elementName, item.name)) {
                const direction = item.action === '-' ? -1 : 1;
                total += direction * (Number(priceItem.price) ?? 0);
                if (!isNullOrEmpty(priceItem.currency)) {
                  currency = priceItem.currency;
                }
                break;
              }
            }
          }
        }
        if (!isNullOrEmpty(props.additionalPrice)) {
          total += props.additionalPrice;
        }
        return {
          label: props.label,
          price: total,
          currency
        };
      })
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss"></style>
