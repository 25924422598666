<template>
  <modal ref="confirmModalRef" class="s-checkout-confirm-modal" :class="{ convert: options?.autoConvert || options?.blindOrder }" theme="black" closable @closed="onClosed">
    <jss-rich-text class="s-checkout-confirm-modal__title" :field="options?.autoConvert || options?.blindOrder ? fields.convertTitle : fields.title" />
    <jss-rich-text class="s-checkout-confirm-modal__subtitle" :field="fields.subtitle" />
    <div class="s-checkout-confirm-modal__content">
      <div class="s-checkout-confirm-modal__section">
        <div class="s-checkout-confirm-modal__label">{{ $t('Model detail') }}</div>
        <div class="s-checkout-confirm-modal__text">
          {{ $formatModel(configuration.model) }}
        </div>
      </div>
      <div class="s-checkout-confirm-modal__section">
        <div class="s-checkout-confirm-modal__label">{{ $t('Name') }}</div>
        <div class="s-checkout-confirm-modal__text">{{ $concatString([options?.personalData?.firstName, options?.personalData?.middleName, options?.personalData?.lastName], ' ') }}</div>
      </div>
      <div class="s-checkout-confirm-modal__section">
        <div class="s-checkout-confirm-modal__label">{{ $t('Date of birth') }}</div>
        <div class="s-checkout-confirm-modal__text">{{ options?.personalData?.dateOfBirth }}</div>
      </div>
      <div class="s-checkout-confirm-modal__section" v-if="blindOrder?.mobile">
        <div class="s-checkout-confirm-modal__label">{{ $t('Phone number') }}</div>
        <div class="s-checkout-confirm-modal__text">+{{ blindOrder?.areaCode }}{{ blindOrder?.buyerPhone }}</div>
      </div>
      <div class="s-checkout-confirm-modal__section">
        <div class="s-checkout-confirm-modal__label">{{ $t('Email address') }}</div>
        <div class="s-checkout-confirm-modal__text">{{ options?.email }}</div>
      </div>
      <div class="s-checkout-confirm-modal__section">
        <div class="s-checkout-confirm-modal__label">{{ $t('Invoice address') }}</div>
        <div class="s-checkout-confirm-modal__text">
          {{ $buildAddress(options?.billingData?.address, options?.billingData?.address2, options?.billingData?.zipCode, options?.billingData?.city, options?.billingData?.country.text) }}
        </div>
      </div>
      <div class="s-checkout-confirm-modal__section">
        <div class="s-checkout-confirm-modal__label">{{ $t('Delivery address') }}</div>
        <div class="s-checkout-confirm-modal__text">
          {{ $buildAddress(options?.shippingData?.address, options?.shippingData?.address2, options?.shippingData?.zipCode, options?.shippingData?.city, options?.shippingData?.country.text) }}
        </div>
      </div>
      <template v-if="options?.companyData">
        <div class="s-checkout-confirm-modal__section">
          <div class="s-checkout-confirm-modal__label">{{ $t('Business email') }}</div>
          <div class="s-checkout-confirm-modal__text">{{ email }}</div>
        </div>
        <div class="s-checkout-confirm-modal__section">
          <div class="s-checkout-confirm-modal__label">{{ $t('VAT number') }}</div>
          <div class="s-checkout-confirm-modal__text">{{ options?.companyData?.vatNumber }}</div>
        </div>
        <div class="s-checkout-confirm-modal__section">
          <div class="s-checkout-confirm-modal__label">{{ $t('Company name') }}</div>
          <div class="s-checkout-confirm-modal__text">{{ options?.companyData?.companyName }}</div>
        </div>
        <div class="s-checkout-confirm-modal__section">
          <div class="s-checkout-confirm-modal__label">{{ $t('Company registration number') }}</div>
          <div class="s-checkout-confirm-modal__text">{{ options?.companyData?.companyRegistrationNumber }}</div>
        </div>
      </template>
    </div>
    <div class="s-checkout-confirm-modal__footer">
      <site-button v-bind="autoConvert || blindOrder ? fields.convertButton : fields.button" @click="onConfirmed" />
    </div>
  </modal>
</template>

<script>
/**
 * @typedef CheckoutConfirmModalRef
 * @property { () => Promise<Boolean>} open
 * */
/**
 * @typedef CheckoutConfirmModalFields
 * @property {SimpleField} convertTitle
 * @property { ButtonField } convertButton
 * */
import { reactive, toRefs } from 'vue';
import { sitecoreProps } from '@/utils/constants';

export default {
  name: 'checkout-confirm-modal',
  emits: ['confirmed'],
  props: {
    fields: {
      type: Object
    },
    email: {
      type: String
    },
    configuration: {
      type: Object
    },
    blindOrder: {
      type: Object
    },
    autoConvert: {
      type: Boolean
    },
    ...sitecoreProps
  },
  setup(props, ctx) {
    const methods = {
      open(options) {
        return new Promise((resolve) => {
          state.options = options;
          let result = false;
          state.onConfirmed = async () => {
            result = true;
            await state.confirmModalRef.close();
          };
          state.onClosed = async () => {
            resolve(result);
          };
          state.confirmModalRef.open();
        });
      }
    };
    const state = reactive({
      /**@type ModalRef*/
      confirmModalRef: null,
      options: {
        email: null,
        mobile: null,
        autoConvert: false,
        blindOrder: null,
        shippingData: null,
        billingData: null,
        companyData: null
      },
      onConfirmed: () => {},
      onClosed: () => {}
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-checkout-confirm-modal {
  &.e-modal {
    align-items: flex-end;
  }

  .e-modal__content {
    width: 100%;
    overflow-y: hidden;
    background: $black;
    color: $white;
    padding: 16px 24px;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 16px;
    margin-top: 24px;
  }

  &__label {
    font-weight: bold;
    text-transform: uppercase;
  }

  &__text {
    margin-top: 8px;
    font-weight: 300;
    font-family: lotusFontFamily('Overpass Light'), sans-serif;
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: 36px;

    .e-site-button {
      width: auto;
    }
  }
  @include tablet-landscape {
    &.e-modal {
      align-items: center;
    }
    .e-modal__content {
      width: grid-width(16);
      padding: 40px 60px;
    }
    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 40px;
      grid-row-gap: 24px;
    }
  }
}
html[lang='ja-JP'] {
  .s-checkout-confirm-modal {
    &__text {
      font-family: lotusFontFamily('Noto Sans JP Light'), sans-serif;
    }
  }
}
html[lang='ko-KR'] {
  .s-checkout-confirm-modal {
    &__text {
      font-family: lotusFontFamily('Noto Sans KR Light'), sans-serif;
    }
  }
}
</style>
