<template>
  <div class="s-disclaimer" v-if="field && field.value">
    <template v-if="disclaimer">
      <template v-if="showHover">
        <JssRichText class="s-disclaimer__dis" :class="disClasses" :field="disclaimer?.fields?.text" v-show="showDis" />
        <JssRichText class="s-disclaimer__main has-dis" :field="field" @mouseenter="(e) => textHover(e)" @mouseleave="(e) => textOut(e)" @click="(e) => textClick(e)" />
      </template>
      <template v-else>
        <div class="s-disclaimer__main has-dis" v-html="$formatString(field.value, { disclaimer: disclaimer?.fields?.icon?.value?.svgCode })" @click="(e) => iconClick(e, disclaimer)"></div>
      </template>
    </template>
    <template v-else>
      <JssRichText class="s-disclaimer__main" :field="field" />
    </template>
  </div>
</template>
<script>
import { reactive, toRefs, inject, computed } from 'vue';

export default {
  name: 'Disclaimer',
  props: {
    field: {
      type: Object
    },
    disclaimer: {
      type: Object
    },
    showHover: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'left'
    },
    positionBottom: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const toast = inject('toast');
    const { deviceComputes } = inject('device-common');
    const state = reactive({
      showDis: false
    });
    const computes = {
      disClasses: computed(() => [`align-${deviceComputes.isMobileOrTablet.value ? 'left' : props.align}`, { 'position-bottom': props.positionBottom }])
    };
    const methods = {
      iconClick: (e, i) => {
        e.stopPropagation();
        toast.show({
          icon: 'lotus',
          message: i.fields.text.value,
          closable: true
        });
      },
      textHover(e) {
        if (!deviceComputes.isMobileOrTablet.value) {
          e.stopPropagation();
          state.showDis = true;
        }
      },
      textOut(e) {
        if (!deviceComputes.isMobileOrTablet.value) {
          e.stopPropagation();
          state.showDis = false;
        }
      },
      textClick(e) {
        if (deviceComputes.isMobileOrTablet.value) {
          e.stopPropagation();
          state.showDis = !state.showDis;
        }
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
.s-disclaimer {
  position: relative;
  &__main {
    &.has-dis {
      cursor: pointer;
    }
  }
  &__dis {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    margin-top: -16px;
    background: $grey-light;
    color: #000;
    font-size: initial;
    padding: 40px;
    z-index: 2;
    &.align {
      &-center {
        left: 50%;
        transform: translate3d(-50%, -100%, 1px);
      }
      &-right {
        left: unset;
        right: 0;
      }
    }
    &.position-bottom {
      top: 100%;
      margin-top: 16px;
      transform: translateY(0);
    }
  }
  @include desktop {
    &__dis {
      width: 600px;
    }
  }
}
</style>
