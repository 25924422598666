<template>
  <label-value
    class="s-checkout-price-item"
    :label="label ? $formatModel(label) : $formatModel(pricing.label)"
    :value="$formatMoney(pricing.price, pricing.currency)"
    :size="size"
    :theme="theme"
    :bordered="bordered"
    v-if="pricing"
  />
</template>

<script>
import { computed } from 'vue';
import { equalString } from '@/utils/string-utils';

export default {
  name: 'checkout-price-item',
  props: {
    priceList: {
      type: Array
    },
    label: {
      type: String
    },
    elementName: {
      type: String
    },
    theme: {
      type: String
    },
    size: {
      type: String
    },
    bordered: {
      type: Boolean
    }
  },
  setup(props) {
    const computes = {
      pricing: computed(() => props.priceList?.find((x) => equalString(x.elementName, props.elementName)))
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss"></style>
