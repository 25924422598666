<template>
  <div class="s-checkout-price-nav">
    <block-summary>
      <div class="s-checkout-price-nav__tips" v-if="!$isOnApp($route)">{{ $t('The final price is subject to your selection') }}</div>
      <label-value :label="$tu('Total on the road price')" size="small" v-if="showPrice">{{ $formatMoney(totalAmount, currency) }}</label-value>
      <label-value size="small" v-if="(stepCode !== 'verify' && isFinance && (simulation || financeQuote)) || showPriceBtn">
        <template #label>
          <div class="s-checkout-price-nav__calculator" v-show="stepCode !== 'verify' && (isFinance || financeQuote)" @click="openFinanceCalculator">
            <icon name="calculator" size="tiny" />
            <span class="s-checkout-price-nav__calculator-text">{{ $t('Finance calculator') }}</span>
          </div>
        </template>
        <template #desc>
          <span
            v-if="isFinance && simulation?.selectedSimulation"
            v-html="$formatString($ifEmpty(fields.aprText?.value, '##effectiveInterest##% APR'), { effectiveInterest: simulation?.selectedSimulation.effectiveInterest.value })"
          />
          <span
            v-else-if="financeQuote?.Finance?.Quote"
            v-html="$formatString($ifEmpty(fields.aprText?.value, '##effectiveInterest##% APR'), { effectiveInterest: financeQuote.Finance.Quote?.Apr })"
          />
        </template>
        <span v-if="showPriceBtn" class="s-checkout-summary__price-down" @click="onOpenPriceBreakdown"> {{ $t('Price breakdown') }} </span>
        <span
          v-else-if="isFinance && simulation?.selectedSimulation"
          v-html="$formatString($ifEmpty(fields.perMonthText?.value, '##monthlyPayment## per month'), { monthlyPayment: $formatMoney(simulation?.selectedSimulation.monthlyPayment.value, currency) })"
        />
        <span
          v-else-if="financeQuote?.Finance?.Quote"
          v-html="$formatString($ifEmpty(fields.perMonthText?.value, '##monthlyPayment## per month'), { monthlyPayment: $formatMoney(financeQuote?.Finance.Quote.TotalRegularPayment, currency) })"
        />
      </label-value>
      <label-value class="s-checkout-price-nav__deposit" :label="$t('Deposit due today')" :value="$formatMoney(downPayment, currency)" v-if="showPrice" />
    </block-summary>
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import BUS_EVENTS from '@/utils/bus-events';

export default {
  name: 'checkout-price-nav',
  emits: ['onOpenPriceBreakdown'],
  props: {
    fields: {
      type: Object
    },
    stepCode: {
      type: String
    },
    priceInfo: {
      type: Array
    },
    paymentOption: {
      type: Object
    },
    alpha2Code: {
      type: String
    },
    financeLevel2: {
      type: Boolean
    },
    showPriceBtn: {
      type: Boolean,
      default: true
    },
    simulation: {
      type: Object
    },
    /**@type CwFinanceResult*/
    financeQuote: {
      type: Object
    },
    downPayment: {
      type: Number
    },
    totalAmount: {
      type: Number
    },
    currency: {
      type: String,
      default: 'EUR'
    },
    showPrice: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const $bus = inject('$bus');
    const methods = {
      onOpenPriceBreakdown() {
        ctx.emit('onOpenPriceBreakdown');
      },
      openFinanceCalculator() {
        $bus.emit(BUS_EVENTS.OPEN_FINANCE_CALCULATOR);
      }
    };
    const computes = {
      isFinance: computed(() => props.paymentOption?.name !== 'cash' && props.financeLevel2)
    };
    return {
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-checkout-price-nav {
  $c: &;
  width: 100%;
  background: $black;
  color: $white;
  padding: 16px 24px;
  &__tips {
    padding: 8px 16px;
    font-size: 12px;
  }
  &__calculator {
    font-size: 12px;
    color: $yellow;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      #{$c}__calculator-text {
        text-decoration: underline;
      }
    }
    .e-icon {
      margin-right: 4px;
    }
  }
  &__deposit {
    padding: 8px 16px 12px 16px !important;
  }
}
</style>
