<template>
  <div v-bind="$attrs" class="s-dynamic-form" :class="[{ hidden: !visible }]">
    <template v-if="formItems">
      <div class="s-dynamic-form__item" v-for="item in formItems" :key="item.id">
        <template v-if="item.visible !== false">
          <div class="s-dynamic-form__item-control">
            <template v-if="item.type === 'description'">
              <div class="s-dynamic-form__item-desc" v-html="item.html" v-if="item.visible !== false" />
            </template>
            <template v-else>
              <component
                :is="item.type"
                v-bind="item.controlProps"
                @change="(e, ...args) => onItemChange(item, e, ...args)"
                @search="(e, ...args) => onItemSearch(item, e, ...args)"
                @focus="(e) => onItemFocus(item, e)"
                @blur="(e, value) => onItemBlur(item, e, value)"
                :ref="(e) => (item.ref = e)"
                v-if="!item.invisible"
              >
                <template #option="scope" v-if="$slots[`${item.controlProps.dataName}Option`]">
                  <slot :name="`${item.controlProps.dataName}Option`" v-bind="scope" />
                </template>
                <template #content="scope" v-if="$slots[`${item.controlProps.dataName}Content`]">
                  <slot :name="`${item.controlProps.dataName}Content`" v-bind="scope" />
                </template>
                <div v-for="btn in (item.buttons ?? []).filter((x) => x.visible !== false)" :key="btn.id">
                  <site-button :fields="btn.fields" :disabled="btn.disabled" @click="(e) => onBtnClick(e, item, btn)" />
                </div>
              </component>
            </template>
          </div>
        </template>
      </div>
    </template>
    <div class="s-dynamic-form__item" v-if="form?.fields?.gRecaptcha?.value">
      <google-recaptcha ref="googleRecaptchaRef" :action="form?.fields?.gRecaptchaAction?.value ?? 'submit'" />
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, inject, nextTick, onMounted, reactive, toRefs, watch } from 'vue';
import { merge, debounce } from 'lodash';
import { isArray, isNullOrEmpty, isNullOrWhitespace } from '@/utils/obj-utils';
import { getCurrentAlpha2Code, loadGMap, settingValue } from '@/utils/site-utils';
import { validateFunctions } from '@/utils/validate-functions';
import { getCountryOptions, getShippingCountryOptions, getWebshopShippingCountryOptions, getRegistrableCountryOptions } from '@/services/siteService';
import { equalString } from '@/utils/string-utils';
import { useI18n } from 'vue-i18n';
import { scrollToFirstFormError } from '@/utils/dom-utils';
import * as querystring from 'querystring';
import { ERROR_CODES } from '@/utils/constants';
import { unWrapSitecoreItem } from '@/utils/sitecore-utils';
export default {
  name: 'DynamicForm',
  emits: ['init-form', 'btn-click', 'update:data', 'change'],
  inheritAttrs: false,
  props: {
    /**@type {Form}*/
    form: {
      type: Object
    },
    dealerModal: {
      type: Object
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    /**@type CarPriceModel*/
    carModel: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    scrollToForm: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const toast = inject('toast');
    const { t } = useI18n();
    let formItems = [];
    if (props.form?.fields?.items?.length > 0) {
      const dataCopy = merge({}, props.data);
      for (let x of props.form?.fields?.items) {
        const { id, fields } = x;
        const { fieldType, dataName, title, label, placeholder, checked, required, invisible, mandatoryTitle, mandatoryText, description, icon, reverse } = fields;
        let { buttons } = fields;
        const type = fieldType?.fields.phrase.value ?? 'form-input';
        const fieldProps = { id, type, invisible: invisible?.value, controlProps: { dataName: dataName?.value ?? null }, errorMsg: null, fields: fields };
        if (!dataCopy[dataName?.value]) {
          dataCopy[dataName?.value] = {};
        }
        const dataCfg = dataCopy[dataName?.value];
        dataCfg.disabled = dataCfg.disabled ?? props.disabled ?? false;
        if (buttons && dataCfg.buttons) {
          buttons = buttons.map((btn) => {
            const { dataName: btnDataName } = btn.fields;
            const btnCfg = dataCfg.buttons[btnDataName.value];
            return merge(btn, btnCfg);
          });
        }
        if (required?.value) {
          merge(fieldProps, {
            controlProps: {
              required: true
            }
          });
        }
        switch (type) {
          case 'form-input':
          default:
            {
              const { maxLength, description, defaultValue } = fields;
              const inputType = settingValue(fields.inputType, 'text');
              merge(
                fieldProps,
                {
                  controlProps: {
                    title: title?.value,
                    label: label?.value,
                    placeholder: placeholder?.value,
                    inputType,
                    value: defaultValue?.value ?? null,
                    icon: icon?.value.svgCode,
                    reverse: reverse?.value,
                    description: description?.value
                  },
                  buttons
                },
                dataCfg
              );
              if (!isNullOrEmpty(maxLength?.value) && !isNaN(maxLength?.value)) {
                merge(fieldProps, {
                  controlProps: {
                    maxLength: Number(maxLength.value)
                  }
                });
              }
            }
            break;
          case 'form-textarea':
            {
              const { rows, maxLength, showLimitation, defaultValue } = fields;
              merge(
                fieldProps,
                {
                  controlProps: {
                    label: label?.value,
                    placeholder: placeholder?.value,
                    rows: rows?.value ?? 3,
                    showLimitation: showLimitation?.value,
                    value: defaultValue?.value ?? null
                  },
                  buttons
                },
                dataCfg
              );
              if (!isNullOrEmpty(maxLength?.value) && !isNaN(maxLength?.value)) {
                merge(fieldProps, {
                  controlProps: {
                    maxLength: Number(maxLength.value)
                  }
                });
              }
            }
            break;
          case 'phone-input':
            {
              const { maxLength, defaultArea, defaultPhoneNumber } = fields;
              merge(
                fieldProps,
                {
                  controlProps: {
                    label: label?.value,
                    placeholder: placeholder?.value,
                    value: null
                  },
                  buttons
                },
                dataCfg
              );
              if (!isNullOrEmpty(defaultArea)) {
                merge(fieldProps, {
                  controlProps: {
                    value: {
                      area: {
                        data: {
                          alpha2Code: defaultArea?.fields.alpha2Code.value
                        }
                      }
                    }
                  }
                });
              }
              if (!isNullOrEmpty(defaultPhoneNumber?.value)) {
                merge(fieldProps, {
                  controlProps: {
                    value: {
                      number: defaultPhoneNumber?.value
                    }
                  }
                });
              }
              if (!isNullOrEmpty(maxLength?.value) && !isNaN(maxLength?.value)) {
                merge(fieldProps, {
                  controlProps: {
                    maxLength: Number(maxLength.value)
                  }
                });
              }
            }
            break;
          case 'date-input':
            {
              const { format } = fields;
              merge(
                fieldProps,
                {
                  controlProps: {
                    label: label?.value,
                    format: settingValue(format, 'yyyy-MM-dd'),
                    placeholder: placeholder?.value ?? 'yyyy-MM-dd',
                    value: null
                  },
                  buttons
                },
                dataCfg
              );
            }
            break;
          case 'dropdown':
            {
              const { options: dataOptions = [], selectedOption: dataSelectedOption, clearable, clearText, hideSelected } = fields;
              const options =
                dataOptions?.map((x) => {
                  const _option = {
                    code: x.fields.code?.value,
                    text: x.fields.text?.value,
                    key: x.fields.key?.value
                  };
                  if (!isNullOrEmpty(x.fields.extra?.value)) {
                    _option.extra = querystring.parse(x.fields.extra.value);
                  }
                  return _option;
                }) ?? [];
              const selectedOption = dataSelectedOption ? options.find((x) => x.code === dataSelectedOption.fields.code?.value) : null;
              merge(dataCfg, {
                controlProps: {
                  options,
                  selectedOption,
                  title: title?.value,
                  label: label?.value,
                  placeholder: placeholder?.value,
                  description: description?.value,
                  clearable: clearable?.value,
                  clearText: clearText?.value,
                  hideSelected: hideSelected?.value
                },
                buttons
              });
              merge(fieldProps, dataCfg);
            }
            break;
          case 'checkbox':
            {
              const { switchLike, bordered, rtl } = fields;
              merge(dataCfg, {
                controlProps: {
                  title: title?.value,
                  mandatoryTitle: mandatoryTitle?.value,
                  mandatoryText: mandatoryText?.value,
                  value: checked?.value,
                  switchLike: switchLike?.value,
                  bordered: bordered?.value,
                  rtl: rtl?.value
                }
              });
              merge(fieldProps, dataCfg);
            }
            break;
          case 'check-list':
            {
              const { checkItems, columnCountMobile, columnCountTablet, columnCountDesktop, labelClickable } = fields;
              const options = checkItems.map((x) => ({
                code: x.fields.code.value,
                text: x.fields.text.value,
                checked: x.fields.checked.value
              }));
              merge(dataCfg, {
                controlProps: {
                  label: label?.value,
                  options,
                  columnCountMobile: parseInt(settingValue(columnCountMobile)),
                  columnCountTablet: parseInt(settingValue(columnCountTablet)),
                  columnCountDesktop: parseInt(settingValue(columnCountDesktop))
                }
              });
              merge(fieldProps, dataCfg);
            }
            break;
          case 'radio-list':
            {
              const { overline, checkItems, checkedItem, columnCountMobile, columnCountTablet, columnCountDesktop, labelClickable } = fields;
              const options = checkItems.map((x) => ({
                code: x.fields.code.value,
                text: x.fields.text.value
              }));
              const selectedOption = checkedItem ? options.find((x) => x.code === checkedItem.fields.code.value) : null;
              merge(dataCfg, {
                controlProps: {
                  options,
                  selectedOption
                }
              });
              merge(
                fieldProps,
                {
                  controlProps: {
                    overline: overline?.value,
                    label: label?.value,
                    columnCountMobile: parseInt(settingValue(columnCountMobile)),
                    columnCountTablet: parseInt(settingValue(columnCountTablet)),
                    columnCountDesktop: parseInt(settingValue(columnCountDesktop)),
                    labelClickable: !!labelClickable.value
                  }
                },
                dataCfg
              );
            }
            break;
          case 'description':
            merge(fieldProps, {
              html: description.value,
              ...dataCfg
            });
            break;
          case 'autocomplete-input':
            {
              const { autocompleteType, global, maxLength } = fields;
              merge(
                fieldProps,
                {
                  autoType: settingValue(autocompleteType, 'zipCode'),
                  controlProps: {
                    title: title?.value,
                    label: label?.value,
                    placeholder: placeholder?.value,
                    icon: icon?.value.svgCode,
                    reverse: reverse?.value,
                    maxLength: maxLength?.value
                  },
                  global,
                  buttons
                },
                dataCfg
              );
            }
            break;
          case 'dealer-picker':
            {
              const { title, placeholder, icon, selectText, changeText } = fields;
              merge(fieldProps, {
                controlProps: {
                  title: title?.value,
                  placeholder: placeholder?.value,
                  icon: icon?.value?.svgCode,
                  selectText: selectText?.value,
                  changeText: changeText?.value,
                  dealerModal: props.dealerModal
                }
              });
            }
            break;
          case 'form-pick-up':
            {
              const { pickType, unmatchedMsg, defaultValue } = fields;
              const options = unWrapSitecoreItem(pickType?.fields.options) ?? [];
              const selectedOption = options.find((x) => equalString(x.code, defaultValue.value));
              merge(fieldProps, {
                controlProps: {
                  title: title?.value,
                  label: label?.value,
                  placeholder: placeholder?.value,
                  options: options,
                  value: selectedOption?.code ?? null,
                  unmatchedMsg: unmatchedMsg?.value
                }
              });
            }
            break;
        }
        formItems.push(fieldProps);
      }
      ctx.emit('init-form', dataCopy);
    }
    const state = reactive({
      /**@type Array<FormItem>*/
      formItems,
      /**@type GoogleRecaptchaRef*/
      googleRecaptchaRef: null,
      alpha2Code: null
      // rules: xml2Js(props.form?.fields?.rules?.value ?? '[]')
    });
    watch(
      () => props.data,
      (data) => {
        if (!state.formItems?.length) return;
        const keys = Object.keys(data);
        for (let key of keys) {
          const dataCfg = data[key];
          const { buttons: dataButtons, ...rest } = dataCfg;
          const item = state.formItems.find((x) => x.fields.dataName.value === key);
          if (item) {
            merge(item, rest);
            let { buttons } = item;
            if (buttons && dataButtons) {
              item.buttons = buttons.map((btn) => {
                const { dataName: btnDataName } = btn.fields;
                const btnCfg = dataButtons[btnDataName.value];
                return merge(btn, btnCfg);
              });
            }
          }
        }
      },
      {
        deep: true
      }
    );
    watch(
      () => props.disabled,
      (val) => {
        if (!state.formItems.length) return;
        for (let item of state.formItems) {
          if (item.controlProps) {
            item.controlProps.disabled = val;
          }
        }
      }
    );
    const methods = {
      onItemChange(item, e, ...args) {
        const { dataName } = item.fields;
        let propsData = { ...(props.data ?? {}) };
        const setCfgValue = (key, val) => {
          merge(propsData, {
            [dataName.value]: {
              controlProps: {
                [key]: val
              }
            }
          });
          ctx.emit('change', dataName.value, val, item);
        };
        item.errorMsg = null;
        switch (item.type) {
          case 'dropdown':
            {
              const [option] = args;
              item.controlProps.selectedOption = option;
              setCfgValue('selectedOption', option);
            }
            break;
          case 'autocomplete-input':
            {
              const [option] = args;
              item.controlProps.value = option.code;
              item.controlProps.selectedOption = option;
              setCfgValue('value', option.code);
            }
            break;
          case 'check-list':
            {
              const [index, checked] = args;
              item.controlProps.options[index].checked = checked;
              setCfgValue('options', item.controlProps.options);
            }
            break;
          case 'checkbox':
            {
              const [value] = args;
              item.controlProps.value = value;
              item.controlProps.errorMsg = null;
              setCfgValue('value', value);
            }
            break;
          case 'radio-list':
            {
              const [, option] = args;
              item.controlProps.selectedOption = option;
              item.controlProps.errorMsg = null;
              setCfgValue('selectedOption', option);
            }
            break;
          case 'date-input':
            {
              const [value, timestamp] = args;
              item.controlProps.value = value;
              item.controlProps.errorMsg = null;
              setCfgValue('value', value);
              setCfgValue('timestamp', timestamp);
            }
            break;
          default:
            {
              let [value] = args;
              value = value ?? e.target?.value ?? null;
              item.controlProps.value = value;
              setCfgValue('value', value);
            }
            break;
        }
        ctx.emit('update:data', propsData);
      },
      onItemSearch: async (item, e, keyword) => {
        const { dataName } = item.fields || {};
        if (item.type === 'autocomplete-input') {
          item.controlProps.value = keyword;
          if (keyword?.length < 3) {
            item.controlProps.options = [];
            ctx.emit('change', dataName.value, keyword, item);
            return;
          }
          const countryCode = state.alpha2Code ?? getCurrentAlpha2Code();
          const page = $jss.routeData();
          const gmaps = await loadGMap(page);
          if (!gmaps?.places.AutocompleteService) return;
          const service = new gmaps.places.AutocompleteService();
          const types = [];
          switch (item.autoType) {
            case 'zipCode': {
              types.push('postal_code');
              break;
            }
            case 'cityOrZipCode': {
              types.push('postal_code', 'locality');
              break;
            }
          }
          service.getPlacePredictions({ input: keyword, types, componentRestrictions: { country: item.global ? null : countryCode } }, (predictions, status) => {
            if (status !== gmaps.places.PlacesServiceStatus.OK || !predictions) return;
            item.controlProps.options = (predictions ?? []).map((x) => ({
              code: x.structured_formatting.main_text,
              text: `${x.structured_formatting.main_text}<span style="color: #B3B3B3">, ${x.structured_formatting.secondary_text}</span>`,
              data: {
                placeId: x.place_id
              }
            }));
          });
        }
      },
      onItemFocus(item, e) {
        item.controlProps.errorMsg = null;
      },
      onItemBlur(item, e, value) {
        item.controlProps.value = value;
        const { dataName, required } = item.fields;
        const { trigger } = props.data[dataName.value] ?? {};
        if (trigger === 'blur') {
          methods.validateItem(item, required?.value ?? false);
        }
      },
      onBtnClick(e, item, btn) {
        ctx.emit('btn-click', e, item, btn.fields.dataName.value);
      },
      getItem(name) {
        return state.formItems.find((x) => x.fields.dataName.value === name);
      },
      hasItem(name) {
        return methods.getItem(name) !== null;
      },
      hasAllMandatoryCheckboxChecked() {
        for (let item of state.formItems) {
          const { type, controlProps } = item;
          if (type === 'checkbox') {
            const { required, value } = controlProps;
            if (required && !value) {
              return false;
            }
          }
        }
        return true;
      },
      getValues() {
        if (!props.form?.fields) return [true, null];
        const { externalData } = props.form.fields;
        const formData = {};
        if (externalData?.value) {
          merge(formData, {
            externalData: querystring.parse(externalData?.value)
          });
        }
        for (let item of state.formItems) {
          const { type, fields } = item;
          if (type === 'description') {
            continue;
          }
          const { dataName } = fields;
          formData[dataName.value] = methods.getItemValue(item);
        }
        return formData;
      },
      getItemValue(item) {
        if (!item) return null;
        const { type, controlProps } = item;
        let { value } = controlProps;
        switch (type) {
          case 'check-list':
            {
              const { options } = controlProps;
              value = options.filter((x) => x.checked);
            }
            break;
          case 'radio-list':
            {
              const { selectedOption } = controlProps;
              value = selectedOption;
            }
            break;
          case 'dropdown':
            {
              const { selectedOption } = controlProps;
              value = selectedOption;
            }
            break;
          default:
            break;
        }
        return value;
      },
      getItemValueByName(name) {
        const item = state.formItems.find((x) => x.fields.dataName.value === name);
        return methods.getItemValue(item);
      },
      validateItem(item, validateFilling = false, showError = true) {
        let valid = true;
        const { dataName, requiredMessage, validators, customValidators } = item.fields;
        const requiredMessageText = requiredMessage?.value ?? t('Please fill this field');
        let { type, controlProps, visible } = item;
        if (visible === false) return [true, null];
        let { value } = controlProps;
        let allValidators = [];
        if (isArray(validators)) {
          allValidators = [...validators];
        }
        if (isArray(customValidators)) {
          allValidators.push(...customValidators);
        }
        switch (type) {
          case 'form-input':
          case 'date-input':
            if (validateFilling) {
              valid = valid && methods.checkRequired(item, value, requiredMessageText, showError);
              if (!valid) {
                break;
              }
            }
            if (value) {
              valid = valid && methods.validateValidators(item, value, allValidators);
            }
            break;
          case 'phone-input':
            {
              const { phoneRequiredMessage, areaCodeRequiredMessage } = item.fields;
              if (validateFilling) {
                valid = valid && methods.checkRequired(item, value?.area?.code, areaCodeRequiredMessage?.value);
                if (!valid) {
                  break;
                }
                valid = valid && methods.checkRequired(item, value?.number, phoneRequiredMessage?.value);
                if (!valid) {
                  break;
                }
              }
              if (!isNullOrEmpty(value?.number)) {
                valid = valid && methods.validateValidators(item, value, allValidators);
              }
            }
            break;
          case 'check-list':
            {
              const { options } = controlProps;
              value = options.filter((x) => x.checked);
              if (validateFilling) {
                valid = valid && methods.checkRequired(item, value, requiredMessageText);
              }
            }
            break;
          case 'radio-list':
            {
              const { selectedOption } = controlProps;
              value = selectedOption;
              if (validateFilling) {
                valid = valid && methods.checkRequired(item, selectedOption, requiredMessageText);
              }
            }
            break;
          case 'dropdown':
            {
              const { selectedOption } = controlProps;
              value = selectedOption;
              if (validateFilling) {
                valid = valid && methods.checkRequired(item, value, requiredMessageText);
              }
            }
            break;
          case 'autocomplete-input':
            {
              if (validateFilling) {
                valid = valid && methods.checkRequired(item, value, requiredMessageText);
              }
            }
            break;
          default:
            {
              if (validateFilling) {
                valid = valid && methods.checkRequired(item, value, requiredMessageText);
              }
            }
            break;
        }
        if (!valid) {
          console.log('validate failed', dataName?.value, value);
        }
        return [valid, value];
      },
      validateItemByName(name, validateFilling = false) {
        const item = state.formItems.find((x) => x.fields.dataName.value === name);
        if (!item) return [false, null];
        return methods.validateItem(item, validateFilling);
      },
      checkRequired(item, value, message, showError = true) {
        const { type } = item;
        switch (type) {
          case 'check-list':
            {
              if (value.length === 0) {
                if (showError) {
                  item.controlProps.errorMsg = message;
                }
                return false;
              }
            }
            break;
          case 'checkbox':
            {
              if (value !== true) {
                if (showError) {
                  item.controlProps.errorMsg = message;
                }
                return false;
              }
            }
            break;
          default:
            if (isNullOrWhitespace(value)) {
              if (showError) {
                item.controlProps.errorMsg = message;
              }
              return false;
            }
            break;
        }
        return true;
      },
      validateValidators(item, value, validators, showError = true) {
        let valid = true;
        for (let validator of validators) {
          const { type } = validator.fields;
          const validatorType = settingValue(type, null);
          if (!validatorType) continue;
          const validateFunc = validateFunctions[validatorType];
          if (!validateFunc) continue;
          valid = validateFunc(item, value, validator, showError);
          if (!valid) {
            break;
          }
        }
        return valid;
      },
      showFormErrorToast: debounce(() => {
        toast.showEx({ code: ERROR_CODES.FormDefaultError }).catch();
        nextTick().then(() => {
          if (props.scrollToForm) {
            scrollToFirstFormError();
          }
        });
      }, 200),
      async validate(showError = true) {
        let valid = true;
        if (!props.form?.fields) return [true, null];
        const { id } = props.form;
        const { externalData } = props.form.fields;
        const formData = {};
        if (externalData?.value) {
          merge(formData, {
            externalData: querystring.parse(externalData?.value)
          });
        }
        for (let item of state.formItems) {
          const { type, fields } = item;
          if (type === 'description') {
            continue;
          }
          const { dataName, required } = fields;
          const [itemValid, value] = methods.validateItem(item, required?.value ?? false, showError);
          formData[dataName.value] = value;
          valid = valid && itemValid;
        }
        if (state.googleRecaptchaRef) {
          formData.gRecaptchaToken = await state.googleRecaptchaRef.getToken();
          formData.formScId = id;
        }
        if (!valid && showError) {
          methods.showFormErrorToast();
        }
        return [valid, formData];
      },
      lockItem(name) {
        const item = state.formItems.find((x) => x.fields.dataName.value === name);
        if (!item) return false;
        item.disabled = true;
        return true;
      },
      lock() {
        for (let item of state.formItems) {
          item.disabled = true;
        }
      },
      free() {
        for (let item of state.formItems) {
          item.disabled = false;
        }
      },
      getItemRequiredMsg(name) {
        const item = state.formItems.find((x) => x.fields.dataName.value === name);
        if (!item) return null;
        const { requiredMessage } = item.fields || {};
        return requiredMessage?.value ?? `Please input a valid ${name}`;
      },
      setItemSuccess(name, message) {
        const item = state.formItems.find((x) => x.fields.dataName.value === name);
        if (!item) return false;
        const { successMessage } = item.fields || {};
        return methods.setItemMsg(name, message ?? successMessage?.value ?? 'Success', 'success');
      },
      clearItemSuccess(name) {
        const item = state.formItems.find((x) => x.fields.dataName.value === name);
        if (!item) return false;
        item.controlProps.successMsg = null;
        return true;
      },
      setItemInfoMsg(name, message) {
        const item = state.formItems.find((x) => x.fields.dataName.value === name);
        if (!item) return false;
        item.controlProps.infoMsg = message;
        return true;
      },
      setItemErrorMsg(name, message) {
        const item = state.formItems.find((x) => x.fields.dataName.value === name);
        if (!item) return false;
        item.controlProps.errorMsg = message;
        return true;
      },
      setItemMsg(name, message, messageType = 'info', clearOtherMessage = true) {
        const item = state.formItems.find((x) => x.fields.dataName.value === name);
        if (!item) return false;
        if (clearOtherMessage) {
          item.controlProps.infoMsg = null;
          item.controlProps.errorMsg = null;
          item.controlProps.successMsg = null;
        }
        item.controlProps[`${messageType}Msg`] = message;
        return true;
      },
      getVerifiedConsents() {
        const set = state.formItems.reduce((prev, current) => {
          const { type, fields = {}, controlProps } = current;
          const { value } = controlProps;
          if (type !== 'checkbox' || !value) return prev;
          const { consents = [] } = fields;
          const revisionNumbers = consents.map((x) => x.fields.revisionNumber.value);
          for (let rNo of revisionNumbers) {
            if (isNullOrWhitespace(rNo)) continue;
            prev.add(rNo);
          }
          return prev;
        }, new Set());
        return Array.from(set);
      },
      getVerifiedFullConsents() {
        const result = [];
        for (let formItem of state.formItems) {
          const { type, fields = {}, controlProps } = formItem;
          if (type !== 'checkbox') continue;
          const { value } = controlProps;
          if (!value) continue;
          const { consents = [] } = fields;
          for (let c of consents) {
            const { code, revisionNumber } = c.fields || {};
            const revisionNumberVal = revisionNumber?.value;
            if (isNullOrEmpty(revisionNumberVal)) continue;
            if (result.some((x) => x.revisionNumber === revisionNumberVal)) continue;
            result.push({
              code: code?.value,
              revisionNumber: revisionNumberVal
            });
          }
        }
        return result;
      },
      getItemConsents(name) {
        const item = state.formItems.find((x) => x.fields.dataName.value === name);
        if (!item) return [];
        const {
          fields: { consents = [] }
        } = item;
        return consents.map((x) => x.fields.revisionNumber.value).filter((x) => !isNullOrWhitespace(x));
      },
      getExternalData() {
        const { externalData } = props.form.fields;
        if (isNullOrEmpty(externalData?.value)) return Object.create(null);
        return querystring.parse(externalData?.value);
      },
      updateItem(name, data) {
        const item = state.formItems.find((x) => equalString(x.fields.dataName.value, name));
        if (item) {
          const { type, controlProps, ...rest } = data;
          merge(item, { ...rest });
          if (!isNullOrEmpty(controlProps)) {
            const propKeys = Object.keys(controlProps);
            for (let pk of propKeys) {
              item.controlProps[pk] = controlProps[pk];
            }
          }
        }
      },
      setSelectedOption(name, code) {
        const item = state.formItems.find((x) => equalString(x.fields.dataName.value, name));
        const { type, controlProps } = item;
        const { options } = controlProps;
        if (options?.length && ['dropdown', 'radio-list', 'autocomplete-input'].includes(type)) {
          const selectedOption = options.find((x) => equalString(x.code, code));
          if (selectedOption) {
            merge(item, {
              controlProps: {
                selectedOption
              }
            });
          }
        }
      },
      updateItems(payload) {
        const names = Object.keys(payload);
        for (let name of names) {
          methods.updateItem(name, payload[name]);
        }
      },
      setCountry(alpha2Code) {
        state.alpha2Code = alpha2Code;
      },
      getLeadTypes() {
        const set = state.formItems.reduce((prev, current) => {
          const { type, fields = {}, controlProps } = current;
          const { value } = controlProps;
          if (type !== 'checkbox' || !value) return prev;
          const leadType = settingValue(fields.leadsType);
          if (!isNullOrWhitespace(leadType)) {
            prev.add({
              consentType: fields.dataName.value,
              leadType
            });
          }
          return prev;
        }, new Set());
        return Array.from(set);
      },
      setDealers(dealers) {
        state.formItems.forEach((item) => {
          const { type } = item;
          if (type === 'dealer-picker' && item.ref) {
            item.ref.setDealers(dealers);
          }
        });
      }
    };
    onMounted(async () => {
      const ddlCountries = [];
      const ddlShippingCountries = [];
      const ddlWebshopShippingCountries = [];
      const ddlRegistrableCountries = [];
      const alpha2Code = getCurrentAlpha2Code();
      let hasUpdated = false;
      let propsData = { ...(props.data ?? {}) };
      await nextTick();
      const setCountryOptions = (dropList, countryOptions, onlyCurrent = false, defaultCurrent = true) => {
        for (let item of dropList) {
          const { dataName } = item.fields;
          let options = [...countryOptions];
          if (onlyCurrent) {
            options = options.filter((x) => equalString(x.code, alpha2Code));
          }
          item.controlProps.options = options;
          if (defaultCurrent) {
            item.controlProps.selectedOption = options.find((x) => equalString(x.code, alpha2Code));
          }
          merge(propsData, {
            [dataName.value]: {
              controlProps: {
                ...item.controlProps
              }
            }
          });
        }
      };
      for (let item of state.formItems) {
        const { type, fields } = item;
        switch (type) {
          case 'dropdown':
            {
              const { optionType } = fields;
              const optionTypeValue = settingValue(optionType);
              switch (optionTypeValue) {
                case 'country':
                  ddlCountries.push(item);
                  break;
                case 'shipping-country':
                  ddlShippingCountries.push(item);
                  break;
                case 'webshop-shipping-country':
                  ddlWebshopShippingCountries.push(item);
                  break;
                case 'registrable-country':
                  ddlRegistrableCountries.push(item);
                  break;
                default:
                  break;
              }
            }
            break;
          default:
            break;
        }
      }
      if (ddlCountries.length > 0) {
        hasUpdated = true;
        const countryOptions = await getCountryOptions();
        setCountryOptions(ddlCountries, countryOptions);
      }
      if (ddlShippingCountries.length > 0) {
        hasUpdated = true;
        const shippingCountryOptions = await getShippingCountryOptions(props.carModel?.code);
        setCountryOptions(ddlShippingCountries, shippingCountryOptions, true);
      }
      if (ddlWebshopShippingCountries.length > 0) {
        hasUpdated = true;
        const webShopShippingCountries = await getWebshopShippingCountryOptions();
        setCountryOptions(ddlWebshopShippingCountries, webShopShippingCountries, false, false);
      }
      if (ddlRegistrableCountries.length > 0) {
        hasUpdated = true;
        const registrableCountryOptions = await getRegistrableCountryOptions();
        setCountryOptions(ddlRegistrableCountries, registrableCountryOptions);
      }
      if (hasUpdated) {
        ctx.emit('init-form', propsData);
      }
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-dynamic-form {
  $c: &;
  padding: 24px 0;
  &:empty {
    display: none;
  }
  &__item {
    display: flex;
    &-desc {
      padding-bottom: 40px;
    }
    &-control {
      max-width: 100%;
      flex-grow: 1;
      > .e-form-checkbox {
        margin-bottom: 40px;
      }
    }
    &.switch {
      + #{$c}__item {
        &:not(.switch) {
          margin-top: 40px;
        }
      }
    }
    @include mobile {
      .e-site-button {
        &__icon {
          display: none;
        }
        &.ani- {
          &link-swipe {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
      }
    }
  }
  &.hidden {
    display: none;
  }
  &:not(.hidden) {
    + .s-dynamic-form {
      margin-top: -16px;
      padding-top: 0;
    }
  }
  @include desktop {
  }
}
</style>
