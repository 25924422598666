<script setup>
import { computed } from 'vue';
import { isNullOrEmpty } from '@/utils/obj-utils';

const props = defineProps({
  fields: {
    type: Object
  },
  data: {
    type: Object
  },
  dealer: {
    type: Object
  },
  selectable: {
    type: Boolean,
    default: true
  },
  selectedDealer: {
    type: Object
  },
  theme: {
    type: String
  }
});
const checked = computed(() => props.selectedDealer?.id === props.dealer?.id);
</script>

<template>
  <div class="s-dealer-card-v2" :class="[{ selectable, checked, [`theme-${theme}`]: !isNullOrEmpty(theme) }]" v-if="dealer">
    <div class="s-dealer-card-v2__main">
      <div class="s-dealer-card-v2__name fs-18 fw-500 __lh-1_5">{{ dealer.storeName }}</div>
      <div class="s-dealer-card-v2__info">
        <div class="s-dealer-card-v2__addr">{{ dealer.addressDetail }}<br />{{ dealer.city }}</div>
        <div class="s-dealer-card-v2__distance" v-if="dealer.metre">
          <icon name="paper-plane" />
          <span>{{ $formatDistance(dealer.metre, 'm', 0) }}</span>
        </div>
      </div>
    </div>
    <icon class="s-dealer-card-v2__arrow" name="forward" size="tiny" />
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-dealer-card-v2 {
  padding: 24px 0 16px 0;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $grey-light;
  transition: all 0.2s cubic-bezier(0, 0, 0.4, 0.2);
  &.selectable {
    user-select: none;
    cursor: pointer;
  }
  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    color: $grey-next;
  }
  &__distance {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    line-height: 24px;
    white-space: nowrap;
    .e-icon {
      svg {
        height: 12px;
      }
    }
  }
  &.theme {
    &-dark {
      background: $black;
      border-color: $grey-dark;
      color: rgba($white, 0.7);
    }
  }
}
html.rtl {
  .s-dealer-card-v2 {
    &__arrow {
      transform: rotate(180deg);
    }
  }
}
</style>
