<template>
  <div class="s-consent-view">
    <div class="s-consent-view__content" v-html="detail?.htmlInfo" />
  </div>
</template>

<script>
import { inject, onMounted, reactive, toRefs } from 'vue';
import api from '@/api';

export default {
  name: 'consent-view',
  props: {
    agreementNo: {
      type: String
    }
  },
  setup(props) {
    const toast = inject('toast');
    const state = reactive({
      frameRef: null,
      loading: true,
      detail: null
    });
    onMounted(async () => {
      const [res1, ex1] = await api.consent.all(null, {
        agreementNo: props.agreementNo
      });
      console.log('res1', res1, ex1);
      const [res, ex] = await api.consent.get(null, {
        agreementNo: props.agreementNo
      });
      if (ex) {
        await toast.showEx(ex);
        return;
      }
      state.detail = res;
      state.loading = false;
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-consent-view {
  overflow-x: auto;
  &__content {
    display: block;
    margin: $space-80 auto;
    width: grid-width-m(10);
  }
  @include tablet-landscape {
    &__content {
      width: grid-width(20);
    }
  }
}
</style>
