<template>
  <div class="s-content-loading" :class="rootClasses" v-bind="$attrs">
    <div class="s-content-loading__content">
      <slot />
    </div>
    <transition name="fade-in" @after-enter="onEnter" @after-leave="onLeave">
      <div class="s-content-loading__mask" v-if="visible" />
    </transition>
    <div class="s-content-loading__spinner" v-if="visible">
      <slot name="loading" v-if="$slots.loading" />
      <site-loading v-else />
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'content-loading',
  inheritAttrs: false,
  emits: ['update:loading', 'opened', 'closed'],
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String
    }
  },
  setup(props, ctx) {
    const state = reactive({
      maskEl: null,
      visible: false,
      onTransEnd: () => {}
    });
    const computes = {
      rootClasses: computed(() => [
        {
          loading: state.visible,
          [`theme-${props.theme}`]: !isNullOrEmpty(props.theme)
        }
      ])
    };
    const methods = {
      open() {
        return new Promise((resolve) => {
          state.onTransEnd = () => {
            resolve();
          };
          state.visible = true;
        });
      },
      hide() {
        return new Promise((resolve) => {
          state.onTransEnd = () => {
            resolve();
          };
          console.log('hide');
          state.visible = false;
          ctx.emit('update:loading', false);
        });
      },
      onEnter() {
        ctx.emit('opened');
        ctx.emit('update:loading', true);
        state.onTransEnd();
      },
      onLeave() {
        ctx.emit('closed');
        state.onTransEnd();
      }
    };
    watch(
      () => props.loading,
      (loading) => {
        state.visible = loading;
      },
      {
        immediate: true
      }
    );
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-content-loading {
  $c: &;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: $blue-night;
  }
  &__spinner {
    position: absolute !important;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    color: $white;
  }
  &__content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &.loading {
    &__content {
      opacity: 0;
    }
  }
  &.theme {
    &-white {
      #{$c}__mask {
        background-color: $white;
      }
      #{$c}__spinner {
        color: $black;
      }
    }
  }
}
</style>
