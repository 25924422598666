<template>
  <div class="s-hero-landing-slide">
    <adaptive-media class="s-hero-landing-slide__bg" :class="rootClasses" :field="fields.media">
      <div class="s-hero-landing-slide__grid">
        <div class="s-hero-landing-slide__main" :class="mainClasses">
          <div class="s-hero-landing-slide__content">
            <!-- <animated-content type="skew-in" :turn-off="turnOffTextAnimation"> -->
            <animated-content type="skew-in">
              <div class="s-hero-landing-slide__disclaimer" :class="{ clickable: fields.titleDisclaimer }">
                <Heading class="s-hero-landing-slide__title" :field="headerField" :type="fields.titleType" rich @click="iconClick($event, fields.titleDisclaimer)" />
              </div>
            </animated-content>
            <!-- <animated-content type="bottom-fade-in" :delay="200" :turn-off="turnOffTextAnimation"> -->
            <animated-content type="bottom-fade-in" :delay="200">
              <Disclaimer class="s-hero-landing-slide__body" :field="fields.body" :disclaimer="fields.bodyDisclaimer" />
            </animated-content>
          </div>
          <div class="s-hero-landing-slide__buttons">
            <site-button v-for="button in buttons" :key="button.id" v-bind="button" @click="(e) => onButtonClick(e, button)" />
          </div>
          <Modal class="s-hero-landing-slide__modal" :remove-on-hidden="false" v-if="fields.video.value" ref="modalEl" @closed="onClosed" closable>
            <video-player :options="playerOptions" :show-play-button="false" ref="playerEl" @ended="onVideoEnded" />
          </Modal>
        </div>
      </div>
    </adaptive-media>
  </div>
</template>

<script>
/**
 * @typedef HeroLandingSlideFields
 * @property {GlobalSettingEntry} theme
 * @property {GlobalSettingEntry} align
 * @property {GlobalSettingEntry} valign
 * @property {SimpleField} title
 * @property {SimpleField} body
 * @property {ImageField} pcImage
 * @property {ImageField} mobileImage
 * @property {SimpleField} video
 * @property {ImageField} poster
 * @property {CheckField} muted
 * @property {ButtonField} playButton
 * @property {Array<ButtonField>} buttons
 * @property {CheckField} diagonalSymmetry
 * */
import { computed, reactive, toRefs, inject } from 'vue';
import { settingValue } from '@/utils/site-utils';
import { formatString } from '@/utils/string-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'HeroLandingSlide',
  props: {
    /**@type HeroLandingSlideFields*/
    fields: {
      type: Object
    },
    turnOffImageAnimation: {
      type: Boolean,
      default: false
    },
    turnOffTextAnimation: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const toast = inject('toast');
    const state = reactive({
      /**@type ModalRef */
      modalEl: null,
      playerEl: null
    });
    const computes = {
      rootClasses: computed(() => {
        const gradientColor = settingValue(props.fields.gradientColor);
        const gradientDirection = settingValue(props.fields.gradientDirection);
        return [
          {
            [`gradient-${gradientColor}`]: !!gradientColor,
            [`gradient-${gradientDirection}`]: !!gradientDirection
          }
        ];
      }),
      mainClasses: computed(() => [
        `align-${settingValue(props.fields.align, 'left')}`,
        `valign-${settingValue(props.fields.valign, 'top')}`,
        {
          'diagonal-symmetry': props.fields.diagonalSymmetry.value && computes.buttons.value?.length > 0
        }
      ]),
      buttons: computed(() => {
        const { buttons } = props.fields || [];
        const formattedButtons = [];
        for (let btn of buttons) {
          if (!btn.fields) continue;
          let btnText = btn.fields?.text.value ?? btn.fields?.link.value?.text;
          const btnFields = { ...btn.fields };
          const buttonType = settingValue(btnFields.buttonType, 'link');
          if (buttonType === 'play-video') {
            delete btnFields.link;
          }
          formattedButtons.push({ id: btn.id, name: btn.name, fields: { ...btnFields, text: { value: btnText } }, type: buttonType });
        }
        return formattedButtons;
      }),
      playerOptions: computed(() => {
        return {
          muted: props.fields.muted.value,
          controls: true,
          fluid: true,
          poster: props.fields.poster.value?.src,
          disableFullscreen: props.fields.videoDisablefullscreen?.value ?? false,
          disableUnmute: props.fields.videoDisableUnmute?.value ?? false,
          sources: [
            {
              src: props.fields.video.value,
              type: 'video/mp4'
            }
          ]
        };
      }),
      headerField: computed(() => {
        return {
          value: formatString(props.fields.title.value, { disclaimer: props.fields.titleDisclaimer?.fields?.icon?.value?.svgCode })
        };
      })
    };
    const methods = {
      onButtonClick(e, button) {
        if (button.type === 'play-video') {
          methods.onPlay();
          e.preventDefault();
        }
      },
      async onPlay() {
        await state.modalEl.open();
        state.playerEl.player.ready(() => {
          state.playerEl.player.play();
        });
      },
      async onClosed() {
        state.playerEl.player.pause();
        state.playerEl.player.currentTime(0);
        state.playerEl.playBtnVisible = false;
      },
      async onVideoEnded() {
        await state.modalEl.close();
      },
      iconClick: (e, i) => {
        const txt = i?.fields?.text.value;
        if (isNullOrEmpty(txt)) return;
        e.stopPropagation();
        toast.show({
          icon: 'lotus',
          message: txt,
          closable: true
        });
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-hero-landing-slide {
  $c: &;
  $media: #{'.e-adaptive-media'};
  &__bg {
    height: 100%;
    &.e-adaptive-media {
      @each $color-name, $color-value in $colors {
        &.#{'gradient-' + $color-name} {
          position: relative;
          &.gradient-ltr,
          &.gradient-rtl {
            height: 100%;
            #{$media}__content {
              z-index: 1;
              transform: translateZ(1px);
            }
            #{$media}__image {
              position: relative;
              &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                transform: translateZ(1px);
              }
            }
            .e-video-player {
              position: relative;
              &:before {
                content: '';
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                transform: translateZ(1px);
              }
            }
          }
          &.gradient-ltr {
            #{$media}__image,
            .e-video-player {
              &:before {
                background-image: linear-gradient(to right, rgba($color-value, 0.75), rgba($color-value, 0.5) 25%, transparent 60%, transparent) !important;
              }
            }
          }
          &.gradient-rtl {
            #{$media}__image,
            .e-video-player {
              &:before {
                background-image: linear-gradient(to left, rgba($color-value, 0.75), rgba($color-value, 0.5) 25%, transparent 60%, transparent) !important;
              }
            }
          }
        }
      }
    }
  }
  &__grid {
    @include grid-container;
    height: 100%;
  }
  &__main {
    height: 100%;
    @include grid-block(2, 10);
    padding: grid-width-m(1) 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    &.align-left {
      align-items: flex-start;
      #{$c}__content {
        text-align: start;
      }
      #{$c}__buttons {
        align-self: flex-start;
      }
      &.diagonal-symmetry {
        &.valign-top {
          justify-content: space-between;
          #{$c}__buttons {
            align-self: flex-end;
          }
        }
        &.valign-bottom {
          justify-content: space-between;
          #{$c}__buttons {
            order: 1;
            align-self: flex-end;
          }
        }
      }
    }
    &.align-center {
      align-items: center;
      #{$c}__content {
        text-align: center;
      }
      #{$c}__buttons {
        width: fit-content;
        align-self: center;
      }
      &.valign-middle {
        justify-content: space-between;
      }
    }
    &.align-right {
      align-items: flex-end;
      #{$c}__content {
        text-align: end;
      }
      #{$c}__buttons {
        width: fit-content;
        align-self: flex-end;
      }
      &.diagonal-symmetry {
        &.valign-top {
          justify-content: space-between;
          #{$c}__buttons {
            align-self: flex-start;
          }
        }
        &.valign-bottom {
          justify-content: space-between;
          #{$c}__buttons {
            order: 1;
            align-self: flex-start;
          }
        }
      }
    }
    &.valign-top {
      justify-content: flex-start;
    }

    &.valign-middle {
      justify-content: center;
    }
    &.valign-bottom {
      justify-content: flex-end;
    }
  }
  &__title {
    @include h3;
    margin-bottom: $space-12;
  }
  &__body {
    @include h6;
    margin-bottom: $space-30;
  }
  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $space-30;
  }
  &__modal {
    .e-modal__content {
      width: grid-width-m(10);
      overflow: hidden;
    }
    .e-modal__close {
      top: 12px;
      right: 12px;
      z-index: 1;
      transition: all 0.2s ease-in-out;
      color: $white;
      &:hover {
        transform: scale(1.1);
      }
    }
    &__content {
      position: relative;
      background: $yellow;
    }
  }
  &__disclaimer {
    &.clickable {
      cursor: pointer;
    }
  }
  @include tablet-landscape {
    &__main {
      @include grid-block(3, 20);
      padding: grid-width(2) 0;
    }
    &__title {
      @include h6;
      margin-bottom: $space-16;
    }
    &__body {
      @include h7;
      margin-bottom: $space-56;
      max-width: grid-width(8);
    }
    &__modal {
      .e-modal__content {
        width: grid-width(20);
        height: grid-width(20 * math.div(9, 16));
      }
    }
    &__buttons {
      flex-direction: row;
    }
  }
  @include desktop-large {
    &__title {
      @include h3;
      margin-bottom: $space-16;
    }
    &__body {
      @include h6;
      margin-bottom: $space-56;
      max-width: grid-width(8);
    }
  }
}
</style>
