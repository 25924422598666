<template>
  <div class="s-checkout-steps" v-if="!$isOnApp($route)">
    <div class="s-checkout-steps__mobile" v-if="$deviceComputes.isMobileOrTablet.value">
      <div class="s-checkout-steps__left">
        <div class="s-checkout-steps__prev" v-if="prevStep" @click="onPrevClick">
          <icon name="left" />
          <div class="s-checkout-steps__prev-text" v-html="prevStep" />
        </div>
      </div>
      <div class="s-checkout-steps__progress">{{ $t('Step') }} {{ current + 1 }}/{{ steps.length }}</div>
    </div>
    <div class="s-checkout-steps__laptop" v-else>
      <div class="s-checkout-steps__step" :class="[{ finished: index < current, current: index === current }]" v-for="(step, index) in steps" :key="step.id">
        <div class="s-checkout-steps__step-text" v-html="step?.fields?.title?.value" />
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  props: {
    steps: {
      type: Array
    },
    current: {
      type: Number
    },
    mhpUrl: {
      type: String
    }
  },
  setup(props, ctx) {
    const computes = {
      prevStep: computed(() => (props.current > 0 ? props.steps[props.current - 1].fields?.title?.value : 'Edit car configuration'))
    };
    const methods = {
      onPrevClick() {
        if (props.current === 0 && !isNullOrEmpty(props.mhpUrl)) {
          window.location = props.mhpUrl;
        } else if (props.current === 2) {
          ctx.emit('update:current', 1);
        }
      }
    };
    return {
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-checkout-steps {
  $c: &;
  margin-bottom: 60px;
  &__mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__prev {
    display: flex;
    align-items: center;
    .e-icon {
      margin-right: 8px;
    }
  }
  &__laptop {
    display: flex;
    padding-left: grid-width(2);
    padding-right: grid-width(2);
    overflow: hidden;
  }
  &__step {
    display: block;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: visible;
    position: relative;
    text-align: center;
    padding-bottom: 54px;
    font-size: 12px;
    &-text {
      position: absolute;
      top: 0;
      left: 4px;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      white-space: nowrap;
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid $grey-taubmans;
      position: absolute;
      bottom: 28px;
    }
    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid $grey-taubmans;
      background: $white;
      position: absolute;
      left: 0;
      bottom: 24px;
    }
    &:last-child {
      flex-grow: 0;
      width: 8px;
      &:before {
        width: 0;
      }
    }
    &.current {
      &:after {
        border-color: $black;
        background: $black;
      }
      &:first-child {
        &:before {
          border-bottom-color: $black;
        }
        &:after {
          background: $yellow;
        }
      }
    }
    &.finished {
      &:before {
        border-bottom-color: $black;
      }
      &:after {
        border-color: $black;
        background: $yellow;
      }
    }
  }
}
html.rtl {
  .s-checkout-steps {
    &__prev {
      .e-icon {
        margin-right: 0;
        margin-left: 8px;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
