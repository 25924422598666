<script setup>
import { computed, onMounted, reactive, watch } from 'vue';
import services from '@/services';
import { APP_CONST } from '@/utils/constants';
import utils from '@/utils';
import { isNullOrEmpty, padding } from '@/utils/obj-utils';
import { isDate } from 'lodash';
import { formatDate } from '@/utils/date-utils';
import { useI18n } from 'vue-i18n';
const emit = defineEmits(['update:date', 'update:time']);
const props = defineProps({
  date: {
    type: String
  },
  time: {
    type: String
  },
  configuration: {
    type: Object
  }
});
const { t } = useI18n();
const state = reactive({
  config: null,
  /**@type {?string}*/
  bannerText: null,
  showDayView: true,
  showYearView: false,
  showMonthView: false
});
const yearView = reactive({
  value: null,
  min: 1800,
  max: 2200,
  page: 1,
  pageSize: 30,
  totalPage: Math.ceil((2200 - 1800) / 30),
  set: []
});
const monthView = reactive({
  value: null,
  set: []
});
const computes = {
  prevDisabled: computed(() => {
    console.log('view status', state.showDayView, state.showYearView, state.showMonthView);
    if (state.showDayView) {
      return yearView.value <= yearView.min && monthView.value <= 0;
    }
    if (state.showYearView) {
      console.log('abc');
      return yearView.page <= 1;
    }
    if (state.showMonthView) {
      return yearView.value <= yearView.min;
    }
    return true;
  }),
  nextDisabled: computed(() => {
    if (state.showDayView) {
      return yearView.value >= yearView.max && monthView.value >= 11;
    }
    if (state.showYearView) {
      return yearView.page >= yearView.totalPage;
    }
    if (state.showMonthView) {
      return yearView.value <= yearView.min;
    }
    return true;
  })
};
const dayView = reactive({
  value: null,
  startBlankCount: 0,
  endBlankCount: 0,
  set: []
});
const buildYearSet = () => {
  yearView.set = Array.from({ length: yearView.pageSize }, (v, i) => yearView.min + (yearView.page - 1) * yearView.pageSize + i);
};
const buildBannerText = () => {
  state.bannerText = utils.date.sysFormatDate(new Date(yearView.value, monthView.value - 1, dayView.value), {
    year: 'numeric',
    month: 'long'
  });
};
const buildDaySet = () => {
  const year = yearView.value;
  const month = monthView.value;
  const day = dayView.value;
  let dateObj = new Date(year, month - 1, 1);
  const dayOfWeek = dateObj.getDay();
  const totalDays = new Date(year, month, 0).getDate();
  const prevTotalDays = new Date(year, month - 1, 0).getDate();
  let count = totalDays + dayOfWeek - 1;
  dayView.set = [];
  for (let i = 1, len = dayOfWeek + 1; i < len; i++) {
    dayView.set.push({
      month: month - 1,
      visible: false,
      value: prevTotalDays - (dayOfWeek - i) + 1
    });
    ++count;
  }
  for (let i = 1; i <= totalDays; i++) {
    dayView.set.push({
      month: month,
      visible: true,
      value: i
    });
  }
  if (count < 42) {
    for (let i = 1, len = 42 - count; i <= len; i++) {
      dayView.set.push({
        month: month + 1,
        visible: false,
        value: i
      });
    }
  }
};
const onBannerClick = () => {
  if (state.showDayView) {
    state.showDayView = false;
    state.showMonthView = false;
    state.showYearView = true;
  } else {
    state.showDayView = true;
    state.showMonthView = false;
    state.showYearView = false;
  }
};
const setYear = (val) => {
  yearView.value = val;
  emit('update:date', `${yearView.value}-${padding(monthView.value)}-${padding(dayView.value)}`);
  state.showDayView = false;
  state.showYearView = false;
  state.showMonthView = true;
};
const setMonth = (month) => {
  monthView.value = month.value;
  emit('update:date', `${yearView.value}-${padding(monthView.value)}-${padding(dayView.value)}`);
  state.showDayView = true;
  state.showYearView = false;
  state.showMonthView = false;
};
const setDate = (day) => {
  if (!day.visible) return;
  dayView.value = day.value;
  emit('update:date', `${yearView.value}-${padding(monthView.value)}-${padding(dayView.value)}`);
};
const onPrev = () => {
  if (state.showDayView) {
    if (monthView.value === 0) {
      yearView.value -= 1;
      monthView.value = 11;
    } else {
      monthView.value -= 1;
    }
    buildBannerText();
    buildDaySet();
  } else if (state.showYearView) {
    if (yearView.page > 1) {
      yearView.page -= 1;
      buildYearSet();
    }
  }
};
const onNext = () => {
  if (state.showDayView) {
    if (monthView.value >= 11) {
      yearView.value += 1;
      monthView.value = 0;
    }
    monthView.value += 1;
    buildBannerText();
    buildDaySet();
  } else if (state.showYearView) {
    const totalPage = Math.ceil((yearView.max - yearView.min) / yearView.pageSize);
    console.log('totalPage', totalPage);
    if (yearView.page < totalPage) {
      yearView.page += 1;
      buildYearSet();
    }
  }
};
watch(
  () => props.date,
  (_date) => {
    if (isNullOrEmpty(_date)) return;
    let dateObj = _date;
    if (!isDate(dateObj)) dateObj = new Date(_date);
    if (dateObj?.toString() === 'Invalid date') return;
    const yearOfDate = dateObj.getFullYear();
    yearView.value = yearOfDate;
    monthView.value = dateObj.getMonth() + 1;
    dayView.value = dateObj.getDate();
    yearView.page = Math.ceil((yearOfDate - yearView.min) / yearView.pageSize);
    buildYearSet();
    buildDaySet();
    buildBannerText();
  },
  {
    immediate: true
  }
);
onMounted(async () => {
  state.config = await services.site.getDatePickerConfig();
  const now = new Date();
  const startDate = new Date();
  startDate.setDate(1);
  const endDate = new Date();
  endDate.setMonth(endDate.getMonth() + 1, -1);
  console.log('endDate', startDate, endDate);
  monthView.startBlankCount = now.getDate();
  const monthKeys = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
  monthView.set = monthKeys.map((key, index) => ({
    value: index + 1,
    text: state.config.monthLabels[key]
  }));
});
</script>

<template>
  <div class="s-dpv" v-if="state.config">
    <div class="s-dpv__header">
      <div class="s-dpv__header-left" @click="onBannerClick">
        <div class="s-dpv__selected-date">{{ state.bannerText }}</div>
        <icon name="down" size="xs" />
      </div>
      <div class="s-dpv__controls">
        <icon name="left" :class="[{ disabled: computes.prevDisabled.value }]" @click="onPrev" />
        <icon name="right" :class="[{ disabled: computes.nextDisabled.value }]" @click="onNext" />
      </div>
    </div>
    <div class="s-dpv__view">
      <transition name="options-ani">
        <div class="s-dpv__view-day" v-if="state.showDayView">
          <template v-for="wl in state.config?.weekLabels" :key="wl">
            <div class="s-dpv__cell week-label">
              <span class="s-dpv__cell-val">{{ wl }}</span>
            </div>
          </template>
          <template v-for="d of dayView.set" :key="`${d.month}-${d.value}`">
            <div class="s-dpv__cell" :class="[{ active: d.visible && d.value === dayView.value, empty: !d.visible, clickable: d.visible }]" @click="setDate(d)">
              <span class="s-dpv__cell-val">{{ d.visible ? $padding(d.value) : '' }}</span>
            </div>
          </template>
        </div>
        <div class="s-dpv__view-year" v-else-if="state.showYearView">
          <div class="s-dpv__cell clickable" :class="[{ active: y === yearView.value }]" @click="setYear(y)" v-for="y in yearView.set" :key="y">
            <span class="s-dpv__cell-val">{{ y }}</span>
          </div>
        </div>
        <div class="s-dpv__view-month" v-else-if="state.showMonthView">
          <template v-for="m of monthView.set" :key="m.value">
            <div class="s-dpv__cell clickable" :class="[{ active: m.value === monthView.value }]" @click="setMonth(m)">
              <span class="s-dpv__cell-val">{{ m.text }}</span>
            </div>
          </template>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-dpv {
  display: flex;
  flex-direction: column;
  gap: 16px;
  user-select: none;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      min-width: 140px;
      cursor: pointer;
    }
  }
  &__controls {
    display: flex;
    align-items: center;
    gap: 32px;
    .e-icon {
      cursor: pointer;
      svg {
        height: 20px;
      }
      &.disabled {
        color: $grey-taubmans;
      }
    }
  }
  &__view {
    height: 280px;
    position: relative;
    &-year {
      position: absolute;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(auto-fit, minmax(1fr, 50px));
      grid-auto-rows: max-content;
      column-gap: 1.6rem;
      row-gap: 8px;
    }
    &-month {
      position: absolute;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: max-content;
      column-gap: 2rem;
      row-gap: 8px;
    }
    &-day {
      position: absolute;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-auto-rows: max-content;
      column-gap: 1rem;
      row-gap: 8px;
    }
  }
  &__cell {
    $cell: &;
    display: flex;
    justify-content: center;
    align-items: center;
    &.week-label {
      font-weight: 600;
    }
    &-val {
      height: 36px;
      min-width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      border: 1px solid transparent;
    }
    &.clickable {
      cursor: pointer;
      &.active {
        #{$cell}-val {
          background: radial-gradient(77.27% 77.27% at 50.83% 100%, rgba(46, 46, 46, 0.8) 0%, #000000 100%);
          border: 1px solid $grey-neutral;
          border-radius: 1px;
          color: $yellow;
        }
      }
      &:hover {
        #{$cell}-val {
          border: 1px solid #4f4f4f;
        }
      }
    }
  }
  @include tablet {
    &__view {
      &-day {
        column-gap: 0.8rem;
      }
    }
  }
  @include desktop {
    &__view {
      &-year {
        column-gap: 1.2rem;
      }
    }
  }
  @include desktop-normal {
    &__view {
      &-year {
        column-gap: 2rem;
      }
    }
  }
  @include desktop-large {
    &__view {
      &-year {
        column-gap: 2.1rem;
      }
    }
  }
}
</style>
