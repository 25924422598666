<template>
  <div class="s-image-swiper">
    <div class="s-image-swiper-content">
      <JssText class="s-image-swiper-title" :field="title" tag="div" />
      <div class="s-image-swiper-right-content">
        <a :href="downloadHighSrc" class="s-image-swiper-high-content" download="">
          <Icon class="s-image-swiper-download-icon" :svg="download.value.svgCode" />
          <JssText class="s-image-swiper-high" :field="high" tag="div" />
        </a>
        <a :href="downloadLowSrc" class="s-image-swiper-low-content" download="">
          <Icon class="s-image-swiper-download-icon" :svg="download.value.svgCode" />
          <JssText class="s-image-swiper-low" :field="low" tag="div" />
        </a>
        <div class="s-image-swiper-close" @click="hideFullSwiperImageFn">
          <Icon class="s-image-swiper-close-icon" :svg="close.value.svgCode" />
        </div>
      </div>
    </div>
    <div class="s-image-swiper-top-swiper-content" ref="topSwiperEl">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in list" :key="index">
          <BackgroundImage class="s-image-swiper-top-image" :image="item.fields.bigImage" />
        </div>
      </div>
      <div class="s-image-swiper-top-pagination-prevEl" ref="prevEl" v-show="list.length > 1 && currentIndex !== 0">
        <Icon class="s-image-swiper-left-icon" :svg="left.value.svgCode" />
      </div>
      <div class="s-image-swiper-top-pagination-nextEl" ref="nextEl" v-show="list.length > 1 && currentIndex !== list.length - 1">
        <Icon class="s-image-swiper-right-icon" :svg="right.value.svgCode" />
      </div>
    </div>

    <div class="s-image-swiper-bottom-swiper-content" ref="bottomSwiperEl">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in list" :key="index" :class="currentIndex === index ? 'active' : ''">
          <BackgroundImage class="s-image-swiper-bottom-image" :image="item.fields.smallImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, nextTick, toRefs, reactive } from 'vue';
import Swiper from 'swiper';
import { isMobile } from '@/utils/dom-utils';
import { gridWidth } from '@/utils/site-utils';

export default {
  name: 'image-swiper',
  emits: ['btn-click'],
  props: {
    title: {
      type: Object
    },
    close: {
      type: Object
    },
    list: {
      type: Object
    },
    high: {
      type: Object
    },
    low: {
      type: Object
    },
    left: {
      type: Object
    },
    right: {
      type: Object
    },
    download: {
      type: Object
    }
  },
  setup(props, ctx) {
    let topSwiper = null;
    let bottomSwiper = null;
    let state = reactive({
      topSwiperEl: null,
      bottomSwiperEl: null,
      prevEl: null,
      nextEl: null,
      currentIndex: 0,
      downloadHighSrc: null,
      downloadLowSrc: null
    });

    const buildTopSwiper = () => {
      if (topSwiper) {
        topSwiper.destroy(true, true);
      }
      topSwiper = new Swiper(state.topSwiperEl, {
        slidesPerView: '1',
        navigation: {
          prevEl: state.prevEl,
          nextEl: state.nextEl
        },
        on: {
          slideNextTransitionStart() {
            state.currentIndex = this.activeIndex;
            bottomSwiper.slideNext();
            state.downloadHighSrc = props.list[this.activeIndex].fields.bigImage.value.src;
            state.downloadLowSrc = props.list[this.activeIndex].fields.smallImage.value.src;
          },
          slidePrevTransitionStart() {
            state.currentIndex = this.activeIndex;
            bottomSwiper.slidePrev();
            state.downloadHighSrc = props.list[this.activeIndex].fields.bigImage.value.src;
            state.downloadLowSrc = props.list[this.activeIndex].fields.smallImage.value.src;
          }
        }
      });
    };

    const buildBottomSwiper = () => {
      if (bottomSwiper) {
        bottomSwiper.destroy(true, true);
      }
      bottomSwiper = new Swiper(state.bottomSwiperEl, {
        slidesPerView: isMobile() ? '2.5' : '8.5',
        spaceBetween: 15,
        watchSlidesVisibility: true,
        previousMargin: 40,
        on: {
          click() {
            if (this.clickedIndex === 'undefined') {
              return false;
            }
            state.currentIndex = this.clickedIndex;
            topSwiper.slideTo(this.clickedIndex, 1000, false);
            state.downloadHighSrc = props.list[this.activeIndex].fields.bigImage.value.src;
            state.downloadLowSrc = props.list[this.activeIndex].fields.smallImage.value.src;
          }
        }
      });
    };

    const methods = {
      hideFullSwiperImageFn() {
        ctx.emit('btn-click');
        document.body.style.overflow = 'auto';
      },
      initSwiper() {
        buildBottomSwiper();
        buildTopSwiper();
      }
    };

    onMounted(() => {
      state.downloadHighSrc = props.list[0].fields.bigImage.value.src;
      state.downloadLowSrc = props.list[0].fields.smallImage.value.src;
      nextTick(() => {
        buildBottomSwiper();
        buildTopSwiper();
      });
      window.addEventListener('resize', methods.initSwiper);
    });
    onUnmounted(() => {
      topSwiper.destroy(true, true);
      bottomSwiper.destroy(true, true);
      window.removeEventListener('resize', methods.initSwiper);
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-image-swiper {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  background: #000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &-content {
    width: 100%;
    padding-left: grid-width-m(0.5);
    display: block;
    position: relative;
    height: grid-width-m(2);
  }
  &-title {
    @include h7;
    color: #fff;
    width: grid-width-m(10);
    position: absolute;
    bottom: grid-width-m(-2);
    left: grid-width-m(1.5);
  }
  &-close {
    width: grid-width-m(2);
    height: grid-width-m(2);
    background: #fff200;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-close-icon {
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &-top-swiper-content {
    width: 100%;
    height: 225px;
    overflow: hidden;
    margin-top: grid-width-m(3);
    position: relative;
  }
  &-top-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &-top-pagination-prevEl {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 0;
    bottom: 92px;
    z-index: 2;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 15px;
    }
  }
  &-top-pagination-nextEl {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 0;
    bottom: 92px;
    z-index: 2;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 15px;
    }
  }
  &-bottom-swiper-content {
    width: 100%;
    height: 84px;
    padding: 0;
    position: absolute;
    top: grid-width-m(14);
    left: 0;
    z-index: 1;
    overflow: hidden;
    .swiper-wrapper {
      width: 100%;
      height: 100%;
      .active {
        border: 1px solid #fff;
      }
    }
  }
  &-bottom-image {
    width: 100%;
    height: 100%;
  }
  &-high {
    @include h7;
    color: #fff;
    white-space: nowrap;
    margin-left: 16px;
  }
  &-low {
    @include h7;
    color: #fff;
    white-space: nowrap;
    margin-left: 16px;
  }
  &-high-content {
    display: block;
    position: absolute;
    top: grid-width-m(18);
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-low-content {
    display: block;
    position: absolute;
    top: grid-width-m(19.5);
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @include tablet-landscape {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background: #000;
    display: flex;
    flex-direction: column;
    &-content {
      width: 100%;
      padding-left: grid-width(0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: grid-width(1);
    }
    &-right-content {
      display: flex;
    }
    &-high-content {
      position: inherit;
      display: flex;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
    }
    &-high {
      @include h7;
      color: #fff;
      white-space: nowrap;
      margin-left: 16px;
    }
    &-low-content {
      position: inherit;
      display: flex;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
    }
    &-low {
      @include h7;
      color: #fff;
      white-space: nowrap;
      margin-left: 16px;
    }
    &-title {
      @include h7;
      color: #fff;
      width: auto;
      position: initial;
    }
    &-close {
      width: grid-width(1);
      height: grid-width(1);
      background: #fff200;
      position: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    &-close-icon {
      svg {
        width: 30px;
        height: 30px;
      }
    }
    &-top-swiper-content {
      width: 100%;
      overflow: hidden;
      flex: 1;
      margin-top: 0;
      cursor: pointer;
    }
    &-top-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-top-pagination-prevEl {
      width: 48px;
      height: 48px;
      position: absolute;
      left: 0;
      bottom: 48px;
      z-index: 2;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        width: 24px;
        height: 18px;
      }
    }
    &-top-pagination-nextEl {
      width: 48px;
      height: 48px;
      position: absolute;
      right: 0;
      bottom: 48px;
      z-index: 2;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        width: 24px;
        height: 18px;
      }
    }
    &-bottom-swiper-content {
      width: 100%;
      height: 148px;
      padding: 24px 0 24px 24px;
      position: absolute;
      top: initial;
      bottom: 0;
      left: 0;
      z-index: 1;
      overflow: hidden;
      cursor: pointer;
      .swiper-wrapper {
        width: 100%;
        height: 100%;
        .active {
          border: 1px solid #fff;
        }
      }
    }
    &-bottom-image {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
