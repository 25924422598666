<script setup>
import { computed, onMounted, ref } from 'vue';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { equalString, formatString, ifEmpty } from '@/utils/string-utils';
import { formatCabValue } from '@/utils/finance-utils';
import utils from '@/utils';
import { fm2, toThousands } from '@/utils/math-utils';
import { useI18n } from 'vue-i18n';
import { getCurrentAlpha2Code } from '@/utils/site-utils';
const props = defineProps({
  state: {
    type: Object
  },
  calculation: {
    type: Object
  }
});
const alpha2Code = ref();
const santanderResult = computed(() => props.calculation?.santander?.selectedSimulation);
const cabResult = computed(() => {
  const userSelection = props.calculation?.cab?.userSelection;
  const detailsTable = userSelection?.offer?.instalment?.detailsTable ?? props.calculation?.cab?.instalment?.detailsTable;
  return detailsTable?.filter((x) => x.frontend && !isNullOrEmpty(x.label));
});
const getProductName = (dock) => {
  if (!props.calculation || !props.calculation[dock]?.mappedProduct) return null;
  const p = props.calculation[dock].mappedProduct;
  return ifEmpty(p.title, p.name);
};
const getCaValue = (key) => {
  const userSelection = props.calculation?.cab?.userSelection;
  const detailsTable = userSelection?.offer?.instalment?.detailsTable ?? props.calculation?.cab?.instalment?.detailsTable;
  const item = detailsTable?.find((x) => equalString(x.key, key));
  return item?.value;
};
onMounted(() => {
  alpha2Code.value = getCurrentAlpha2Code();
});
</script>

<template>
  <div class="s-std-quote" v-if="state && calculation">
    <template v-if="calculation.codeWaver">
      <div class="s-std-quote__title">{{ calculation.codeWaver.result.quote?.finance.product.name }}</div>
      <template v-for="(block, i) in calculation.codeWaver.result.quote.blocks" :key="i">
        <div class="s-std-quote__line" v-if="i > 0" />
        <template v-for="(detail, j) in block.details" :key="j">
          <label-value :label="detail.label" :value="detail.displayValue" />
        </template>
      </template>
      <div class="mg-t-24 text-desc" v-html="calculation.codeWaver.result.terms" />
    </template>
    <template v-if="santanderResult">
      <div class="s-std-quote__title">{{ getProductName('santander') }}</div>
      <label-value :label="$t('Financed amount')" v-if="santanderResult.amountOfCredit?.value">{{ fm2(santanderResult.amountOfCredit?.value, state.currency) }}</label-value>
      <label-value :label="alpha2Code === 'DE' && ['Leasing', 'Financial Lease'].includes(props.calculation?.santander?.mappedProduct?.name) ? $t('Special leasing payment') : $t('Down payment')">{{
        fm2(santanderResult.downPayment?.value, state.currency)
      }}</label-value>
      <template v-if="$equalString(props.calculation?.santander?.mappedProduct?.name, 'Balloon loan')">
        <label-value :label="$t('First installment')" v-if="santanderResult.firstPayment?.value">{{ fm2(santanderResult.firstPayment?.value, state.currency) }}</label-value>
      </template>
      <template v-if="santanderResult.monthlyPayment.value">
        <label-value :label="$t('Monthly rate')" v-if="['Financial Lease', 'Leasing'].includes(props.calculation?.santander?.mappedProduct?.name) || equalString(alpha2Code.value, 'NL')">{{
          fm2(santanderResult.monthlyPayment.value, state.currency)
        }}</label-value>
        <label-value
          :label="
            ['Financial Lease', 'Leasing'].includes(props.calculation?.santander?.mappedProduct?.name) || equalString(alpha2Code.value, 'NL')
              ? $t('Monthly rate')
              : formatString($t('##no## monthly instalments of'), { no: santanderResult.duration.value - 2 })
          "
          v-else
          >{{ fm2(santanderResult.monthlyPayment.value, state.currency) }}</label-value
        >
      </template>
      <template v-if="['Leasing', 'Financial Lease'].includes(props.calculation?.santander?.mappedProduct?.name)">
        <label-value :label="$t('Vehicle retail price')" v-if="santanderResult.retailPrice?.value">{{ fm2(santanderResult.retailPrice?.value, state.currency) }}</label-value>
      </template>
      <label-value :label="$t('Calculated final instalment')" v-if="santanderResult.lastPayment?.value">{{ fm2(santanderResult.lastPayment?.value, state.currency) }}</label-value>
      <label-value :label="alpha2Code === 'DE' ? `${$t('Term in months')}/${$t('Number of installments')}` : $t('Duration')" v-if="santanderResult.duration?.value">
        {{ santanderResult.duration?.value }}{{ alpha2Code === 'DE' ? '' : ` ${$t('Months')}` }}</label-value
      >
      <label-value :label="$t('Total cost of credit')" v-if="santanderResult.totalAmountFinanced?.value">{{ fm2(santanderResult.totalAmountFinanced?.value, state.currency) }}</label-value>
      <label-value :label="$t('Yearly mileage')" v-if="santanderResult.annualMileage?.value">{{ $toThousands(santanderResult.annualMileage?.value) }} km</label-value>
      <label-value :label="$t('Fixed borrowing rate p.a.')" v-if="santanderResult.nominalInterest?.value">{{ $toThousands(santanderResult.nominalInterest?.value, 2, 2) }}%</label-value>
      <label-value :label="$t('JKP')" v-if="santanderResult.JKP?.value">{{ $toThousands(santanderResult.JKP?.value, 2, 2) }}%</label-value>
      <label-value :label="$t('Effective interest rate')" v-if="santanderResult.effectiveInterest?.value">{{ $toThousands(santanderResult.effectiveInterest?.value, 2, 2) }}%</label-value>
      <template v-if="utils.obj.isArray(santanderResult.services) && santanderResult.services.length > 0">
        <div class="mg-t-8">{{ $t('Included') }}</div>
        <template v-for="service in santanderResult.services" :key="service.name">
          <label-value :label="service.name">{{ fm2(service.monthlyPrime, state.currency) }} / {{ $t('Month') }}</label-value>
        </template>
      </template>
      <div class="mg-t-24 text-desc" v-html="decodeURIComponent(santanderResult.legalDisclaimer?.value ?? santanderResult.legalDisclaimer)" />
    </template>
    <template v-else-if="cabResult">
      <div class="s-std-quote__title">{{ getProductName('cab') }}</div>
      <template v-for="item in cabResult" :key="item.key">
        <label-value :label="item.label" :value="formatCabValue(item, { currency: state.currency })" />
      </template>
      <div class="mg-t-24 text-desc" v-html="getCaValue('parsedLegalNote')" />
    </template>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-std-quote {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 32px;
  &__line {
    border-top: 1px solid $grey-89;
  }
  .e-label-value {
    padding: 0;
    &__label {
      text-transform: unset;
      color: $grey-next;
    }
    &__value {
      text-align: right;
    }
  }
}
</style>
