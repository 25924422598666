<template>
  <div class="s-checkout-car-swiper">
    <div class="swiper" ref="swiperEl">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(image, index) in carImages" :key="index">
          <img class="s-checkout-car-swiper__image" :src="image" :alt="`car_image_${index + 1}`" />
        </div>
      </div>
      <div class="swiper-pagination" ref="pagerEl" />
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, nextTick, onMounted, reactive, toRefs } from 'vue';
import Swiper from 'swiper';

export default {
  name: 'checkout-car-swiper',
  props: {
    carImages: {
      type: Array
    }
  },
  setup(props) {
    let swiper = null;
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const state = reactive({
      swiperEl: null,
      pagerEl: null
    });
    onMounted(async () => {
      if (props.carImages?.length > 1) {
        swiper = new Swiper(state.swiperEl, {
          slidesPerView: 1,
          spaceBetween: 1,
          autoplay: true,
          loop: true,
          observer: true,
          pagination: {
            el: state.pagerEl,
            clickable: true
          }
        });
      }
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-checkout-car-swiper {
  background: $black;
  .swiper {
    aspect-ratio: 16 / 9;
    overflow: hidden;
  }
  &__image {
    outline: none;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-wrapper {
    &:empty {
      display: none;
    }
  }
  .swiper-pagination {
    position: absolute;
    z-index: 2;
    right: 16px;
    bottom: 16px;
    display: flex;
    gap: 4px;
  }
  .swiper-pagination-bullet {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: $white;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.055, 0.215, 0, 1);
    &-active {
      display: block;
      width: 16px;
      height: 8px;
      border-radius: 4px;
      background: $yellow;
      cursor: default;
    }
  }
}
</style>
