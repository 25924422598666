<script setup>
import { reactive, inject, onMounted, onBeforeUnmount, computed } from 'vue';
import BUS_EVENTS from '@/utils/bus-events';
import { addModalClass, removeModalClasses } from '@/utils/dom-utils';
import { merge } from 'lodash';
import utils from '@/utils';
import { equalString } from '@/utils/string-utils';
import { isNullOrEmpty, toCamelObject } from '@/utils/obj-utils';
import services from '@/services';
import { encodeData } from '@/utils/site-utils';
const emit = defineEmits(['calculated', 'update:calculating', 'update:initialized']);
const props = defineProps({
  calculating: {
    type: Boolean
  },
  initialized: {
    type: Boolean
  }
});
let scfSimulationTimeout,
  paymentOptionChanged = false,
  businessDataChanged = false;
const $bus = inject('$bus');
const state = reactive({
  /**@type {?FinanceCalculatorRef}*/
  calculatorRef: null,
  /**@type {?FinanceQuote}*/
  financeQuote: Object.create(null),
  /**@type {?CarConfiguration}*/
  configuration: null,
  orderRule: null,
  features: [],
  /**@type {?string}*/
  carImage: null,
  /**@type {?PaymentOption}*/
  paymentOption: null,
  /**@type {?CustomerType}*/
  customerType: null,
  carSeries: null,
  carModel: null,
  /**@type {?number}*/
  retailPrice: null,
  /**@type {?number}*/
  basePrice: null,
  /**@type {?number}*/
  optionPrice: 0,
  /**@type {?number}*/
  vatAmount: null,
  /**@type {?string}*/
  currency: null,
  preSelection: null,
  vehicleType: null,
  registrationTime: null,
  santanderVisible: false,
  /**@type {?Function}*/
  readyCallback: null
});
const computes = {
  dockCode: computed(() => state.paymentOption?.dock?.code)
};
/**
 *
 * @param {CalculatorContainerRefInitPayload} payload
 * @return {Promise<void>}
 */
const init = async (payload) => {
  const {
    configuration,
    orderRule,
    features,
    carImage,
    customerType,
    paymentOption,
    retailPrice,
    basePrice,
    optionPrice,
    vatAmount,
    isStock,
    currency,
    preSelection,
    vehicleType,
    registrationTime
  } = payload;
  state.configuration = configuration;
  state.orderRule = orderRule;
  state.features = features;
  state.carImage = carImage;
  state.customerType = customerType;
  state.paymentOption = paymentOption;
  state.retailPrice = retailPrice;
  state.basePrice = basePrice;
  state.optionPrice = optionPrice;
  state.vatAmount = vatAmount;
  state.currency = currency;
  state.carSeries = await services.site.getCarSeriesByCode(configuration?.seriesCode);
  state.carModel = await services.site.getCarModelByCode(configuration?.seriesCode, configuration?.modelCode);
  state.preSelection = preSelection;
  state.vehicleType = vehicleType;
  state.isStock = isStock;
  state.registrationTime = registrationTime;
  await config();
};
const config = async (isUpdate) => {
  const dockCode = computes.dockCode.value;
  if (equalString(dockCode, 'code-waver')) {
    emit('update:calculating', true);
    const [cwFinance, cwConfig] = await state.calculatorRef.configCodeWaver();
    state.cwConfig = cwConfig;
    const options = buildCwCfg();
    if (!isUpdate) {
      merge(options, {
        quoteUpdate(quoteReference, quote) {
          emit('update:calculating', false);
          if (isNullOrEmpty(state.financeQuote?.codeWaver) || paymentOptionChanged || businessDataChanged) {
            const cwResult = {
              reference: quoteReference,
              result: toCamelObject(quote)
            };
            state.financeQuote.codeWaver = cwResult;
            emit('calculated', 'codeWaver', cwResult);
            if (paymentOptionChanged) paymentOptionChanged = false;
            if (businessDataChanged) businessDataChanged = false;
          }
        },
        quoteSelect(quoteReference, quote) {
          removeModalClasses('santander-open');
          const cwResult = {
            reference: quoteReference,
            result: toCamelObject(quote)
          };
          state.financeQuote.codeWaver = cwResult;
          emit('calculated', 'codeWaver', cwResult);
          if (paymentOptionChanged) paymentOptionChanged = false;
          if (businessDataChanged) businessDataChanged = false;
        }
      });
    }
    if (!isUpdate) {
      cwFinance.configure(options);
    } else {
      cwFinance.update(options);
    }
    const updateOptions = {
      entity: ''
    };
    if (!isNullOrEmpty(state.preSelection)) {
      if (!isNullOrEmpty(state.preSelection.entity)) {
        updateOptions.entity = state.preSelection.entity;
        merge(updateOptions, {
          entity: state.preSelection.entity,
          initialiseWith: {
            entity: state.preSelection.entity
          }
        });
      }
      if (!isNullOrEmpty(state.preSelection.quote)) {
        merge(updateOptions, {
          initialiseWith: {
            quote: state.preSelection.quote
          }
        });
      }
      // updateOptions.initialiseWith = {
      //   quote: state.preSelection.quote,
      //   term: state.preSelection.term,
      //   deposit: state.preSelection.deposit,
      //   annualMileage: state.preSelection.mileage,
      //   advanceRental: state.preSelection.advanceRental
      // };
    }
    console.log('code waver update payload', updateOptions);
    cwFinance.update(updateOptions);
    await utils.dom.sleep(6000);
    if (utils.obj.isFn(state.readyCallback)) state.readyCallback();
  }
  if (equalString(dockCode, 'santander')) {
    emit('update:calculating', true);
    if (scfSimulationTimeout) clearTimeout(scfSimulationTimeout);
    const [scfSimulation, assetInfo] = await state.calculatorRef.config(buildScfCfg(null));
    if (!state.assetInfo) {
      state.assetInfo = assetInfo;
      if (scfSimulation) {
        scfSimulation.scfQuotingState((e) => {
          if (e.state === 'loaded') {
            if (utils.obj.isFn(state.readyCallback)) state.readyCallback();
          }
        });
        scfSimulation.scfQuotingVisibilityUpdated((e) => {
          state.santanderVisible = e.visible;
          if (e.visible) {
            addModalClass('santander-open');
          } else {
            removeModalClasses('santander-open');
          }
        });
        scfSimulation.scfSelectedSimulationUpdated(async (e) => {
          if (!props.initialized || isNullOrEmpty(state.financeQuote?.santander) || paymentOptionChanged || businessDataChanged) {
            await setFinanceProduct('santander', global.scfSimulation);
            state.financeQuote.santander = global.scfSimulation;
            emit('update:initialized', true);
            emit('calculated', 'santander', global.scfSimulation);
          }
          if (paymentOptionChanged) paymentOptionChanged = false;
          if (businessDataChanged) businessDataChanged = false;
        });
      }
    }
    scfSimulationTimeout = setTimeout(async () => {
      if (isNullOrEmpty(global.scfSimulation.selectedSimulation)) return;
      await setFinanceProduct('santander', global.scfSimulation);
      state.financeQuote.santander = global.scfSimulation;
      emit('calculated', 'santander', global.scfSimulation);
      emit('update:calculating', false);
    }, 12000);
  }
  if (equalString(dockCode, 'ca-auto-bank')) {
    emit('update:calculating', true); //duration
    const payload = {
      img: state.carImage,
      originalPrice: state.retailPrice,
      optionalTotalValue: state.optionPrice,
      priceCurrency: state.currency,
      mileage: state.preSelection?.mileage ?? 0
    };
    state.calculatorRef.configCab({
      payload,
      preSelection: state.preSelection,
      carSeries: state.carSeries,
      carModel: state.carModel,
      isStock: state.isStock,
      onSelect: async (result) => {
        if (!isNullOrEmpty(result?.instalment?.detailsTable)) {
          await setFinanceProduct('ca-auto-bank', result);
          state.financeQuote.cab = result;
          emit('calculated', 'cab', result);
          emit('update:calculating', false);
        }
      },
      onClose: async (result) => {
        if (!isNullOrEmpty(result?.instalment?.detailsTable)) {
          await setFinanceProduct('ca-auto-bank', result);
          state.financeQuote.cab = result;
          emit('calculated', 'cab', result);
        }
        emit('update:calculating', false);
      }
    });
    await utils.dom.sleep(500);
    if (utils.obj.isFn(state.readyCallback)) state.readyCallback();
  }
};
const setFinanceProduct = async (type, result) => {
  const code = equalString(type, 'santander') ? result?.selectedProduct?.loanType : equalString(type, 'ca-auto-bank') ? result?.offer?.productType : null;
  if (isNullOrEmpty(code)) return null;
  result.mappedProduct = await services.finance.getFinanceProductMapItem(code);
};
const updatePayOption = async (_payOption) => {
  state.paymentOption = _payOption;
  paymentOptionChanged = true;
  await config(true);
};
const updateCustomerType = async (_customerType) => {
  state.customerType = _customerType;
  businessDataChanged = true;
  await config(true);
};
const buildScfCfg = (_options) => {
  let preSelection = state.preSelection;
  const simulation = state.financeQuote.santander?.selectedSimulation;
  if (!isNullOrEmpty(simulation)) {
    preSelection = {
      term: simulation.duration.value,
      deposit: simulation.downPayment.value,
      mileage: simulation.annualMileage?.value ?? 0,
      finalPayment: simulation.lastPayment?.value ?? 0
    };
  }
  const options = utils.finance.buildScfConfig({
    model: state.carModel?.assetName,
    assetImage: state.carImage,
    retailPrice: state.retailPrice,
    downPayment: simulation?.downPayment?.value ?? Math.floor(state.retailPrice * 0.15),
    vatAmount: state.vatAmount,
    customerType: state.customerType,
    paymentOption: state.paymentOption,
    preSelection: preSelection,
    vehicleType: state.vehicleType,
    registrationDate: utils.date.formatDate(state.registrationTime)
  });
  merge(options, _options);
  return options;
};
const buildCwCfg = (_options) => {
  // let preSelection = state.preSelection;
  // const cwQuote = state.financeQuote.codeWaver?.result?.quote;
  // if (!isNullOrEmpty(cwQuote)) {
  //   preSelection = {
  //     term: cwQuote.terms ?? cwQuote.finance.quote.contractLength,
  //     deposit: cwQuote.finance.quote.deposit.cash
  //   };
  // }
  const options = utils.finance.buildCwFinanceConfig({
    features: state.features,
    carSeries: state.carSeries,
    carModel: state.carModel,
    customerType: state.customerType,
    cwConfig: state.cwConfig,
    basePrice: state.basePrice,
    orderRule: state.orderRule,
    productKey: state.paymentOption?.abbr
  });

  merge(options, _options);
  return options;
};
const onOpenFinanceCalculator = () => {
  if (computes.dockCode.value === 'code-waver') {
    if (!global.cwFinance) return;
    global.cwFinance.update(buildCwCfg());
    global.cwFinance.openCalculator();
    addModalClass('santander-open');
  }
  if (computes.dockCode.value === 'santander') {
    if (!global.scfSimulation) return;
    global.scfSimulation.configure(buildScfCfg());
    global.scfSimulation.openSimulator();
  }
  if (equalString(computes.dockCode.value, 'ca-auto-bank')) {
    state.calculatorRef.openCab();
  }
};
const onConfirmSantander = (e) => {
  state.financeQuote.santander = e;
  emit('calculated', 'santander', e);
};
const open = () => {
  $bus.emit(BUS_EVENTS.OPEN_FINANCE_CALCULATOR);
};
const ready = (callback) => {
  state.readyCallback = callback;
};
defineExpose({ init, updatePayOption, updateCustomerType, open, ready });
onMounted(() => {
  $bus.on(BUS_EVENTS.OPEN_FINANCE_CALCULATOR, onOpenFinanceCalculator);
  $bus.on(BUS_EVENTS.CONFIRM_FINANCE_CALCULATOR, onConfirmSantander);
});
onBeforeUnmount(() => {
  $bus.off(BUS_EVENTS.OPEN_FINANCE_CALCULATOR, onOpenFinanceCalculator);
  $bus.off(BUS_EVENTS.CONFIRM_FINANCE_CALCULATOR, onConfirmSantander);
});
</script>

<template>
  <finance-calculator :ref="(e) => (state.calculatorRef = e)" />
</template>

<style lang="scss"></style>
