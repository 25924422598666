<template>
  <div class="s-download-item">
    <JssFile :field="fields.link">
      <template v-slot:default="item">
        <a :href="item.src" :download="item.displayName">
          <Icon :svg="fields.icon.value.svgCode" class="s-download-item__icon" />
          <div class="s-download-item__details">
            <div class="s-download-item__details-top">
              <span class="s-download-item__details-name">{{ fileName }}</span>
              <Icon :svg="fields.nextIcon.value.svgCode" class="s-download-item__top-right-arrow s-download-item__right-arrow" size="small" />
            </div>
            <div class="s-download-item__details-bottom">
              <span class="s-download-item__details-size">{{ fileSize }}</span>
              <Icon :svg="fields.nextIcon.value.svgCode" class="s-download-item__bottom-right-arrow s-download-item__right-arrow" size="small" />
            </div>
          </div>
        </a>
      </template>
    </JssFile>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'DownloadItem',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const fileName = computed(() => props.fields.link.value.displayName);
    const fileSize = computed(() =>
      Number(props.fields.link.value.size) > 1048576 ? Math.floor(Number(props.fields.link.value.size) / 1024 / 1024) + 'MB' : Math.floor(Number(props.fields.link.value.size) / 1024) + 'KB'
    );
    return {
      fileName,
      fileSize
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-download-item {
  @include component-overall-settings;
  @include component-themes;
  > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    margin-top: $space-40;
  }
  &__icon {
    > svg {
      width: 40px;
      height: 40px;
    }
    margin-right: $space-20;
  }
  &__details-top {
    line-height: 1.3;
  }
  &__details-bottom {
    line-height: 1.5;
  }
  &__details-name {
    font-size: 18px;
    margin-right: $space-20;
  }
  &__details-size {
    display: inline-block;
    font-size: 14px;
    margin-right: $space-20;
  }
  &__right-arrow {
    vertical-align: middle;
  }
  &__top-right-arrow {
    display: none;
  }
  @include tablet-landscape {
    &__top-right-arrow {
      display: inline-block;
    }
    &__bottom-right-arrow {
      display: none;
    }
  }
}
</style>
