<template>
  <div class="s-checkout-summary" :class="[{ opened }]" ref="rootEl">
    <div class="s-checkout-summary__wrapper">
      <checkout-car-swiper :car-images="carImages" />
      <div class="s-checkout-summary__delivery-date" v-html="$formatString(fields.expectedDeliveryDate?.value, { date: carModel?.expectedDeliveryDate })" v-if="$isNullOrEmpty($route.query.vin)" />
      <div class="s-checkout-summary__main">
        <div class="s-checkout-summary__series">{{ configuration?.series }}</div>
        <div class="s-checkout-summary__model">{{ $formatModel(configuration?.model) }}</div>
        <div class="s-checkout-summary__sections">
          <site-accordion :title="$t('Vehicle configuration')" theme="black" v-model:active="vehicleConfigOpened">
            <div class="s-checkout-summary__config-section" v-for="(feature, index) in features" :key="index">
              <block-summary :title="feature.name">
                <template v-for="(info, j) in feature.infos" :key="j">
                  <label-value
                    class="c-build-to-order__vehicle-detail-item"
                    :label="info.label"
                    :label-desc="info.showDesc ? info.desc : ''"
                    :value="$formatInclMoney(info.price?.price, info.price?.currency)"
                  />
                </template>
              </block-summary>
            </div>
          </site-accordion>
          <site-accordion :title="$t('Extra\'s')" theme="black" v-model:active="extraOpened" v-show="false"> </site-accordion>
          <site-accordion :title="$t('Price summary')" theme="black" v-model:active="priceSummaryOpened">
            <checkout-price-item :price-list="priceInfo" element-name="Base Retail Price" size="small" />
            <checkout-price-item :price-list="priceInfo" element-name="Option Price" size="small" />
            <div class="s-checkout-summary__price-collection">
              <checkout-price-summary :label="$t('Vehicle total incl. options')" :price-list="priceInfo" formula="Base Retail Price + Option Price" size="small" />
              <block-summary size="small">
                <template #title
                  ><span class="fs-12 ttc">{{ $t('Additional costs') }}</span></template
                >
                <label-value
                  class="s-checkout-price-item"
                  :label="item.label"
                  :value="$formatMoney(item.price, item.currency)"
                  size="small"
                  theme="night"
                  :bordered="false"
                  v-for="(item, index) in additionalCosts"
                  :key="index"
                />
              </block-summary>
              <checkout-price-item :label="$t('Vehicle total incl. additional costs')" :price-list="priceInfo" element-name="Vehicle Total Price" size="small" />
            </div>
            <div class="s-checkout-summary__price-collection">
              <block-summary size="small">
                <template #title>
                  <span class="fs-12">{{ $t('Accessories and service') }}</span>
                </template>
                <template v-for="(benefit, index) in benefitsList" :key="index">
                  <label-value :label="benefit.name" bordered theme="night" size="small" :value="$formatInclMoney(benefit?.price ? benefit?.price : 0, benefit?.currency)" />
                </template>
              </block-summary>
              <checkout-price-item :price-list="priceInfo" element-name="Total Price Excl. VAT" bordered theme="night" size="small" />
            </div>
            <checkout-price-item :price-list="priceInfo" element-name="Incl. VAT" bordered theme="night" size="small" />
            <checkout-price-item :price-list="priceInfo" element-name="Total Price" bordered theme="night" size="small" v-if="$isNullOrEmpty(vin)" />
            <label-value :label="getPriceLabel('Total Price')" v-else>{{ $formatMoney(totalAmount, currency) }}</label-value>
          </site-accordion>
          <site-accordion :title="$t('Financing')" theme="black" v-model:active="financingOpened">
            <template #summary>{{ paymentOption?.title }}</template>
            <div class="s-checkout-summary__regulatory" v-if="paymentOption?.name !== 'cash' && !$isNullOrEmpty(fields.financeSection.fields.regulatoryImage?.value?.src)">
              <jss-image class="img-width-fix" :media="fields.financeSection.fields.regulatoryImage" />
            </div>
            <checkout-loan-detail
              v-bind="fields.financeSection"
              :payment-option="paymentOption"
              :simulation="simulation"
              :finance-quote="financeQuote"
              :total-amount="totalAmount"
              :currency="currency"
              theme="night"
            />
          </site-accordion>
          <jss-rich-text class="s-checkout-summary__disclaimer" :field="fields.disclaimer" />
        </div>
      </div>
    </div>
    <checkout-price-nav
      :fields="fields"
      :step-code="stepCode"
      :price-info="priceInfo"
      :payment-option="paymentOption"
      :alpha2-code="alpha2Code"
      :finance-level2="financeLevel2"
      :simulation="simulation"
      :finance-quote="financeQuote"
      :down-payment="downPayment"
      :total-amount="totalAmount"
      :currency="currency"
      :show-price-btn="false"
    />
  </div>
</template>

<script>
import { computed, inject, reactive, toRefs } from 'vue';
import { getPageAlpha2Code } from '@/utils/site-utils';
import { equalString } from '@/utils/string-utils';

export default {
  name: 'checkout-summary',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    },
    vin: {
      type: String
    },
    stepCode: {
      type: String
    },
    configuration: {
      type: Object
    },
    features: {
      type: Array
    },
    carModel: {
      type: Object
    },
    benefitsList: {
      type: Array
    },
    priceInfo: {
      type: Array
    },
    additionalCosts: {
      type: Array
    },
    carImages: {
      type: Array
    },
    paymentOption: {
      type: Object
    },
    financeLevel2: {
      type: Boolean
    },
    simulation: {
      type: Object
    },
    /**@type CwFinanceResult*/
    financeQuote: {
      type: Object
    },
    downPayment: {
      type: Number
    },
    totalAmount: {
      type: Number
    },
    currency: {
      type: String,
      default: 'EUR'
    }
  },
  setup(props) {
    const { deviceState } = inject('device-common');
    const state = reactive({
      rootEl: null,
      opened: false,
      vehicleConfigOpened: false,
      extraOpened: false,
      priceSummaryOpened: false,
      financingOpened: false,
      carImages: []
    });
    const computes = {
      isFinance: computed(() => props.paymentOption?.name !== 'cash'),
      alpha2Code: computed(() => getPageAlpha2Code(props.page)),
      vehicleTotalPrice: computed(() => props.priceInfo?.find((x) => equalString(x.elementName, 'Vehicle Total Price')))
    };
    const methods = {
      getPriceLabel(name) {
        return props.priceInfo?.find((x) => equalString(x.elementName, name))?.label;
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      deviceState
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-checkout-summary {
  $c: &;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100% - 60px);
  &__wrapper {
    flex-grow: 1;
    overflow-y: auto;
  }
  &__delivery-date {
    background: $yellow;
    text-align: center;
    padding: 8px;
    color: $black;
  }
  &__price-bar {
    flex-shrink: 0;
  }
  &__main {
    padding: 32px 24px 24px;
  }
  &__series {
    font-size: 26px;
    color: $white;
  }
  &__model {
    color: $grey-next;
    margin-bottom: 40px;
  }
  &__price-collection {
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    > .e-label-value {
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom: none;
      }
    }
    + #{$c}__price-collection {
      border-top: none;
    }
  }
  &__regulatory {
    padding-bottom: 8px;
    border-bottom: 1px solid $grey-light;
  }
  &__price-down {
    cursor: pointer;
    text-decoration: underline;
  }
  &__disclaimer {
    margin-top: 40px;
  }
  @include tablet-landscape {
    position: static;
    display: block;
    height: 100%;
    &__main {
      color: $white;
      padding: 64px 64px 24px 64px;
    }
    .s-checkout-price-nav {
      padding: 16px 32px;
      &__tips {
        padding-left: 32px;
        padding-right: 32px;
      }
      &__deposit {
        padding-left: 32px !important;
        padding-right: 32px !important;
      }
      .e-block-summary {
        &__main {
          .e-label-value {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
      }
    }
  }
}
</style>
