<template>
  <div class="c-checkout-recommending" v-if="fields && loaded">
    <div class="c-checkout-recommending__top">
      <div class="mg-t-40 tl-mg-t-48 mg-b-32 tl-mg-b-64">
        <a class="c-checkout-recommending__back" :href="mhpUrl">
          <icon name="back" />
          <jss-text :field="fields.backText" tag="span" />
        </a>
      </div>
      <div class="c-checkout-recommending__title" v-html="$formatString(fields.title?.value, { model: configuration.filterName })" />
      <div class="c-checkout-recommending__desc mg-b-40 tl-mg-b-60" v-html="$formatString(fields.description?.value, { count: recommendedList.length, deliveryDate: '4 weeks' })" />
    </div>
    <div class="c-checkout-recommending__main">
      <div class="c-checkout-recommending__wrapper">
        <jss-rich-text class="c-checkout-recommending__stocks-title" :field="fields.stocksTitle" tag="div" />
        <div class="c-checkout-recommending__items">
          <checkout-recommending-item
            title="YOUR CONFIGURATION"
            :car-images="carImages"
            :fields="fields"
            :model="configuration.filterName"
            :price="Number(totalAmount)"
            :color="getFeature({ features }, 'BODY COLOR')?.desc"
            :currency="currency"
            @continue="onContinue"
          />
          <checkout-recommending-item
            :title="getRecommendedTitle(index)"
            :car-images="item.images"
            :fields="fields"
            :stock-config="item"
            :model="item.model"
            :price="Number(item.totalPrice.price)"
            :currency="currency"
            :differences="item.differentPoints"
            :color="item.color.desc"
            :is-stock="true"
            :best="index === 0"
            v-for="(item, index) in recommendedList"
            :key="index"
            @show-difference="onShowDifference(item)"
          />
        </div>
      </div>
    </div>
    <div class="c-checkout-recommending__buttons">
      <site-button v-bind="fields.viewAllButton" @click="onViewAll" />
    </div>
    <modal class="c-checkout-recommending__compare" ref="recommendingCompareModalRef" animation="right-slide-in">
      <div class="c-checkout-recommending__compare--header">
        <icon class="c-checkout-recommending__compare--close" name="close" @click="closeCompareModal" />
      </div>
      <div class="c-checkout-recommending__compare--content">
        <checkout-car-swiper class="mg-b-20 tl-mg-b-32" :car-images="compareStock.images" />
        <div class="c-checkout-recommending__compare--delivery-text mg-b-24">
          <div v-html="$formatString(fields?.deliveryText?.value, { deliveryTime: '2 weeks' })" />
        </div>
        <div class="c-checkout-recommending__compare--model fs-32 mg-b-4">{{ compareStock.model }}</div>
        <div class="c-checkout-recommending__compare--color fs-14 mg-b-4">{{ compareStock.color?.desc }}</div>
        <div class="c-checkout-recommending__compare--price fs-26 mg-b-4">{{ $formatMoney(compareStock.priceStartFrom, currency) }}</div>
        <div class="c-checkout-recommending__compare--diff-count mg-b-12" v-html="$formatString(fields?.diffCountText?.value, { count: compareStock.differences.length })" />
        <div class="c-checkout-recommending__compare--diffs">
          <site-accordion
            :title="$toCapitalize(diff.info.label)"
            :summary="diff.info.desc"
            v-model:active="diff.expended"
            :disabled="isNullOrEmpty(diff.yInfo?.saleLongComment)"
            v-for="(diff, index) in compareStock.differences"
            :key="index"
          >
            {{ diff.yInfo?.saleLongComment }}
          </site-accordion>
        </div>
      </div>
      <div class="c-checkout-recommending__compare--buttons">
        <site-button v-bind="fields?.compareDetailsButton" @click="onViewDetail(compareStock)" />
        <site-button v-bind="fields?.orderButton" @click="onStockOrder(compareStock)" />
      </div>
    </modal>
  </div>
</template>

<script>
/**
 * @typedef CheckoutRecommendingFields
 * @property {SimpleField} backText
 * @property {SimpleField} title
 * @property {SimpleField} stocksTitle
 * @property {SimpleField} firstMatchTitle
 * @property {SimpleField} secondMatchTitle
 * @property {SimpleField} nthMatchTitle
 * @property {SimpleField} differenceTitle
 * @property {SimpleField} diffCountText
 * @property {ButtonField} continueButton
 * @property {ButtonField} differenceButton
 * @property {ButtonField} detailsButton
 * @property {ButtonField} compareDetailsButton
 * @property {ButtonField} viewAllButton
 * */
/**
 * @typedef CheckoutRecommendingRef
 * @property {() => Promise<void>} init
 * */
import { getCurrentInstance, provide, reactive, toRefs } from 'vue';
import api from '@/api';
import { getCurrentAlpha2Code, getGlobalConfigs } from '@/utils/site-utils';
import { appendQuery, getBetterUrl, getLangFromUrl } from '@/utils/uri-utils';
import { sitecoreProps } from '@/utils/constants';
import { analyzeConfiguration } from '@/utils/mhp-utils';
import { equalString } from '@/utils/string-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'CheckoutRecommending',
  methods: { isNullOrEmpty },
  emits: ['continue'],
  props: {
    /**@type CheckoutRecommendingFields*/
    fields: {
      type: Object
    },
    configuration: {
      type: Object
    },
    features: {
      type: Array
    },
    featureCodes: {
      type: Array
    },
    carModel: {
      type: Object
    },
    carImages: {
      type: Array
    },
    totalAmount: {
      type: Number
    },
    currency: {
      type: String
    },
    mhpUrl: {
      type: String
    },
    ...sitecoreProps
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const recommendingMethods = {
      getFeature(item, name) {
        if (!item?.features?.length) return null;
        for (let feature of item?.features) {
          for (let info of feature.infos) {
            if (equalString(info.label, name)) {
              return info;
            }
          }
        }
        return null;
      },
      onViewDetail(stock) {
        const page = $jss.routeData();
        const [stockDetailLink] = getGlobalConfigs(page, 'stockDetailLink');
        const stockDetailHref = stockDetailLink?.value?.href;
        if (!stockDetailHref) return;
        window.location = getBetterUrl(appendQuery(stockDetailHref, { vin: stock.vin }), page.itemLanguage, true);
      },
      onViewAll() {
        const page = $jss.routeData();
        const [stockListLink] = getGlobalConfigs(page, 'stockListLink');
        const stockListHref = stockListLink?.value?.href;
        if (!stockListHref) return;
        window.location = getBetterUrl(appendQuery(stockListHref, { model: props.configuration.series }), page.itemLanguage, true);
      },
      onStockOrder(stock) {
        const page = $jss.routeData();
        const [checkoutLink] = getGlobalConfigs(page, 'checkoutLink');
        const checkoutHref = checkoutLink?.value?.href;
        if (!checkoutHref) return;
        window.location = getBetterUrl(
          appendQuery(checkoutHref, {
            transfer_code: stock.transferCode,
            filter_id: stock.filterId,
            vin: stock.vin,
            vid: stock.vid
          }),
          page.itemLanguage,
          true
        );
      }
    };
    const methods = {
      ...recommendingMethods,
      async init() {
        if (!props.fields) return [];
        const lang = getLangFromUrl();
        const alpha2Code = getCurrentAlpha2Code();
        const [res, ex] = await api.stock.recommending(null, {
          country: alpha2Code,
          featureList: props.featureCodes,
          filterId: props.configuration.filterId,
          language: lang,
          model: props.configuration.model,
          totalPrice: props.totalAmount
        });
        if (!res?.length) return [];
        const result = [];
        for (let item of res) {
          const images = item.images.grid?.map((x) => x.externalUrl) ?? [];
          const configuration = JSON.parse(item.configurationDetails);
          const priceInfo = JSON.parse(item.detailPrice);
          const features = analyzeConfiguration({ nextLevels: configuration }, false);
          const color = recommendingMethods.getFeature({ features }, 'BODY COLOR');
          const differences = [];
          for (let f of features) {
            for (let info of f.infos) {
              const yf = props.features.find((x) => x.name === f.name);
              const yInfo = yf?.infos.find((x) => x.label === info.label);
              if (info.code !== yInfo?.code) {
                differences.push({
                  expended: false,
                  info,
                  yInfo
                });
              }
            }
          }
          result.push({
            filterId: item.filterId,
            transferCode: item.transferCode,
            series: item.series,
            model: item.vehicleModel,
            priceStartFrom: Number(item.priceStartFrom),
            currency: item.currency,
            differentPoints: item.differentPoints,
            differences,
            vid: item.vid,
            vin: item.vin,
            images,
            featureCodes: JSON.parse(item.trim),
            configuration,
            features,
            color,
            totalPrice: priceInfo.find((x) => x.elementName === 'Total Price'),
            priceInfo
          });
        }
        state.recommendedList = result;
        state.loaded = true;
        return res;
      },
      getRecommendedTitle(index) {
        return index === 0 ? props.fields.firstMatchTitle?.value : index === 1 ? props.fields.secondMatchTitle?.value : props.fields.nthMatchTitle?.value;
      },
      onContinue() {
        ctx.emit('continue');
      },
      onShowDifference(stock) {
        console.log('stock', stock);
        state.compareStock = stock;
        state.recommendingCompareModalRef.open();
      },
      closeCompareModal() {
        state.recommendingCompareModalRef.close();
      }
    };
    const state = reactive({
      loaded: false,
      recommendedList: [],
      /**@type ModalRef*/
      recommendingCompareModalRef: null,
      compareStock: null
    });
    provide('recommendingMethods', recommendingMethods);
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@use "sass:math";
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-checkout-recommending {
  $c: &;
  &__top {
    padding: 0 24px;
  }
  &__back {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__title {
    @include h5;
  }
  &__stocks-title {
    font-size: 18px;
    margin-bottom: 8px;
  }
  &__items {
    display: flex;
    align-items: stretch;
    gap: 24px;
    padding-left: 24px;
  }
  &__main {
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
  &__stocks-title {
    padding-left: calc(70vw + 48px);
    white-space: nowrap;
  }
  .s-checkout-recommending-item {
    width: 70vw;
  }
  &__delivery {
    display: flex;
  }
  .s-checkout-car-swiper {
    .swiper {
      aspect-ratio: 16 / 9;
    }
    .swiper-slide {
      aspect-ratio: 16 / 9;
    }
    .swiper-pagination {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      gap: 8px;
    }
    .swiper-pagination-bullet {
      width: 4px;
      height: 4px;
      &-active {
        width: 4px;
        height: 4px;
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    gap: 24px;
  }
  &__compare {
    .e-modal {
      &__content {
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        .e-site-accordion {
          &__summary {
            max-width: 40%;
          }
          &__content {
            font-size: 12px;
            color: $grey-next0;
          }
        }
      }
    }
    &--header {
      position: sticky;
      z-index: 2;
      top: 0;
      padding: 24px 24px 48px 24px;
      display: flex;
      justify-content: flex-end;
      background: $white;
      .e-icon {
        cursor: pointer;
        svg {
          height: 32px;
        }
      }
    }
    &--content {
      height: calc(100vh - 280px);
      padding: 0 24px;
      overflow-y: auto;
    }
    &--delivery-text {
      display: flex;
      align-items: center;
      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: $green;
        margin-right: 8px;
      }
    }
    &--color {
      color: $grey-next;
    }
    &--buttons {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 24px;
      background: $white;
    }
  }
  @include tablet-landscape {
    padding: 48px grid-width(2) 96px grid-width(2);
    &__top {
      padding: 0;
    }
    &__stocks-title {
      padding-left: calc((100% - 48px) / 3 + 24px);
    }
    &__items {
      padding-left: 0;
    }
    .s-checkout-recommending-item {
      width: calc((100% - 48px) / 3);
    }
    &__compare {
      &.e-modal {
        justify-content: flex-end;
        .e-modal__close {
          top: 32px;
          right: 64px;
        }
        .e-modal__content {
          width: grid-width(10);
        }
      }
      &--header {
        padding: 48px 64px 32px 64px;
      }
      &--content {
        height: calc(100vh - 180px);
        padding: 0 64px;
      }
      &--buttons {
        gap: 24px;
        padding: 24px 64px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .e-site-button {
          flex-grow: 1;
          flex-shrink: 0;
          &:first-child {
            width: 180px;
            flex-grow: unset;
          }
        }
      }
    }
  }
}
html.rtl {
  .c-checkout-recommending {
    .s-checkout-car-swiper {
      .swiper-pagination-bullet {
        + .swiper-pagination-bullet {
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
