import OverallSettings from './overall-settings';
import CaAutoBankCalculator from './ca-auto-bank-calculator';
import CalculatorContainer from './calculator-container';
import CheckoutSteps from './checkout-steps';
import CheckoutVerify from './checkout-verify';
import CheckoutDelivery from './checkout-delivery';
import CheckoutFinance from './checkout-finance';
import CheckoutPayment from './checkout-payment';
import CheckoutSummary from './checkout-summary';
import CheckoutLoanDetail from './checkout-loan-detail';
import CheckoutCarSwiper from './checkout-car-swiper';
import CheckoutPriceItem from './checkout-price-item';
import CheckoutPriceSummary from './checkout-price-summary';
import CheckoutConfirmModal from './checkout-confirm-modal';
import CheckoutPriceNav from './checkout-price-nav';
import CheckoutRecommending from './checkout-recommending';
import CheckoutRecommendingItem from './checkout-recommending-item';
import CheckoutExtras from './checkout-extras';
import DatePickView from '@/sections/date-pick-view';
import FinanceQuoteDetail from '@/sections/finance-quote-detail';
import StockCollapse from './stock-collapse';
import HeroLandingSlide from './hero-landing-slide';
import PrincipleItem from './principle-item';
import DownloadItem from './download-item';
import ImageSliderVerticalMobile from './image-slider-vertical-mobile';
import ImageSliderVerticalPc from './image-slider-vertical-pc';
import HeroSupportItem from './hero-support-item';
import SocialMedias from './social-medias';
import DynamicForm from './dynamic-form';
import FormLayout from './form-layout';
import Toast from './toast';
import ContentLoading from './content-loading';
import ConsentView from './consent-view';
import HeaderCurtainMenu from './header-curtain-menu';
import ImageSwiper from './image-swiper';
import VideoSwiper from './video-swiper';
import PersonalCenter from './personal-center';
import ReservationModelSelect from './reservation-model-select';
import ReservationModelListContent from './reservation-model-list-content';
import ReservationModelLvContent from './reservation-model-lv-content';
import ReservationSteps from './reservation-steps';
import ReservationDealer from './reservation-dealer';
import ReservationPowertrain from './reservation-powertrain';
import ReservationAccountVerify from './reservation-account-verify';
import ReservationDetailsForm from './reservation-details-form';
import ReservationPayment from './reservation-payment';
import ReservationSummary from './reservation-summary';
import ReservationToolbar from './reservation-toolbar';
import StockVehicleCard from './stock-vehicle-card';
import StockVehicleCardV2 from './stock-vehicle-card-v2';
import StockVehicleFilterMenu from './stock-vehicle-filter-menu';
import StockVehicleFilterMenuV2 from './stock-vehicle-filter-menu-v2';
import StockVehiclePriceNav from './stock-vehicle-price-nav';
import StockVehicleSplashScreen from './stock-vehicle-splash-screen';
import TradeIn from './trade-in';
import Disclaimer from './disclaimer';
import DashboardLayout from './dashboard-layout';
import DashboardCard from './dashboard-card';
import FinanceCalculator from './finance-calculator';
import Tooltip from './tooltip';
import DealerCard from './dealer-card';
import DealerCardV1 from './dealer-card-v1';
import DealerCardV2 from './dealer-card-v2';
import DealerCardV3 from './dealer-card-v3';
import DealerCardV4 from './dealer-card-v4';
import SimpleDisclaimer from './simple-disclaimer';
import PopoverDisclaimer from './popover-disclaimer';
import MarketSelectModal from './market-select-modal';
import MerchandiseCheckoutSteps from './merchandise-checkout-steps';
import MerchandiseCheckoutSummary from './merchandise-checkout-summary';
import MerchandiseCheckoutSummaryLife from './merchandise-checkout-summary-life';
import MerchandiseCheckoutSummaryPlan from './merchandise-checkout-summary-plan';
import MerchandiseCheckoutVerify from './merchandise-checkout-verify';
import MerchandiseCheckoutPlan from './merchandise-checkout-plan';
import MerchandiseCheckoutDelivery from './merchandise-checkout-delivery';
import MerchandiseCheckoutPayment from './merchandise-checkout-payment';
import MerchandiseDetailGallery from './merchandise-detail-gallery';
import MerchandiseShoppingCartPop from './merchandise-shopping-cart-pop';
import FilterMenuSection from './filter-menu-section';
import StoreMapView from './store-map-view';
import StoreLocatorStoreDetails from './store-locator-store-details';
import SendToDealerForm from '@/sections/send-to-dealer-form';
import SendToDealerCfm from '@/sections/send-to-dealer-cfm';
import ShopProductCard from '@/sections/shop-product-card';
import ShopAdCard from '@/sections/shop-ad-card';
import UsedCarFilterMenu from './used-car-filter-menu';
import UsedCarCard from './used-car-card';
import UsedCarLinkedOutCard from '@/sections/used-car-linked-out-card.vue';
import ShopCancelOrderModal from '@/sections/shop-cancel-order-modal';
import ShopConfirmModal from '@/sections/shop-confirm-modal';
import ShopWithdrawalModal from '@/sections/shop-withdrawal-modal';
import ShopTrackModal from '@/sections/shop-track-modal';
import ShopCancelPaymentModal from '@/sections/shop-cancel-payment-modal';
import MerchandiseReturnItem from '@/sections/merchandise-return-item';
import MerchandiseReturnReason from '@/sections/merchandise-return-reason';
import MerchandiseReturnConfirmation from '@/sections/merchandise-return-confirmation';
import MerchandiseReturnSummary from '@/sections/merchandise-return-summary';
import MerchandiseReturnList from '@/sections/merchandise-return-list';
import MerchandiseRecommendList from '@/sections/merchandise-recommend-list';
import VehiclePaying from '@/sections/vehicle-paying.vue';
import VehicleConfigItem from '@/sections/vehicle-config-item.vue';
import StockRecommendationItem from '@/sections/stock-recommendation-item.vue';
import SaveVehicleConfigModal from '@/sections/save-vehicle-config-modal.vue';
import OneFormSection from '@/sections/one-forms/blocks/one-form-section.vue';
import OneFormVehicle from '@/sections/one-forms/secs/one-form-vehicle.vue';
import OneFormLocation from '@/sections/one-forms/secs/one-form-location.vue';
import OneFormDate from '@/sections/one-forms/secs/one-form-date.vue';
import OneFormDetailForm from '@/sections/one-forms/secs/one-form-detail-form.vue';
import OneFormIntro from '@/sections/one-forms/blocks/one-form-intro.vue';
import OneFormContact from '@/sections/one-forms/blocks/one-form-contact.vue';
import OneFormLightForms from '@/sections/one-forms/blocks/one-form-light-forms.vue';
import OneFormTradeIn from '@/sections/one-forms/blocks/one-form-trade-in.vue';
import OneFormLegal from '@/sections/one-forms/blocks/one-form-legal.vue';
import OneFormSwiper from '@/sections/one-forms/blocks/one-form-swiper.vue';
import OneFormSuccess from '@/sections/one-forms/one-form-success.vue';

export default {
  install(Vue) {
    Vue.component('OverallSettings', OverallSettings);
    Vue.component('CaAutoBankCalculator', CaAutoBankCalculator);
    Vue.component('CalculatorContainer', CalculatorContainer);
    Vue.component('CheckoutSteps', CheckoutSteps);
    Vue.component('CheckoutVerify', CheckoutVerify);
    Vue.component('CheckoutFinance', CheckoutFinance);
    Vue.component('CheckoutDelivery', CheckoutDelivery);
    Vue.component('CheckoutPayment', CheckoutPayment);
    Vue.component('CheckoutSummary', CheckoutSummary);
    Vue.component('CheckoutLoanDetail', CheckoutLoanDetail);
    Vue.component('CheckoutCarSwiper', CheckoutCarSwiper);
    Vue.component('CheckoutPriceItem', CheckoutPriceItem);
    Vue.component('CheckoutPriceSummary', CheckoutPriceSummary);
    Vue.component('CheckoutConfirmModal', CheckoutConfirmModal);
    Vue.component('CheckoutPriceNav', CheckoutPriceNav);
    Vue.component('CheckoutRecommending', CheckoutRecommending);
    Vue.component('CheckoutRecommendingItem', CheckoutRecommendingItem);
    Vue.component('CheckoutExtras', CheckoutExtras);
    Vue.component('DatePickView', DatePickView);
    Vue.component('FinanceQuoteDetail', FinanceQuoteDetail);
    Vue.component('HeroLandingSlide', HeroLandingSlide);
    Vue.component('PrincipleItem', PrincipleItem);
    Vue.component('DownloadItem', DownloadItem);
    Vue.component('ImageSliderVerticalMobile', ImageSliderVerticalMobile);
    Vue.component('ImageSliderVerticalPc', ImageSliderVerticalPc);
    Vue.component('HeroSupportItem', HeroSupportItem);
    Vue.component('SocialMedias', SocialMedias);
    Vue.component('DynamicForm', DynamicForm);
    Vue.component('FormLayout', FormLayout);
    Vue.component('Toast', Toast);
    Vue.component('ContentLoading', ContentLoading);
    Vue.component('ConsentView', ConsentView);
    Vue.component('HeaderCurtainMenu', HeaderCurtainMenu);
    Vue.component('ImageSwiper', ImageSwiper);
    Vue.component('VideoSwiper', VideoSwiper);
    Vue.component('TradeIn', TradeIn);
    Vue.component('Disclaimer', Disclaimer);
    Vue.component('DashboardLayout', DashboardLayout);
    Vue.component('DashboardCard', DashboardCard);
    Vue.component('FinanceCalculator', FinanceCalculator);
    Vue.component('DealerCard', DealerCard);
    Vue.component('DealerCardV1', DealerCardV1);
    Vue.component('DealerCardV2', DealerCardV2);
    Vue.component('DealerCardV3', DealerCardV3);
    Vue.component('DealerCardV4', DealerCardV4);
    Vue.component('MarketSelectModal', MarketSelectModal);
    Vue.component('MerchandiseCheckoutSteps', MerchandiseCheckoutSteps);
    Vue.component('MerchandiseCheckoutSummary', MerchandiseCheckoutSummary);
    Vue.component('MerchandiseCheckoutSummaryLife', MerchandiseCheckoutSummaryLife);
    Vue.component('MerchandiseCheckoutSummaryPlan', MerchandiseCheckoutSummaryPlan);
    Vue.component('MerchandiseCheckoutVerify', MerchandiseCheckoutVerify);
    Vue.component('MerchandiseCheckoutPlan', MerchandiseCheckoutPlan);
    Vue.component('MerchandiseCheckoutDelivery', MerchandiseCheckoutDelivery);
    Vue.component('MerchandiseCheckoutPayment', MerchandiseCheckoutPayment);
    Vue.component('MerchandiseDetailGallery', MerchandiseDetailGallery);
    Vue.component('MerchandiseShoppingCartPop', MerchandiseShoppingCartPop);
    Vue.component('MerchandiseReturnItem', MerchandiseReturnItem);
    Vue.component('MerchandiseReturnReason', MerchandiseReturnReason);
    Vue.component('MerchandiseReturnConfirmation', MerchandiseReturnConfirmation);
    Vue.component('MerchandiseReturnSummary', MerchandiseReturnSummary);
    Vue.component('MerchandiseReturnList', MerchandiseReturnList);
    Vue.component('MerchandiseRecommendList', MerchandiseRecommendList);
    Vue.component('FilterMenuSection', FilterMenuSection);
    Vue.component('PersonalCenter', PersonalCenter);
    Vue.component('PopoverDisclaimer', PopoverDisclaimer);
    Vue.component('ReservationModelSelect', ReservationModelSelect);
    Vue.component('ReservationModelListContent', ReservationModelListContent);
    Vue.component('ReservationModelLvContent', ReservationModelLvContent);
    Vue.component('ReservationSteps', ReservationSteps);
    Vue.component('ReservationDealer', ReservationDealer);
    Vue.component('ReservationPowertrain', ReservationPowertrain);
    Vue.component('ReservationAccountVerify', ReservationAccountVerify);
    Vue.component('ReservationDetailsForm', ReservationDetailsForm);
    Vue.component('ReservationPayment', ReservationPayment);
    Vue.component('ReservationSummary', ReservationSummary);
    Vue.component('ReservationToolbar', ReservationToolbar);
    Vue.component('SaveVehicleConfigModal', SaveVehicleConfigModal);
    Vue.component('StockCollapse', StockCollapse);
    Vue.component('StockVehicleCard', StockVehicleCard);
    Vue.component('StockVehicleCardV2', StockVehicleCardV2);
    Vue.component('StockVehicleFilterMenu', StockVehicleFilterMenu);
    Vue.component('StockVehicleFilterMenuV2', StockVehicleFilterMenuV2);
    Vue.component('StockVehiclePriceNav', StockVehiclePriceNav);
    Vue.component('StockVehicleSplashScreen', StockVehicleSplashScreen);
    Vue.component('ShopCancelOrderModal', ShopCancelOrderModal);
    Vue.component('StoreMapView', StoreMapView);
    Vue.component('StoreLocatorStoreDetails', StoreLocatorStoreDetails);
    Vue.component('SendToDealerForm', SendToDealerForm);
    Vue.component('SendToDealerCfm', SendToDealerCfm);
    Vue.component('ShopWithdrawalModal', ShopWithdrawalModal);
    Vue.component('ShopTrackModal', ShopTrackModal);
    Vue.component('ShopCancelPaymentModal', ShopCancelPaymentModal);
    Vue.component('ShopConfirmModal', ShopConfirmModal);
    Vue.component('SimpleDisclaimer', SimpleDisclaimer);
    Vue.component('ShopProductCard', ShopProductCard);
    Vue.component('ShopAdCard', ShopAdCard);
    Vue.component('StockRecommendationItem', StockRecommendationItem);
    Vue.component('Tooltip', Tooltip);
    Vue.component('UsedCarFilterMenu', UsedCarFilterMenu);
    Vue.component('UsedCarCard', UsedCarCard);
    Vue.component('UsedCarLinkedOutCard', UsedCarLinkedOutCard);
    Vue.component('VehiclePaying', VehiclePaying);
    Vue.component('VehicleConfigItem', VehicleConfigItem);
    Vue.component('OneFormSection', OneFormSection);
    Vue.component('OneFormVehicle', OneFormVehicle);
    Vue.component('OneFormLocation', OneFormLocation);
    Vue.component('OneFormDate', OneFormDate);
    Vue.component('OneFormDetailForm', OneFormDetailForm);
    Vue.component('OneFormIntro', OneFormIntro);
    Vue.component('OneFormLightForms', OneFormLightForms);
    Vue.component('OneFormContact', OneFormContact);
    Vue.component('OneFormTradeIn', OneFormTradeIn);
    Vue.component('OneFormLegal', OneFormLegal);
    Vue.component('OneFormSwiper', OneFormSwiper);
    Vue.component('OneFormSuccess', OneFormSuccess);
  }
};
