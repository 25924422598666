<template>
  <div class="c-checkout-payment" v-show="stepCode === 'payment'">
    <div class="c-checkout-payment__failed" v-if="failed">
      <jss-rich-text class="c-build-to-order__failed-overline" :field="fields.failedOverline" />
      <jss-rich-text class="c-build-to-order__failed-title" :field="fields.failedTitle" />
      <jss-rich-text class="c-build-to-order__failed-message" :field="fields.failedErrorMessage" />
      <jss-rich-text class="c-build-to-order__failed-subtitle" :field="fields.failedSubtitle" />
      <jss-rich-text class="c-build-to-order__failed-body" :field="fields.failedBody" />
    </div>
    <div class="c-checkout-payment__widget" ref="widgetRef" />
  </div>
</template>

<script>
/**
 * @typedef CheckoutPaymentRef
 * @property {() => Promise<void>} startListening
 * @property {() => void} initWidget
 * @property {() => void} onPay
 * @property {() => void} onRetry
 * */
import { getCurrentInstance, inject, nextTick, onMounted, reactive, toRefs } from 'vue';
import { loadStripe } from '@/utils/stripe-utils';
import { appendQuery, getBetterUrl, getQueryStrings } from '@/utils/uri-utils';
import { getGlobalConfigs } from '@/utils/site-utils';
import { debounce } from 'lodash';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'checkout-payment',
  props: {
    step: {
      type: Number
    },
    stepCode: {
      type: String
    },
    fields: {
      type: Object
    },
    orderInfo: {
      type: Object
    },
    paymentRes: {
      type: Object
    },
    failed: {
      type: Boolean
    }
  },
  setup(props) {
    let stripeHelper, stripe, confirmPayment, detailHref, clientSecret;
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const loading = inject('loading');
    const toast = inject('toast');
    const checkoutMethods = inject('checkoutMethods');
    const methods = {
      async initWidget(payInfo) {
        const page = $jss.routeData();
        stripeHelper = await loadStripe(page, toast);
        stripe = stripeHelper.initSdk(payInfo);
        setTimeout(() => {
          confirmPayment = stripeHelper.initWidget(stripe, state.widgetRef, payInfo);
        }, 500);
      },
      onPay: debounce(async () => {
        loading.show();
        try {
          const addParams = {};
          if (!isNullOrEmpty(props.paymentRes?.publicKey)) {
            addParams.pk = props.paymentRes?.publicKey;
          }
          const redirectUrl = appendQuery(window.location.href, addParams, ['payment_intent', 'payment_intent_client_secret', 'redirect_status']);
          const error = await confirmPayment(redirectUrl);
          if (error) {
            loading.hide();
            checkoutMethods._qt.trackPayClk(false);
          } else {
            checkoutMethods._qt.trackPayClk(true);
          }
        } catch {
          loading.hide();
          checkoutMethods._qt.trackPayClk(false);
        }
      }, 1000)
    };
    const state = reactive({
      widgetRef: null
    });
    onMounted(async () => {
      const page = $jss.routeData();
      [clientSecret] = getQueryStrings('payment_intent_client_secret');
      const [detailLink] = getGlobalConfigs(page, 'btoDetailLink');
      detailHref = detailLink.value?.href;
      if (!detailHref) {
        await toast.showEx(null);
        return;
      }
      detailHref = getBetterUrl(detailHref, page.itemLanguage, true);
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
.c-checkout-payment {
  padding-bottom: 60px;
}
</style>
