<template>
  <div class="s-checkout-recommending-item" :class="[{ 'theme-black': !isStock, best }]">
    <div class="s-checkout-recommending-item__title" v-html="title" />
    <checkout-car-swiper :car-images="carImages" />
    <div class="s-checkout-recommending-item__props">
      <div class="s-checkout-recommending-item__delivery">
        <span class="s-checkout-recommending-item__delivery-point" v-if="isStock" />
        <icon name="skew-arrow-br" size="tiny" v-else />
        <div class="s-checkout-recommending-item__delivery-text" v-html="$formatString(fields?.deliveryText?.value, { deliveryTime: '4 weeks' })" />
      </div>
      <div class="s-checkout-recommending-item__model fs-32 mg-b-4">{{ $formatModel(model) }}</div>
      <div class="s-checkout-recommending-item__color __ft-pm mg-b-4">{{ color }}</div>
      <div class="s-checkout-recommending-item__price fs-26 mg-b-12">{{ $formatMoney(price, currency) }}</div>
      <div class="s-checkout-recommending-item__diff mg-b-8" v-if="stockConfig?.differences.length > 0">
        <jss-rich-text class="s-checkout-recommending-item__diff-title" :field="fields.differenceTitle" />
        <div class="s-checkout-recommending-item__diff-body" v-html="stockConfig?.differences.map((x) => $toCapitalize(x.info.label)).join(', ')" />
      </div>
      <div class="s-checkout-recommending-item__buttons">
        <template v-if="isStock">
          <site-button v-bind="fields.differenceButton" @click="showDifference" />
          <site-button v-bind="fields.detailsButton" @click="onViewDetail(stockConfig)" />
        </template>
        <site-button v-bind="fields.continueButton" @click="onContinue" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
  name: 'checkout-recommending-item',
  emits: ['continue', 'show-difference'],
  props: {
    title: {
      type: String
    },
    best: {
      type: Boolean
    },
    stockConfig: {
      type: Object
    },
    carImages: {
      type: Array
    },
    fields: {
      type: Object
    },
    vin: {
      type: String
    },
    model: {
      type: String
    },
    price: {
      type: Number
    },
    currency: {
      type: String
    },
    differences: {
      type: String
    },
    color: {
      type: String
    },
    isStock: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const recommendingMethods = inject('recommendingMethods');
    const methods = {
      ...recommendingMethods,
      onContinue() {
        ctx.emit('continue');
      },
      showDifference() {
        ctx.emit('show-difference');
      }
    };
    return {
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-checkout-recommending-item {
  $c: &;
  @include component-themes;
  position: relative;
  padding-bottom: 132px;
  &__title {
    background: $grey-neutral;
    color: $white;
    font-size: 14px;
    text-align: center;
    padding: 4px 16px;
    position: relative;
    z-index: 2;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-100%);
      bottom: -5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $grey-neutral;
    }
  }
  &.best {
    #{$c}__title {
      background: $black;
      color: $white;
      &:before {
        border-top-color: $black;
      }
    }
  }
  &.theme-black {
    #{$c}__title {
      background: $yellow;
      color: $black;
      &:before {
        border-top-color: $yellow;
      }
    }
    #{$c}__buttons {
      padding-bottom: 72px;
    }
  }
  &__props {
    padding: 16px;
  }
  &__delivery {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .e-icon {
      margin-right: 4px;
    }
    &-point {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: $green;
      margin-right: 8px;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    padding: 0 16px;
    gap: 16px;
    .e-site-button {
      width: 100%;
    }
  }
  .s-checkout-car-swiper {
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
