<template>
  <div class="s-header-curtain-menu__mask" v-show="showMask" />
  <div class="s-header-curtain-menu" :class="[{ [`above-status-bar`]: deviceState.deviceType === 'mobile' && deviceState.browser === 'safari' }]" ref="rootEl" v-show="visible">
    <div class="s-header-curtain-menu__main">
      <div class="s-header-curtain-menu__header">
        <icon class="s-header-curtain-menu__close" name="close" size="small" @click="onMenuClose" />
      </div>
      <div class="s-header-curtain-menu__content">
        <div class="s-header-curtain-menu__content-main" ref="contentMainRef">
          <div class="s-header-curtain-menu__main-menu">
            <heading class="s-header-curtain-menu__model-title" :field="fields.modelTitle" rich />
            <div :class="['s-header-curtain-menu__model-link', { last: index === fields.modelLinks.length - 1 }]" v-for="(modelLink, index) in fields.modelLinks" :key="modelLink.id">
              <site-link :link="modelLink.fields.link" data-event_name="menu_item_click" />
            </div>
            <div :class="['s-header-curtain-menu__other-link', { last: index === fields.otherLinks.length - 1 }]" v-for="(otherLink, index) in fields.otherLinks" :key="otherLink.id">
              <site-link :link="otherLink.fields.link" data-event_name="menu_item_click" />
            </div>
            <div class="s-header-curtain-menu__menus" v-if="fields.menus?.length">
              <site-button v-bind="menu.fields.button" v-for="menu in fields.menus" :key="menu.id" @click="onMenuClick(menu)" />
            </div>
          </div>
          <div class="s-header-curtain-menu__submenu" v-show="activeMenu">
            <div class="s-header-curtain-menu__submenu-back" @click="onCloseSubmenu">
              <icon :field="fields.backIcon" />
              <jss-text :field="fields.backText" />
            </div>
            <template v-for="menu in fields.menus" :key="menu.id">
              <div class="s-header-curtain-menu__submenu-buttons" v-show="activeMenu === menu">
                <site-button v-bind="submenu.fields.button" v-for="submenu in menu.fields.subMenus" :key="submenu.id" />
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="s-header-curtain-menu__region-bar" @click="onOpenRegion">
        <icon class="s-header-curtain-menu__region-icon" name="region" />
        <jss-text class="s-header-curtain-menu__region-label" :field="fields.regionLabel" />
        <div class="s-header-curtain-menu__region-cur">{{ regionName }}</div>
        <icon class="s-header-curtain-menu__region-icon" name="down" />
      </div>
    </div>
  </div>
</template>

<script>
/**@typedef HeaderCurtainMenuFields
 * @property {SimpleField} modelTitle
 * @property {Array<LinkField>} modelLinks
 * @property {Array<LinkField>} otherLinks
 * @property {SimpleField} globalRegionName
 * */
import { inject, nextTick, reactive, toRefs } from 'vue';
import BUS_EVENTS from '@/utils/bus-events';
import { getRegionName } from '@/utils/site-utils';
import { canUseDOM } from '@/utils/dom-utils';
import gsap from 'gsap';
import { gtmPush } from '@/utils/gtm-utils';
import useDevice from '@/hooks/useDevice';

export default {
  name: 'header-curtain-menu',
  emits: ['before-open', 'opened', 'before-close', 'close', 'closed'],
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    },
    rtl: {
      type: Boolean
    }
  },
  setup(props, ctx) {
    const $bus = inject('$bus');
    const { deviceState } = useDevice();
    const regionName = getRegionName(props.page);
    const state = reactive({
      showMask: false,
      visible: false,
      /**@type HTMLDivElement*/
      rootEl: null,
      /**@type SiteRegion*/
      regionName,
      animating: false,
      activeMenu: null,
      /**@type HTMLDivElement*/
      contentMainRef: null
    });
    const methods = {
      getAnimateItems() {
        return state.rootEl?.querySelectorAll('.s-header-curtain-menu__model-title, .s-header-curtain-menu__model-link, .s-header-curtain-menu__other-link');
      },
      toggle() {
        state.visible ? methods.close() : methods.open();
      },
      async open() {
        if (!canUseDOM()) return null;
        state.visible = true;
        state.showMask = true;
        document.documentElement.classList.add('menu-open');
        await nextTick();
        const animateItems = methods.getAnimateItems();
        gsap.killTweensOf(state.rootEl);
        gsap.killTweensOf(animateItems);
        state.animating = true;
        ctx.emit('before-open');
        await Promise.all([
          gsap.fromTo(
            state.rootEl,
            {
              xPercent: 100
            },
            {
              xPercent: 0,
              duration: 0.4,
              position: 1
            }
          ),
          gsap.fromTo(
            animateItems,
            {
              xPercent: 10
            },
            {
              stagger: -0.1,
              xPercent: 0,
              duration: 0.2,
              position: 1,
              onComplete() {
                state.animating = false;
                ctx.emit('opened');
              }
            }
          )
        ]);
      },
      async close() {
        if (!canUseDOM()) return null;
        let animateItems = methods.getAnimateItems();
        gsap.killTweensOf(state.rootEl);
        gsap.killTweensOf(animateItems);
        state.animating = true;
        state.showMask = false;
        document.documentElement.classList.remove('menu-open');
        ctx.emit('before-close');
        const animations = [];
        gsap.killTweensOf(state.rootEl);
        if (state.activeMenu) {
          const subMenuElements = state.rootEl?.querySelectorAll('.s-header-curtain-menu__submenu-buttons .e-site-button');
          gsap.killTweensOf(state.contentMainRef);
          gsap.killTweensOf(subMenuElements);
          animations.push(
            gsap.to(state.contentMainRef, {
              xPercent: 0,
              duration: 0.6,
              position: 1
            })
          );
          animations.push(
            gsap.fromTo(
              subMenuElements,
              {
                xPercent: 50
              },
              {
                stagger: 0.1,
                xPercent: 20,
                duration: 0.2,
                position: 1
              }
            )
          );
        } else {
          gsap.killTweensOf(animateItems);
          animations.push(
            gsap.to(animateItems, {
              stagger: 0.1,
              xPercent: 10,
              duration: 0.4,
              position: 1
            })
          );
        }
        animations.push(
          gsap.to(state.rootEl, {
            xPercent: 100,
            duration: 0.6,
            position: 1,
            onComplete() {
              state.visible = false;
              state.animating = false;
              ctx.emit('closed');
            }
          })
        );
        await Promise.all(animations);
      },
      async onMenuClose(e) {
        gtmPush({
          event: 'menu_close'
        });
        await methods.close();
        ctx.emit('close', e);
      },
      onOpenRegion() {
        $bus.emit(BUS_EVENTS.OPEN_REGION_MODAL);
      },
      async onMenuClick(menu) {
        state.activeMenu = menu;
        console.log('click menu', state.contentMainRef);
        await nextTick();
        const subMenuElements = state.rootEl?.querySelectorAll('.s-header-curtain-menu__submenu-buttons .e-site-button');
        const animateItems = methods.getAnimateItems();
        gsap.killTweensOf(state.contentMainRef);
        gsap.killTweensOf(subMenuElements);
        gsap.killTweensOf(animateItems);
        gsap.fromTo(
          subMenuElements,
          {
            xPercent: 20
          },
          {
            stagger: -0.01,
            xPercent: 0,
            duration: 0.1,
            position: 0
          }
        );
        gsap.to(state.contentMainRef, {
          xPercent: -50,
          duration: 0.6,
          position: 0
        });
        gsap.fromTo(
          animateItems,
          {
            xPercent: 10
          },
          {
            stagger: 0.1,
            xPercent: 0,
            duration: 0.2,
            position: 0
          }
        );
      },
      async onCloseSubmenu() {
        const subMenuElements = state.rootEl?.querySelectorAll('.s-header-curtain-menu__submenu-buttons .e-site-button');
        const animateItems = methods.getAnimateItems();
        gsap.killTweensOf(state.contentMainRef);
        gsap.killTweensOf(subMenuElements);
        gsap.killTweensOf(animateItems);
        await Promise.all([
          gsap.to(state.contentMainRef, {
            xPercent: 0,
            duration: 0.6,
            position: 1
          }),
          gsap.fromTo(
            subMenuElements,
            {
              xPercent: 0
            },
            {
              stagger: 0.1,
              xPercent: 20,
              duration: 0.2,
              position: 1
            }
          ),
          gsap.fromTo(
            animateItems,
            {
              xPercent: -10
            },
            {
              stagger: -0.1,
              xPercent: 0,
              duration: 0.2,
              position: 1,
              onComplete() {
                state.activeMenu = null;
              }
            }
          )
        ]);
      }
    };
    return {
      ...toRefs(state),
      deviceState,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@use "sass:math";
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-header-curtain-menu {
  $c: &;
  position: fixed;
  z-index: 12;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: $yellow;
  @include h9;
  &__mask {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11;
    width: 100%;
    height: 100vh;
    background: rgba($black, 0.5);
  }
  &__main {
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
  }
  &__content {
    height: calc(100% - 52px);
    padding-top: 50px;
    padding-left: grid-width-m(1);
    overflow: hidden;
    overflow-y: auto;
    &-main {
      display: flex;
      width: 200vw;
    }
  }
  &__main-menu {
    width: 100vw;
    height: 100%;
    padding-top: 40px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__menus {
    margin-top: -12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .e-site-button {
      height: 30px;
      &.primary-link {
        height: 30px;
        padding: 0;
        &.bordered {
          padding: 0 16px;
        }
      }
    }
  }
  &__submenu {
    width: 100vw;
    height: 100%;
    padding-top: 100px;
    overflow-y: auto;
    &-back {
      display: inline-flex;
      align-items: center;
      line-height: 1;
      cursor: pointer;
      .e-icon {
        svg {
          width: 16px;
          height: auto;
          margin-right: 4px;
        }
      }
    }
    &-buttons {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .e-site-button {
        height: 30px;
        &.old {
          height: 30px;
        }
      }
    }
  }

  &__menus,
  &__submenu {
    .e-site-button {
      height: 30px;
      font-weight: normal;
      &.primary-link {
        padding: 0;
        &.bordered {
          padding: 0 16px;
        }
      }
    }
  }
  &__header {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: $space-40;
    padding: $space-20;
  }
  &__close {
    cursor: pointer;
  }
  &__model-title {
    font-weight: 400;
    margin-bottom: $space-24;
  }
  &__model-link {
    display: block;
    width: auto;
    font-family: lotusFontFamily('Overpass Lotus Headlines Exlight'), sans-serif;
    letter-spacing: 2px;
    margin-left: -2px;
    margin-bottom: $space-16;
    @include h6;
    > a {
      display: inline-block;
      white-space: nowrap;
    }
    &.last {
      margin-bottom: $space-40;
    }
  }
  &__other-link {
    display: block;
    width: auto;
    margin-bottom: $space-16;
    > a {
      display: inline-block;
      white-space: nowrap;
    }
    &:last-of-type {
      margin-bottom: $space-40;
    }
    font-weight: 400;
  }
  &__region-bar {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    padding: 0 $space-16;
    background: $black;
    color: $white;
    cursor: pointer;
  }
  &__region-icon {
    color: $yellow;
  }
  &__region-label {
    margin-left: $space-24;
  }
  &__region-cur {
    margin-left: $space-8;
    margin-right: auto;
    text-transform: uppercase;
  }
  &.above-status-bar {
    height: calc(100vh - 72px);
  }
  @include tablet-landscape {
    width: grid-width(10);
    &__content {
      padding-left: grid-width(1);
      &-main {
        width: grid-width(20);
      }
    }
    &__main-menu {
      width: grid-width(10);
    }
    &__submenu {
      width: grid-width(10);
    }
  }
}
html[lang='ja-JP'] {
  .s-header-curtain-menu {
    &__model-link {
      font-family: lotusFontFamily('Noto Sans JP Light'), sans-serif;
    }
  }
}
html[lang='ko-KR'] {
  .s-header-curtain-menu {
    &__model-link {
      font-family: lotusFontFamily('Noto Sans KR Light'), sans-serif;
    }
  }
}
</style>
